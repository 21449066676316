import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAuthToken } from '../store/selectors';

/**
 * `ProtectedRoute` restricts access to authenticated users only.
 * Redirects to the login page if no authentication token is found.
 */
const ProtectedRoute = ({ children }) => {
  const token = useSelector(selectAuthToken); // Retrieves auth token from Redux store
  const location = useLocation(); // Current route location

  if (!token) {
    // Redirect to login if no token is found
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children; // Render children if authenticated
};

export default ProtectedRoute;
