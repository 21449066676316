import React, { useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import { IoCloseOutline } from 'react-icons/io5';

const DeploymentConfirmation = ({ onClose }) => {
    const [windowDimensions, setWindowDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });

    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions({ width: window.innerWidth, height: window.innerHeight });
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const styles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            backdropFilter: 'blur(5px)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1000,
        },
        popup: {
            backgroundColor: 'white',
            borderRadius: '15px',
            padding: '25px',
            width: '350px',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
            position: 'relative',
            zIndex: 1001,
        },
        closeButton: {
            position: 'absolute',
            top: '15px',
            right: '15px',
            background: 'rgba(0, 0, 0, 0.1)',
            border: 'none',
            borderRadius: '50%',
            width: '30px',
            height: '30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
        },
        title: {
            fontSize: '24px',
            fontWeight: 'bold',
            marginBottom: '20px',
            textAlign: 'center',
        },
        message: {
            fontSize: '16px',
            textAlign: 'center',
            marginBottom: '20px',
        },
    };

    return (
        <div style={styles.overlay}>
            <Confetti
                width={windowDimensions.width}
                height={windowDimensions.height}
                numberOfPieces={200}
                recycle={false}
                colors={['#FFD700', '#C0C0C0', '#90EE90', '#32CD32']}
            />
            <div style={styles.popup}>
                <button style={styles.closeButton} onClick={onClose}>
                    <IoCloseOutline size={24} color="#333" />
                </button>
                <h2 style={styles.title}>Deployment Successful!</h2>
                <p style={styles.message}>
                    Your campaign has been successfully deployed. You can track its progress in the dashboard.
                </p>
            </div>
        </div>
    );
};

export default DeploymentConfirmation;