import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthToken } from '../../../store/selectors';
import { logout } from '../../../store/authSlice';
import { 
  fetchGroups, 
  removeDuplicates,
  importContactsSpreadsheet,
  importContactsManual,
} from '../../../store/campaignActions';
import { 
  setSelectedGroups,
  resetTotalWithoutDuplicates,
  addIndividualLead,
  setSelectedIndividualLeads,
  deleteIndividualLead
} from '../../../store/campaignSlice';
import {
  selectGroups,
  selectSelectedGroups,
  selectSelectedGroupIds,
  selectIsLoading,
  selectError,
  selectTotalSelectedContacts,
  selectTotalWithoutDuplicates,
  selectIsAnyGroupSelected,
} from '../../../store/selectors';
import campaignAPI from '../../../service/campaignAPI';

const LeadsLogic = () => {
  // ===================== STATE MANAGEMENT START =====================
  const [importedFile, setImportedFile] = useState(null);
  const [isImporting, setIsImporting] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [viewModalData, setViewModalData] = useState({
    isOpen: false,
    isLoading: false,
    data: [],
    groupName: '',
    totalLeads: 0,
    error: null
  });
  const [isManualAdding, setIsManualAdding] = useState(false);

  // ===================== REDUX & ROUTER START =====================
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector(selectAuthToken);
  const groups = useSelector(selectGroups);
  const selectedGroups = useSelector(selectSelectedGroups);
  const selectedGroupIds = useSelector(selectSelectedGroupIds);
  const isLoading = useSelector(selectIsLoading);
  const error = useSelector(selectError);
  const totalSelectedContacts = useSelector(selectTotalSelectedContacts);
  const totalWithoutDuplicates = useSelector(selectTotalWithoutDuplicates);
  const duplicatesRemoved = useSelector(state => state.campaign.duplicatesRemoved);
  const isAnyGroupSelected = useSelector(selectIsAnyGroupSelected);
  const individualLeads = useSelector(state => state.campaign.individualLeads);
  const selectedIndividualLeads = useSelector(state => state.campaign.selectedIndividualLeads);

  // ===================== DATA LOADING START =====================
  const fetchGroupsData = useCallback(() => {
    if (token) {
      dispatch(fetchGroups())
        .unwrap()
        .catch(err => {
          if (err.status === 401) {
            dispatch(logout());
            navigate('/login');
          } else {
            setErrorMessage('Failed to fetch groups. Please try again.');
          }
        });
    }
  }, [dispatch, token, navigate]);

  useEffect(() => {
    fetchGroupsData();
  }, [fetchGroupsData]);

  useEffect(() => {
    dispatch(setSelectedGroups([]));
  }, [dispatch]);

  useEffect(() => {
    dispatch(resetTotalWithoutDuplicates());
  }, [selectedGroups, dispatch]);

  useEffect(() => {
    return () => {
      setSuccessMessage('');
      setErrorMessage('');
    };
  }, []);

  // ===================== HANDLERS START =====================
  const handleViewGroup = async (groupId) => {
    const group = groups.find(g => g.id === groupId);
    
    setViewModalData({
      isOpen: true,
      isLoading: true,
      data: [],
      groupName: group?.name || '',
      totalLeads: group?.contacts || 0,
      error: null
    });
  
    try {
      const response = await campaignAPI.fetchLeadsByGroupId(token, groupId);
      setViewModalData(prev => ({
        ...prev,
        isLoading: false,
        data: response.leads.map(lead => ({
          number: lead.lead_phone_number,
          first_name: lead.lead_first_name,
          last_name: lead.lead_last_name,
          group_name: group?.name
        })),
        error: null
      }));
    } catch (error) {
      setViewModalData(prev => ({
        ...prev,
        isLoading: false,
        error: 'Failed to load lead details. Please try again.',
        data: []
      }));
    }
  };

  const handleCloseViewModal = () => {
    setViewModalData({
      isOpen: false,
      isLoading: false,
      data: [],
      groupName: '',
      totalLeads: 0,
      error: null
    });
  };

  const handleSpreadsheetImport = (file) => {
    setImportedFile(file);
    setSuccessMessage('');
    setErrorMessage('');
  };

  const handleAddSpreadsheet = async () => {
    if (!importedFile) return;
  
    setIsImporting(true);
    const formData = new FormData();
    formData.append('file', importedFile);
  
    try {
      const result = await dispatch(importContactsSpreadsheet(formData)).unwrap();
      await fetchGroupsData();
      setImportedFile(null);
      setSuccessMessage('Spreadsheet imported successfully!');
      setErrorMessage('');
    } catch (err) {
      if (err.status === 401) {
        dispatch(logout());
        navigate('/login');
      } else {
        setErrorMessage('Failed to import spreadsheet. Please try again.');
        setSuccessMessage('');
      }
    } finally {
      setIsImporting(false);
    }
  };

  const handleAddIndividualLead = async (leadData) => {
    setIsManualAdding(true);
    try {
      const result = await dispatch(importContactsManual({
        name: leadData.name,
        phone: leadData.phone
      })).unwrap();
      
      if (result?.id) {
        dispatch(addIndividualLead({
          id: result.id,
          ...leadData
        }));
        dispatch(setSelectedIndividualLeads([...selectedIndividualLeads, result.id]));
      }
      
      setSuccessMessage('Lead added successfully!');
      return result;
    } catch (err) {
      setErrorMessage('Failed to add lead. Please try again.');
      return null;
    } finally {
      setIsManualAdding(false);
    }
  };

  const handleDeleteIndividualLead = async (leadId) => {
    try {
      dispatch(deleteIndividualLead(leadId));
      setSuccessMessage('Lead deleted successfully!');
    } catch (err) {
      setErrorMessage('Failed to delete lead. Please try again.');
    }
  };

  const handleLeadSelection = (id) => {
    const updatedSelectedGroups = selectedGroups.some(group => group.id === id)
      ? selectedGroups.filter(group => group.id !== id)
      : [...selectedGroups, groups.find(group => group.id === id)];
    
    dispatch(setSelectedGroups(updatedSelectedGroups));
  };

  const handleSelectAll = () => {
    if (selectedGroups.length === groups.length) {
      dispatch(setSelectedGroups([]));
    } else {
      dispatch(setSelectedGroups(groups));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (selectedGroups.length > 1) {
      try {
        const validGroupIds = selectedGroups
          .map(group => parseInt(group.id, 10))
          .filter(id => !isNaN(id));
  
        const result = await dispatch(removeDuplicates({ groupIds: validGroupIds })).unwrap();
        
        if (result) {
          setSuccessMessage('Duplicates removed successfully!');
          setErrorMessage('');
        }
      } catch (error) {
        if (error.status === 401) {
          dispatch(logout());
          navigate('/login');
          return;
        }
        setErrorMessage('Failed to remove duplicates. Please try again.');
        setSuccessMessage('');
      }
    }
    
    navigate('/campaign/steps/business');
  };

  // ===================== RETURN VALUES START =====================
  return {
    importedFile,
    isImporting,
    successMessage,
    errorMessage,
    groups,
    selectedGroups,
    isLoading,
    error,
    totalSelectedContacts,
    totalWithoutDuplicates,
    duplicatesRemoved,
    isAnyGroupSelected,
    viewModalData,
    individualLeads,
    selectedIndividualLeads,
    isManualAdding,
    handleSpreadsheetImport,
    handleAddSpreadsheet,
    handleSubmit,
    handleLeadSelection,
    handleSelectAll,
    handleViewGroup,
    handleCloseViewModal,
    handleAddIndividualLead,
    handleDeleteIndividualLead
  };
};

export default LeadsLogic;