import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { initiateLogin } from '../../../store/authSlice';
import { selectAuthError, selectAuthLoading, selectIsAuthenticated } from '../../../store/selectors';
import logo from '../../../components/images/sendue_logo.png';
import googleIcon from '../../../components/icons/google_g.png';
import loginImage from '../../../components/images/login_image.png';
import LoadingCursor from '../../../components/Single/LoadingCursor';
import AnimatedWave from './AnimatedWave';

const gradientBorder = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const PageContainer = styled.div`
  display: flex;
  min-height: 100vh;
  padding: 40px;
  padding-top: 80px;
  justify-content: flex-start;
  align-items: flex-start;
  background-image: url("data:image/svg+xml,%3Csvg id='patternId' width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3Cpattern id='a' patternUnits='userSpaceOnUse' width='70' height='70' patternTransform='scale(2) rotate(0)'%3E%3Crect x='0' y='0' width='100%25' height='100%25' fill='hsla(86, 0%25, 100%25, 1)'/%3E%3Cpath d='M-4.8 4.44L4 16.59 16.14 7.8M32 30.54l-13.23 7.07 7.06 13.23M-9 38.04l-3.81 14.5 14.5 3.81M65.22 4.44L74 16.59 86.15 7.8M61 38.04l-3.81 14.5 14.5 3.81' stroke-linecap='square' stroke-width='1' stroke='hsla(86, 96%25, 90%25, 1)' fill='none'/%3E%3C/pattern%3E%3C/defs%3E%3Crect width='800%25' height='800%25' transform='translate(0,0)' fill='url(%23a)'/%3E%3C/svg%3E");
  background-repeat: repeat;
  background-size: auto;
  font-family: 'Raleway', sans-serif;
`;

const LoginContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  height: 500px;
  position: relative;
  overflow: hidden;
  display: flex;
`;

const ContentSection = styled.div`
  display: flex;
  height: 100%;
  position: relative;
  z-index: 2;
  width: 100%;
`;

const LeftSection = styled.div`
  width: 50%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const RightSection = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: -40px;
  position: relative;
`;

const ImageContainer = styled.div`
  width: 115%;
  margin-left: -150px;  // Changed from -120px to -150px (30px more left)
  position: relative;
`;

const LoginImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
  max-width: none;
  transform: scale(1.1);
`;

const Logo = styled.img`
  position: absolute;
  top: 20px;
  left: 20px;
  width: 150px;
  height: auto;
`;

const LoginContent = styled.div`
  max-width: 400px;
  width: 100%;
  margin: 100px auto 0;
  padding: 0 20px 0 -10px;  // Adjusted to move content left
`;

const LoginTitle = styled.h1`
  margin-bottom: 2rem;
  font-size: 32px;
  font-weight: 600;
  color: #111827;
  padding-left: -10px;  // Adjusted from 20px
`;


const ButtonWrapper = styled.div`
  position: relative;
  width: 320px;
  height: 70px;
  margin-left: -10px;  // Changed from 20px to -10px

  &::before {
    content: '';
    position: absolute;
    top: -3px;      // border width
    left: -3px;     // Change from -6px to -3px
    right: -3px;    // Change from -6px to -3px
    bottom: -3px;   // Change from -6px to -3px
    border-radius: 14px;
    background: linear-gradient(90deg, #4ade80, #22c55e, #4ade80);
    background-size: 200% 200%;
    animation: ${gradientBorder} 3s ease infinite;
    z-index: 0;
  }
`;

const GoogleButton = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 16px);
  height: calc(100% - 16px);
  padding: 12px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: inherit;
  opacity: ${props => props.disabled ? 0.7 : 1};
  color: #374151;
  transition: all 0.2s ease;
  z-index: 1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  
  &:hover {
    background-color: ${props => !props.disabled && '#F9FAFB'};
  }
`;

const GoogleIcon = styled.img`
  width: 44px;
  height: 44px;
  margin-right: 12px;
`;

const ErrorMessage = styled.p`
  color: #EF4444;
  text-align: center;
  font-size: 14px;
  margin-top: 1rem;
`;

const Login = () => {
  const dispatch = useDispatch();
  const error = useSelector(selectAuthError);
  const isLoading = useSelector(selectAuthLoading);
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const handleGoogleSignIn = () => {
    dispatch(initiateLogin());
  };

  if (isAuthenticated) {
    return <Navigate to="/campaign" replace />;
  }

  return (
    <PageContainer>
      {isLoading && <LoadingCursor />}
      <LoginContainer>
        <AnimatedWave />
        <ContentSection>
          <LeftSection>
            <Logo src={logo} alt="Logo" />
            <LoginContent>
              <LoginTitle>Login</LoginTitle>
              <ButtonWrapper>
                <GoogleButton
                  onClick={handleGoogleSignIn}
                  disabled={isLoading}
                >
                  <GoogleIcon src={googleIcon} alt="Google" />
                  Sign in with Google
                </GoogleButton>
              </ButtonWrapper>
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </LoginContent>
          </LeftSection>
          <RightSection>
            <ImageContainer>
              <LoginImage src={loginImage} alt="Login" />
            </ImageContainer>
          </RightSection>
        </ContentSection>
      </LoginContainer>
    </PageContainer>
  );
};

export default Login;