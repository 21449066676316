/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const containerStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const linkStyles = css`
  color: #4CAF50;
  text-decoration: underline;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    opacity: 0.7;
  }
`;

const TotalContacts = ({ 
  totalSelectedContacts, 
  onSelectAll, 
  totalWithoutDuplicates, 
  duplicatesRemoved
}) => (
  <>
    <div css={containerStyles}>
      <span>Total Selected Contacts: {totalSelectedContacts}</span>
      <span css={linkStyles} onClick={onSelectAll}>Select All</span>
    </div>
    {totalWithoutDuplicates !== null && (
      <div css={containerStyles}>
        <span>
          Total without Duplicates: {totalWithoutDuplicates}
        </span>
        {duplicatesRemoved > 0 && (
          <span css={css`font-size: 0.9em; color: #666;`}>
            ({duplicatesRemoved} duplicates removed)
          </span>
        )}
      </div>
    )}
  </>
);

export default TotalContacts;