import React from 'react';

const TermsOfService = () => {
  const containerStyle = {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
    marginTop: '20px',
    marginBottom: '20px',
  };

  return (
    <div style={containerStyle}>
      <h1>Terms of Service for Sendue</h1>
      <p><em>Last updated: [Current Date]</em></p>

      <h2>1. Acceptance of Terms</h2>
      <p>By accessing or using the Sendue service, you agree to be bound by these Terms of Service.</p>

      <h2>2. Description of Service</h2>
      <p>Sendue provides a platform for managing and sending business communications.</p>

      <h2>3. User Responsibilities</h2>
      <p>You are responsible for maintaining the confidentiality of your account and for all activities that occur under your account.</p>

      <h2>4. Content</h2>
      <p>You retain all rights to the content you upload to Sendue. You are solely responsible for the content you send through our service.</p>

      <h2>5. Prohibited Activities</h2>
      <p>You agree not to use Sendue for any unlawful purpose or in violation of any applicable regulations.</p>

      <h2>6. Intellectual Property</h2>
      <p>The Sendue service and its original content, features, and functionality are owned by Sendue and are protected by international copyright, trademark, patent, trade secret, and other intellectual property laws.</p>

      <h2>7. Termination</h2>
      <p>We may terminate or suspend your account and access to the Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever.</p>

      <h2>8. Limitation of Liability</h2>
      <p>In no event shall Sendue, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages.</p>

      <h2>9. Changes to Terms</h2>
      <p>We reserve the right to modify or replace these Terms at any time. It is your responsibility to check these Terms periodically for changes.</p>

      <h2>10. Contact Us</h2>
      <p>If you have any questions about these Terms, please contact us at:</p>
      <p>Email: [Your Contact Email]<br />
      Address: [Your Business Address]</p>
    </div>
  );
};

export default TermsOfService;