// store.js
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './rootReducer';
import loggerMiddleware from './middleware/loggerMiddleware';
import analyticsMiddleware from './middleware/analyticsMiddleware';
import config from '../config';
import { handleUnauthorized } from '../utils/authUtils';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['campaign', 'auth'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// New middleware to handle unauthorized access
const unauthorizedMiddleware = () => next => action => {
  if (action.type === 'UNAUTHORIZED') {
    handleUnauthorized();
  }
  return next(action);
};

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    const middlewares = getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST'],
      },
    }).concat(analyticsMiddleware, unauthorizedMiddleware);

    if (config.env === 'development') {
      middlewares.push(loggerMiddleware);
    }

    return middlewares;
  },
});

export const persistor = persistStore(store);

// New event listener to handle unauthorized access
window.addEventListener('unauthorized', () => {
  store.dispatch({ type: 'UNAUTHORIZED' });
});