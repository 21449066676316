// campaignAPI.js
import config from '../config';
const campaignAPI = {
  apiCall: async (method, url, data = null, token = null, isFormData = false) => {
    const headers = new Headers();
    if (token) {
      headers.append('Authorization', `Bearer ${token}`);
    }
    if (!isFormData) {
      headers.append('Content-Type', 'application/json');
    }

    const options = {
      method,
      headers,
      body: isFormData ? data : (data ? JSON.stringify(data) : null),
    };

    const response = await fetch(`${config.apiUrl}${url}`, options);
    if (!response.ok) {
      if (response.status === 401) {
        // New code to handle unauthorized access
        window.dispatchEvent(new Event('unauthorized'));
      }
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
  },
  
  // ==================== AUTH SECTION START ====================

  fetchLogin: async () => {
    return campaignAPI.apiCall('GET', '/auth/login');
  },

  fetchHandleLoginCallback: async (code) => {
    return campaignAPI.apiCall('GET', `/auth/callback?code=${code}`);
  },

  fetchUserInfo: async (token) => {
    return campaignAPI.apiCall('GET', '/user/me', null, token);
  },

  fetchCheckAuth: async (token) => {
    return campaignAPI.apiCall('GET', '/check-auth', null, token);
  },

  // ==================== AUTH SECTION END ====================

// ==================== LEADS SECTION START ====================

fetchGroups: async (token) => {
  console.log('Fetching groups...');
  return campaignAPI.apiCall('GET', '/leads/groups', null, token);
},

fetchLeadsByGroupId: async (token, groupId) => {
  console.log(`Fetching leads for group: ${groupId}`);
  return campaignAPI.apiCall('GET', `/leads/group/${groupId}`, null, token);
},

fetchImportContactsSpreadsheet: async (token, formData) => {
  console.log('Starting spreadsheet import request');
  try {
    if (!(formData instanceof FormData)) {
      throw new Error('Invalid formData: Expected FormData object');
    }

    // Log form data entries (excluding file content)
    for (let [key, value] of formData.entries()) {
      console.log(`FormData entry - ${key}:`, value instanceof File ? value.name : value);
    }

    const response = await fetch(`${config.apiUrl}/leads/import_contacts_spreadsheet`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        // Don't set Content-Type header - browser will set it with boundary for FormData
      },
      body: formData
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Import failed with status:', response.status);
      console.error('Error response:', errorText);
      throw new Error(errorText || `HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log('Import successful:', data);
    return data;
  } catch (error) {
    console.error('Spreadsheet import error:', error);
    if (error.response) {
      try {
        const errorBody = await error.response.text();
        console.error('Error response body:', errorBody);
      } catch (e) {
        console.error('Unable to read error response body');
      }
    }
    throw error;
  }
},

fetchImportContactsManual: async (token, data) => {
  return campaignAPI.apiCall('POST', '/leads/import_contacts_manual', data, token);
},

fetchDeleteGroup: async (token, groupId) => {
  return campaignAPI.apiCall('DELETE', `/groups/${groupId}`, null, token);
},

fetchRemoveDuplicates: async (token, data) => {
  try {
    const response = await campaignAPI.apiCall('POST', '/leads/remove_duplicates', data, token);
    return response;
  } catch (error) {
    console.error('Remove duplicates error:', error);
    throw error;
  }
},

// ==================== LEADS SECTION END ====================

  // ==================== BUSINESS SECTION START ====================

  fetchBusinesses: async (token) => {
    return campaignAPI.apiCall('GET', '/businesses', null, token);
  },

  fetchBusiness: async (token, businessId) => {
    return campaignAPI.apiCall('GET', `/businesses/${businessId}`, null, token);
  },

  fetchCreateBusiness: async (token, data) => {
    try {
      console.log('Sending create business request with data:', data);
      const response = await campaignAPI.apiCall('POST', '/create_business', data, token);
      console.log('Create business API response:', response);
      return response;
    } catch (error) {
      console.error('Error in fetchCreateBusiness:', error);
      throw error;
    }
  },

fetchDeleteBusiness: async (token, businessId) => {
  try {
    console.log('Sending delete business request for ID:', businessId);
    const response = await campaignAPI.apiCall('DELETE', `/businesses/${businessId}`, null, token);
    console.log('Delete business API response:', response);
    return response;
  } catch (error) {
    console.error('Error in fetchDeleteBusiness:', error);
    throw error;
  }
},

  fetchUpdateBusiness: async (token, businessId, data) => {
    console.log(`Sending update request for business: ${businessId} with data:`, data);
    return campaignAPI.apiCall('PUT', `/businesses/${businessId}`, data, token);
  },
 

  // ==================== BUSINESS SECTION END ====================

// ==================== POSTS SECTION START ====================
fetchPosts: async (token) => {
  console.log('[API Posts] Fetching all posts');
  return campaignAPI.apiCall('GET', '/posts', null, token);
 },
 
 fetchCreatePost: async (token, data) => {
  console.log('[API CreatePost] Starting request with data:', data);
  try {
    const response = await campaignAPI.apiCall('POST', '/create_post', data, token);
    console.log('[API CreatePost] Success:', response);
    return response;
  } catch (error) {
    console.error('[API CreatePost] Failed:', error);
    throw error;
  }
 },
 
 fetchDeletePost: async (token, postId) => {
  console.log('[API DeletePost] Deleting post:', postId);
  try {
    await campaignAPI.apiCall('DELETE', `/posts/${postId}`, null, token);
    return postId;
  } catch (error) {
    console.error('[API DeletePost] Failed:', error);
    throw error;
  }
 },
 
 fetchUpdatePost: async (token, postId, data) => {
  console.log('[API UpdatePost] Updating post:', postId, 'with data:', data);
  return campaignAPI.apiCall('PUT', `/posts/${postId}`, data, token);
 },
 
 fetchGetPost: async (token, postId) => {
  console.log('[API GetPost] Fetching post:', postId);
  try {
    const data = await campaignAPI.apiCall('GET', `/posts/${postId}`, null, token);
    return {
      title: data.template_name,
      postText: data.body,
      postImage: data.header,
      buttons: data.buttons.map(button => ({
        type: button.action_type,
        text: button.label,
        action: button.button_action
      }))
    };
  } catch (error) {
    console.error('[API GetPost] Failed:', error);
    throw error;
  }
 },
 
 fetchUploadImage: async (token, formData) => {
  console.log('[API UploadImage] Starting upload');
  try {
    const response = await fetch(`${config.apiUrl}/upload-image`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`
      },
      body: formData
    });
 
    if (!response.ok) {
      throw new Error('Failed to upload image');
    }
 
    const data = await response.json();
    console.log('[API UploadImage] Success:', data);
    return data;
  } catch (error) {
    console.error('[API UploadImage] Failed:', error);
    throw error;
  }
 },
 // ==================== POSTS SECTION END ====================
 
  // ==================== CAMPAIGNS SECTION START ====================

  fetchCreateCampaign: async (token, data) => {
    console.log('Creating campaign with data:', data);
    try {
      // Format data to match API expectations
      const campaignPayload = {
        business_id: data.business_id,
        post_id: data.post_id,
        group_ids: data.group_ids || [],
        individual_lead_ids: data.individual_lead_ids || [],
        scheduled_time: data.scheduled_time
      };
  
      console.log('Formatted payload:', campaignPayload);
      const response = await campaignAPI.apiCall('POST', '/campaigns/create', campaignPayload, token);
      console.log('Campaign creation response:', response);
      return response;
    } catch (error) {
      console.error('Campaign creation error details:', {
        status: error.response?.status,
        message: error.message,
        data: error.response?.data
      });
      throw error;
    }
  },
  fetchCampaigns: async (token) => {
    return campaignAPI.apiCall('GET', '/campaigns', null, token);
  },

  fetchCampaign: async (token, campaignId) => {
    return campaignAPI.apiCall('GET', `/campaigns/${campaignId}`, null, token);
  },

  fetchSendCampaign: async (token, campaignId) => {
    return campaignAPI.apiCall('POST', `/send_campaign?campaign_id=${campaignId}`, null, token);
  },


  // ==================== CAMPAIGNS SECTION END ====================

  // ==================== Balance SECTION END ====================

  fetchUserBalance: async (token) => {
    return campaignAPI.apiCall('GET', '/balance', null, token);
  },

  // ==================== Balance SECTION START ====================

  // ==================== MISCELLANEOUS SECTION START ====================

  fetchTestInternet: async () => {
    return campaignAPI.apiCall('GET', '/test-internet');
  },

  fetchRegisterWhatsAppNumber: async (token, data) => {
    return campaignAPI.apiCall('POST', '/register-whatsapp-number', data, token);
  },

  fetchWhatsAppNumbers: async (token, includeDeleted = false) => {
    return campaignAPI.apiCall('GET', `/whatsapp-numbers?include_deleted=${includeDeleted}`, null, token);
  },

  fetchDeleteWhatsAppNumber: async (token, whatsappNumberId) => {
    return campaignAPI.apiCall('DELETE', `/whatsapp-numbers/${whatsappNumberId}`, null, token);
  },

  // ==================== MISCELLANEOUS SECTION END ====================
};

export default campaignAPI;