import React from 'react';

const CustomButton = ({ text, onClick, style }) => {
  return (
    <>
      <button className="custom-button" onClick={onClick} style={style}>
        <span>{text}</span>
      </button>

      <style jsx>{`
        .custom-button {
          position: absolute;
          bottom: 20px;
          right: 20px;
          background-color: #000000;
          color: white;
          padding: 10px 20px;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          transition: transform 0.3s ease;
          overflow: hidden;
        }

        .custom-button::before {
          content: '';
          position: absolute;
          top: 0;
          left: -100%;
          width: 200%;
          height: 100%;
          background: linear-gradient(
            to right, 
            #000000 0%,
            #000000 25%,
            rgba(0, 255, 0, 0.5) 50%,
            #000000 75%,
            #000000 100%
          );
          transition: left 0.7s ease, opacity 0.3s ease;
          z-index: 1;
          opacity: 0;
        }

        .custom-button:hover {
          transform: scale(1.05);
        }

        .custom-button:hover::before {
          left: 0;
          opacity: 1;
        }

        .custom-button:not(:hover)::before {
          left: -100%;
          opacity: 0;
          transition: left 0.7s ease, opacity 0.7s ease 0.2s;
        }

        .custom-button span {
          position: relative;
          z-index: 2;
        }
      `}</style>
    </>
  );
};

export default CustomButton;