import userIcon from '../components/icons/user.png';
import userSelectIcon from '../components/icons/user_select.png';
import campaignIcon from '../components/icons/campain.png';
import campaignSelectIcon from '../components/icons/campain_select.png';
import leadsIcon from '../components/icons/leads.png';
import leadsSelectIcon from '../components/icons/leads_select.png';
import postIcon from '../components/icons/post.png';
import businessIcon from '../components/icons/business.png';
import businessSelectIcon from '../components/icons/business_select.png';
import postSelectIcon from '../components/icons/post_select.png';
import settingsIcon from '../components/icons/settings.png';
import settingsSelectIcon from '../components/icons/settings_select.png';
import supportIcon from '../components/icons/support.png';
import supportSelectIcon from '../components/icons/support_select.png';
import logoutIcon from '../components/icons/logout.png';
import logoutSelectIcon from '../components/icons/logout_select.png';
import logoImage from '../components/images/sendue_logo.png';

export const icons = {
  user: {
    default: userIcon,
    selected: userSelectIcon
  },
  campaign: {
    default: campaignIcon,
    selected: campaignSelectIcon
  },
  leads: {
    default: leadsIcon,
    selected: leadsSelectIcon
  },
  post: {
    default: postIcon,
    selected: postSelectIcon
  },
  business: {
    default: businessIcon,
    selected: businessSelectIcon
  },
  settings: {
    default: settingsIcon,
    selected: settingsSelectIcon
  },
  support: {
    default: supportIcon,
    selected: supportSelectIcon
  },
  logout: {
    default: logoutIcon,
    selected: logoutSelectIcon
  }
};

export const logo = logoImage;