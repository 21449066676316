import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ActionButton from '../../../components/Single/ActionButton';
import CustomTable from '../../../components/Single/CustomTable';

const Dashboard = () => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false); // State to manage hover effect

  const styles = {
    dashboardContainer: {
      padding: '20px',
      position: 'relative',
      minHeight: '100vh',
    },
    contentContainer: {
      position: 'relative',
      paddingBottom: '80px', // To ensure space for the buttons
    },
    card: {
      backgroundColor: 'white',
      borderRadius: '10px',
      padding: '20px',
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
      marginBottom: '20px',
    },
    tableContainer: {
      overflowX: 'auto',
      marginBottom: '20px',
    },
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0,
    },
    tableCell: {
      border: '1px solid #ddd',
      padding: '15px',
      textAlign: 'left',
    },
    tableHeader: {
      background: 'linear-gradient(135deg, #FFFFFF 0%, #F3F7FF 50%, #F4FAFF 100%)',
      position: 'relative',
      overflow: 'hidden',
    },
    buttonContainer: {
      position: 'relative',
      marginTop: '20px',
      paddingBottom: '60px', // To provide space for the button
    },
    buyCreditsButton: {
      position: 'absolute',
      right: '48px',
      bottom: '10px',
      backgroundColor: isHovered ? '#45a049' : '#4CAF50',
      color: 'white',
      padding: '10px 20px',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      width: '200px',
      height: '50px',
      fontSize: '16px',
      transition: 'background-color 0.3s',
    },
    actionButton: {
      position: 'absolute',
      right: '20px',
      bottom: '20px',
      width: '200px',
      height: '50px',
      fontSize: '16px',
    },
  };

  const handleCreateCampaign = () => {
    navigate('/create-campaign');
  };

  const handleBuyCredits = () => {
    alert('Redirecting to Buy Credits page...');
  };

  // Headers and data for the bottom table
  const headers = ['Businesses', 'Total Groups', 'Total Contacts', 'Posts', 'Deployments'];
  const data = [
    ['1', '7', '1650', '1', '1'],
  ];

  return (
    <div style={styles.dashboardContainer}>
      <h1>Dashboard</h1>
      <div style={styles.contentContainer}>
        <div style={styles.buttonContainer}>
          <div style={styles.card}>
            <h2>Billing Information</h2>
            <table style={styles.table}>
              <tbody>
                <tr>
                  <td style={styles.tableCell}>Current Credit</td>
                  <td style={styles.tableCell}>20 ₪ / 250 messages</td>
                </tr>
                <tr>
                  <td style={styles.tableCell}>Messages Sent</td>
                  <td style={styles.tableCell}>1520</td>
                </tr>
                <tr>
                  <td style={styles.tableCell}>Billing Information</td>
                  <td style={styles.tableCell}>
                    <table style={styles.table}>
                      <tbody>
                        <tr>
                          <td style={styles.tableCell}>Full Name</td>
                          <td style={styles.tableCell}>Aharon Segal</td>
                        </tr>
                        <tr>
                          <td style={styles.tableCell}>Credit Card Info</td>
                          <td style={styles.tableCell}></td>
                        </tr>
                        <tr>
                          <td style={styles.tableCell}>Phone Number</td>
                          <td style={styles.tableCell}></td>
                        </tr>
                        <tr>
                          <td style={styles.tableCell}>Address</td>
                          <td style={styles.tableCell}></td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* Buy Credits Button Positioned to the Right, Outside the White Box */}
          <button
            style={styles.buyCreditsButton}
            onClick={handleBuyCredits}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            Buy Credits
          </button>
        </div>

        {/* Replacing the bottom table with CustomTable component */}
        <div style={styles.card}>
          <h2>General Data</h2>
          <CustomTable headers={headers} data={data} emptyMessage="No general data available." />
        </div>

        {/* Action Button for Creating a Campaign Positioned to the Bottom Right */}
        <div style={styles.actionButton}>
          <ActionButton
            text="Create Campaign"
            onClick={handleCreateCampaign}
            style={{ width: '200px', height: '50px', fontSize: '16px' }}
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
