import React from 'react';
import styled from 'styled-components';

const FooterWrapper = styled.footer`
  background-color: #f8f8f8;
  border-top: 1px solid #e7e7e7;
  padding: 20px 0;
  width: 100%;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 20px;
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BottomSection = styled.div`
  display: flex;
  justify-content: center;
  border-top: 1px solid #e7e7e7;
  padding-top: 20px;
`;

const Copyright = styled.p`
  margin: 0;
  color: #666;
`;

const Nav = styled.nav`
  display: flex;
  gap: 20px;
`;

const MenuNav = styled(Nav)`
  justify-content: center;
`;

const StyledLink = styled.a`
  color: #666;
  text-decoration: none;
  padding: 5px 10px;
  border-radius: 4px;
  transition: all 0.3s ease;

  &:hover {
    color: #4CAF50;
    transform: scale(1.05);
    background-color: rgba(76, 175, 80, 0.05);
    text-decoration: none;
  }
`;

const Footer = () => {
  return (
    <FooterWrapper>
      <FooterContent>
        <TopSection>
          <Copyright>&copy; 2024 Sendue. All rights reserved.</Copyright>
          <Nav>
            <StyledLink href="/privacy">Privacy Policy</StyledLink>
            <StyledLink href="/terms">Terms of Service</StyledLink>
            <StyledLink href="/contact">Contact Us</StyledLink>
          </Nav>
        </TopSection>
        <BottomSection>
          <MenuNav>
            <StyledLink href="https://www.sen-due.com/">Home</StyledLink>
            <StyledLink href="https://www.sen-due.com/how">How It Works</StyledLink>
            <StyledLink href="https://www.sen-due.com/pricing">Pricing</StyledLink>
            <StyledLink href="https://www.sen-due.com/about">About</StyledLink>
            <StyledLink href="https://www.sen-due.com/contact">Contact</StyledLink>
          </MenuNav>
        </BottomSection>
      </FooterContent>
    </FooterWrapper>
  );
};

export default Footer;