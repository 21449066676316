export const baseStyle = {
  fontFamily: "'Raleway', sans-serif",
  lineHeight: 1.5,
  color: '#333',
};

export const globalStyles = `
  @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;600;700&display=swap');

  body, html {
    font-family: 'Raleway', sans-serif;
    margin: 0;
    padding: 0;
    line-height: 1.5;
    color: #333;
  }

  * {
    box-sizing: border-box;
  }

  a {
    color: #0066cc;
    text-decoration: none;
  }

  a:hover, a:focus {
    text-decoration: underline;
  }

  button, input, select, textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  /* Ensure contrast for placeholder text */
  ::placeholder {
    color: #767676;
  }

  /* Improve focus visibility */
  :focus {
    outline: 3px solid #0066cc;
    outline-offset: 2px;
  }

  /* Base styles for high contrast mode */
  .high-contrast {
    --text-color: #fff;
    --bg-color: #000;
    --link-color: #ffff00;
  }

  .high-contrast body {
    background-color: var(--bg-color);
    color: var(--text-color);
  }

  .high-contrast a {
    color: var(--link-color);
  }
`;