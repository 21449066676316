import React from 'react';
import { useNavigate } from 'react-router-dom';
import HelpIndicator from './HelpIndicator';

const StepIndicator = ({ currentStep }) => {
  const navigate = useNavigate();
  const steps = ['Leads', 'Business', 'Post', 'Deploy'];

  const handleBack = () => {
    navigate(-1);
  };

  const styles = {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20px 0',
      background: 'linear-gradient(135deg, #FFFFFF 0%, #F3F7FF 50%, #F4FAFF 100%)',
      borderBottom: '1px solid #F8F8F8',
      width: '100%',
    },
    stepContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      maxWidth: '1200px',
      position: 'relative',
      paddingLeft: '100px',
    },
    backButton: {
      position: 'absolute',
      left: '0',
      padding: '6px 12px',
      backgroundColor: '#A5D6A7',
      color: 'white',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      transition: 'background-color 0.3s ease, transform 0.3s ease',
      fontSize: '14px',
      userSelect: 'none',
      outline: 'none',
    },
    stepsWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    step: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '30px',
      height: '30px',
      borderRadius: '50%',
      fontSize: '14px',
      fontWeight: 'bold',
      marginRight: '10px',
      border: '2px solid',
    },
    stepCompleted: {
      backgroundColor: '#4CAF50',
      borderColor: '#4CAF50',
      color: 'white',
      animation: 'scaleIn 0.5s ease-out',
    },
    stepCurrent: {
      backgroundColor: 'white',
      borderColor: '#4CAF50',
      color: '#4CAF50',
    },
    stepIncomplete: {
      backgroundColor: 'white',
      borderColor: '#e0e0e0',
      color: '#757575',
    },
    stepText: {
      marginRight: '20px',
      fontSize: '14px',
      fontWeight: 'bold',
    },
    line: {
      width: '60px',
      height: '2px',
      margin: '0 10px',
      position: 'relative',
    },
    lineCompleted: {
      backgroundColor: '#4CAF50',
      animation: 'lineProgress 0.6s ease-in-out forwards',
      transformOrigin: 'left',
    },
    lineIncomplete: {
      backgroundImage: 'linear-gradient(to right, #e0e0e0 50%, transparent 50%)',
      backgroundSize: '10px 2px',
      backgroundRepeat: 'repeat-x',
    },
    checkmark: {
      width: '12px',
      height: '6px',
      border: '2px solid white',
      borderTop: 'none',
      borderRight: 'none',
      transform: 'rotate(-45deg)',
      marginTop: '-4px',
      animation: 'checkmark 0.5s ease-out forwards',
    },
    helpIndicatorWrapper: {
      marginLeft: '30px', // Increase space between "Deploy" and HelpIcon
    },
  };

  React.useEffect(() => {
    const styleElement = document.createElement('style');
    styleElement.textContent = `
      @keyframes scaleIn {
        0% { transform: scale(0.8); opacity: 0.5; }
        50% { transform: scale(1.1); }
        100% { transform: scale(1); opacity: 1; }
      }

      @keyframes checkmark {
        0% { 
          transform: scale(0) rotate(-45deg);
          opacity: 0;
        }
        70% { 
          transform: scale(1.2) rotate(-45deg);
          opacity: 1;
        }
        100% { 
          transform: scale(1) rotate(-45deg);
          opacity: 1;
        }
      }

      @keyframes lineProgress {
        0% { transform: scaleX(0); }
        100% { transform: scaleX(1); }
      }

      button:focus {
        outline: none !important;
        box-shadow: none !important;
      }
    `;
    document.head.appendChild(styleElement);
    return () => document.head.removeChild(styleElement);
  }, []);

  return (
    <div style={styles.container}>
      <div style={styles.stepContainer}>
        {currentStep > 0 && (
          <button
            style={styles.backButton}
            onClick={handleBack}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = '#66BB6A';
              e.currentTarget.style.transform = 'scale(1.05)';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = '#A5D6A7';
              e.currentTarget.style.transform = 'scale(1)';
            }}
          >
            ← Back
          </button>
        )}
        <div style={styles.stepsWrapper}>
          {steps.map((step, index) => (
            <div key={step} style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  ...styles.step,
                  ...(index < currentStep
                    ? styles.stepCompleted
                    : index === currentStep
                    ? styles.stepCurrent
                    : styles.stepIncomplete),
                }}
              >
                {index < currentStep ? (
                  <div style={styles.checkmark} />
                ) : (
                  index + 1
                )}
              </div>
              <span
                style={{
                  ...styles.stepText,
                  color: index <= currentStep ? '#4CAF50' : '#757575',
                }}
              >
                {step}
              </span>
              {index < steps.length - 1 && (
                <div
                  style={{
                    ...styles.line,
                    ...(index < currentStep ? styles.lineCompleted : styles.lineIncomplete),
                  }}
                />
              )}
            </div>
          ))}
          <div style={styles.helpIndicatorWrapper}>
            <HelpIndicator />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepIndicator;
