/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import ShareIcon from '../../../components/images/Share.png';
import InfoIcon from '../../../components/images/information.png';

const containerStyles = css`
  width: 100%;
  min-height: 600px;
  padding: 20px;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  flex-direction: column;
`;

const imageStyles = css`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 auto 10px;
`;

const businessNameStyles = css`
  color: #000;
  font-size: 16px;
  margin: 5px 0;
  font-weight: bold;
`;

const shareStyles = css`
  font-size: 14px;
  color: #008069;
  margin-top: 0;
  margin-bottom: 20px;
`;

const businessAccountBoxStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f9f9f9;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 20px;
`;

const infoTextStyles = css`
  text-align: left;
  font-size: 14px;
  color: #555;
  margin-bottom: 10px;
`;

const BusinessPreview = ({ selectedBusiness }) => (
  <div css={containerStyles}>
    {selectedBusiness?.profile_picture && (
      <img 
        src={selectedBusiness.profile_picture} 
        alt="Sendue" 
        css={imageStyles}
      />
    )}
    <p css={businessNameStyles}>Sendue</p>
    
    <img
      src={ShareIcon}
      alt="Share Icon"
      css={css`width: 24px; height: 24px; margin: 20px auto 2px;`}
    />
    <p css={shareStyles}>Share</p>

    <div css={businessAccountBoxStyles}>
      <p css={css`font-size: 14px; color: #555; margin: 0;`}>This is a business account.</p>
      <img src={InfoIcon} alt="Info Icon" css={css`width: 16px; height: 16px;`} />
    </div>

    <div css={infoTextStyles}>
      <p><strong>About:</strong> {selectedBusiness?.business_descriptions || 'N/A'}</p>
      <p><strong>Location:</strong> {selectedBusiness?.business_location || 'N/A'}</p>
      <p><strong>Email:</strong> {selectedBusiness?.business_email || 'N/A'}</p>
      <p><strong>Website:</strong> {selectedBusiness?.website || 'N/A'}</p>
      {selectedBusiness?.facebook_link && <p><strong>Facebook:</strong> {selectedBusiness.facebook_link}</p>}
      {selectedBusiness?.instagram_link && <p><strong>Instagram:</strong> {selectedBusiness.instagram_link}</p>}
    </div>
  </div>
);

export default BusinessPreview;