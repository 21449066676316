import React, { useState } from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import RequestCreditsButton from '../../../components/Single/RequestCreditsButton';

const BuyCredits = ({ onClose }) => {
    const [messageCount, setMessageCount] = useState(0);
    const [isCloseHovered, setIsCloseHovered] = useState(false);
    const [isClosePressed, setIsClosePressed] = useState(false);

    const styles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1000,
        },
        popup: {
            backgroundColor: 'white',
            borderRadius: '15px',
            padding: '25px',
            width: '800px',
            maxWidth: '90%',
            minWidth: '600px',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
            position: 'relative',
        },
        closeButton: {
            position: 'absolute',
            top: '15px',
            right: '15px',
            background: isClosePressed 
                ? 'rgba(0, 0, 0, 0.2)' 
                : isCloseHovered 
                    ? 'rgba(0, 0, 0, 0.15)' 
                    : 'rgba(0, 0, 0, 0.1)',
            border: 'none',
            borderRadius: '50%',
            width: '30px',
            height: '30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            transition: 'all 0.2s ease',
            transform: isClosePressed 
                ? 'scale(0.95)' 
                : isCloseHovered 
                    ? 'scale(1.1)' 
                    : 'scale(1)',
            outline: 'none',
            WebkitTapHighlightColor: 'transparent',
            '&:focus': {
                outline: 'none',
                boxShadow: 'none',
            },
            '&:active': {
                outline: 'none',
                boxShadow: 'none',
            },
            userSelect: 'none',
            WebkitUserSelect: 'none',
        },
        closeIcon: {
            transition: 'transform 0.2s ease',
            transform: isCloseHovered ? 'rotate(90deg)' : 'rotate(0deg)',
            pointerEvents: 'none',
        },
        title: {
            fontSize: '24px',
            fontWeight: 'bold',
            marginBottom: '20px',
            textAlign: 'center',
        },
        contactInfo: {
            marginBottom: '25px',
            padding: '15px',
            backgroundColor: '#f5f5f5',
            borderRadius: '8px',
        },
        contactDetails: {
            fontSize: '14px',
            marginBottom: '5px',
            color: '#666',
        },
        calculatorSection: {
            marginTop: '20px',
        },
        inputGroup: {
            marginBottom: '15px',
        },
        label: {
            display: 'block',
            marginBottom: '5px',
            fontWeight: 'bold',
            color: '#333',
        },
        input: {
            width: '100%',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '5px',
            fontSize: '16px',
            transition: 'border-color 0.2s ease',
            '&:focus': {
                outline: 'none',
                borderColor: '#4CAF50',
            },
        },
        totalPrice: {
            fontSize: '18px',
            fontWeight: 'bold',
            color: '#4CAF50',
            padding: '10px 0',
            borderTop: '1px solid #eee',
            marginTop: '10px',
        },
        buttonContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            marginTop: '20px',
        },
    };

    const handleInputChange = (e) => {
        const value = Math.max(0, parseInt(e.target.value) || 0);
        setMessageCount(value);
    };

    const handleCloseClick = () => {
        setIsClosePressed(true);
        setTimeout(() => {
            onClose();
        }, 150);
    };

    return (
        <div style={styles.overlay}>
            <div style={styles.popup}>
                <button 
                    style={styles.closeButton}
                    onClick={handleCloseClick}
                    onMouseEnter={() => setIsCloseHovered(true)}
                    onMouseLeave={() => {
                        setIsCloseHovered(false);
                        setIsClosePressed(false);
                    }}
                    onMouseDown={() => setIsClosePressed(true)}
                    onMouseUp={() => setIsClosePressed(false)}
                >
                    <IoCloseOutline 
                        size={24} 
                        color="#333" 
                        style={styles.closeIcon}
                    />
                </button>
                <h2 style={styles.title}>Buy Credits</h2>
                
                <div style={styles.contactInfo}>
                    <p style={styles.contactDetails}>Aharon: 052-719-3536</p>
                    <p style={styles.contactDetails}>Yosef: 058-554-4618</p>
                </div>

                <div style={styles.calculatorSection}>
                    <div style={styles.inputGroup}>
                        <label style={styles.label}>Number of messages to buy:</label>
                        <input
                            style={styles.input}
                            type="number"
                            value={messageCount}
                            onChange={handleInputChange}
                            min="0"
                        />
                    </div>
                    <div style={styles.totalPrice}>
                        Total Price: ₪{(messageCount * 0.2).toFixed(2)}
                    </div>
                </div>

                <div style={styles.buttonContainer}>
                    <RequestCreditsButton />
                </div>
            </div>
        </div>
    );
};

export default BuyCredits;