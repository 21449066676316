/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { css } from '@emotion/react';

const checkboxCellStyle = (isSelected, isPressed) => css({
  width: 20,
  height: 20,
  borderRadius: '50%',
  border: `2px solid ${isSelected ? '#4CAF50' : '#9CA3AF'}`,
  cursor: 'pointer',
  marginRight: 10,
  position: 'relative',
  transition: 'all 0.2s ease-in-out',
  backgroundColor: isSelected ? '#C6F6D5' : 'transparent',
  boxShadow: isSelected ? '0 0 8px 2px rgba(76, 175, 80, 0.5)' : 'none',
  transform: isPressed ? 'scale(0.95)' : 'scale(1)',

  '&::before': {
    content: '""',
    position: 'absolute',
    top: -3,
    left: -3,
    right: -3,
    bottom: -3,
    borderRadius: '50%',
    transition: 'all 0.2s ease-in-out',
    opacity: 0,
    backgroundColor: 'rgba(76, 175, 80, 0.12)',
  },

  '&:hover': {
    borderColor: '#4CAF50',
    transform: isPressed ? 'scale(0.95)' : 'scale(1.1)',
    '&::before': {
      opacity: 1,
    },
  },

  '&:active': {
    transform: 'scale(0.95)',
  }
});

const SelectCircle = ({ isSelected, onClick }) => {
  const [isPressed, setIsPressed] = useState(false);

  return (
    <div
      css={checkboxCellStyle(isSelected, isPressed)}
      onClick={onClick}
      onMouseDown={() => setIsPressed(true)}
      onMouseUp={() => setIsPressed(false)}
      onMouseLeave={() => setIsPressed(false)}
      role="checkbox"
      aria-checked={isSelected}
    />
  );
};

export default SelectCircle;