import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectIsLoading } from '../../store/selectors';

const LoadingCursor = () => {
  const isLoading = useSelector(selectIsLoading);

  useEffect(() => {
    document.body.style.cursor = isLoading ? 'wait' : 'default';
    return () => {
      document.body.style.cursor = 'default';
    };
  }, [isLoading]);

  if (!isLoading) return null;

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        pointerEvents: 'none',
        zIndex: 9999,
      }}
    >
      <style>
        {`
          @keyframes rotate {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
          .loading-cursor::after {
            content: '';
            display: block;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            border: 2px solid #4CAF50;
            border-color: #4CAF50 transparent #4CAF50 transparent;
            animation: rotate 1.2s linear infinite;
          }
        `}
      </style>
      <div
        className="loading-cursor"
        style={{
          position: 'absolute',
          left: 'calc(50% - 15px)',
          top: 'calc(50% - 15px)',
        }}
      />
    </div>
  );
};

export default LoadingCursor;