import React, { useState, useRef } from 'react';
import { 
  TextField, 
  Select, 
  MenuItem, 
  FormControl, 
  InputLabel, 
  Typography,
  Box,
  ThemeProvider,
  createTheme,
  Alert
} from '@mui/material';
import { green } from '@mui/material/colors';
import DeleteButton from '../../../components/Single/DeleteButton';
import ImportWindow from '../../../components/Single/ImportWindow';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import FormattedTextInput from './FormattedTextInput';
import { usePostFormLogic } from './PostFormLogic';
import { useDispatch } from 'react-redux';
import { clearPostForm } from '../../../store/campaignSlice';
import PrimaryButton from '../../../components/Single/PrimaryButton';
import ClearButton from '../../../components/Single/ClearButton';

// Theme for consistent color styling
const theme = createTheme({
  palette: {
    primary: {
      main: '#4CAF50',
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#4CAF50',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: '#4CAF50',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: '#4CAF50',
        },
      },
    },
  },
});

// Updated button container styling for consistent spacing
const buttonContainerStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  gap: '16px', // Ensures consistent spacing between buttons
  width: '100%',
  marginTop: '24px', // Adjust spacing from the top as needed
};

const PostForm = ({ formData, onFormChange, onSubmit, isSubmitting, isEditing, onDiscardChanges, onDeletePost }) => {
  const dispatch = useDispatch();
  const [resetFormattedTextInput, setResetFormattedTextInput] = useState(false);
  const importWindowRef = useRef(null);

  const {
    errors,
    topErrors,
    handleInputChange,
    handleImageImport,
    handleAddButton,
    handleRemoveButton,
    handleUpdateButton,
    handleSubmit
  } = usePostFormLogic(formData, onFormChange, onSubmit, isEditing);

  const handleClearForm = () => {
    onFormChange({
      title: '',
      postText: '',
      postImage: null,
      buttons: []
    });
    dispatch(clearPostForm());
    setResetFormattedTextInput(true);
    setTimeout(() => setResetFormattedTextInput(false), 0);
    if (importWindowRef.current) {
      importWindowRef.current.reset();
    }
  };

  const handleDeletePost = () => {
    onDeletePost();
    onDiscardChanges();
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ maxWidth: 500, mx: 'auto' }}>
        <Typography variant="h4" sx={{ mb: 3 }}>
          {isEditing ? 'Edit Post' : 'Create Post'}
        </Typography>

        {topErrors.length > 0 && (
          <Alert severity="error" sx={{ mb: 2 }}>
            <Typography variant="body1" component="div">
              Please correct the following errors:
            </Typography>
            <ul style={{ margin: '8px 0 0 0', paddingLeft: '20px' }}>
              {topErrors.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ul>
          </Alert>
        )}

        <TextField
          fullWidth
          id="title"
          label="Post Title"
          value={formData.title}
          onChange={(e) => handleInputChange('title', e.target.value)}
          error={!!errors.title}
          helperText={errors.title}
          margin="normal"
        />

        <ImportWindow
          ref={importWindowRef}
          type_of_import="image_vid"
          onImport={handleImageImport}
          addSubmit={false}
        />

        <FormattedTextInput
          value={formData.postText}
          onChange={(value) => handleInputChange('postText', value)}
          error={!!errors.postText}
          helperText={errors.postText}
          reset={resetFormattedTextInput}
        />

        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel>Add Button</InputLabel>
          <Select
            value=""
            onChange={(e) => handleAddButton(e.target.value)}
            label="Add Button"
            disabled={formData.buttons.length >= 4}
          >
            <MenuItem value="" disabled>Select Button Type</MenuItem>
            {['call', 'copy', 'link'].map(type => (
              <MenuItem 
                key={type} 
                value={type}
                disabled={type === 'call' && formData.buttons.some(b => b.type === 'call')}
              >
                {type.charAt(0).toUpperCase() + type.slice(1)} Button
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {formData.buttons.map((button, index) => (
          <Box key={index} sx={{ mt: 2, p: 2, bgcolor: 'background.paper', borderRadius: 1, boxShadow: 1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
              <Typography variant="h6">{button.type.charAt(0).toUpperCase() + button.type.slice(1)} Button</Typography>
              <DeleteButton onClick={() => handleRemoveButton(index)} />
            </Box>
            {errors[`button-${index}`] && (
              <Alert severity="error" sx={{ mb: 2 }}>{errors[`button-${index}`]}</Alert>
            )}
            <TextField
              fullWidth
              label="Button Text"
              value={button.text}
              onChange={(e) => handleUpdateButton(index, 'text', e.target.value)}
              margin="dense"
              error={!!errors[`button-${index}`]}
            />
            {button.type === 'call' ? (
              <PhoneInput
                country={'us'}
                value={button.action}
                onChange={(value) => handleUpdateButton(index, 'action', value)}
                inputProps={{
                  required: true,
                }}
                containerStyle={{ marginTop: '16px' }}
                inputStyle={{
                  width: '100%',
                  height: '56px',
                  fontSize: '16px',
                  borderColor: errors[`button-${index}`] ? 'red' : 'rgba(0, 0, 0, 0.23)',
                  borderRadius: '4px',
                }}
              />
            ) : (
              <TextField
                fullWidth
                label={button.type === 'link' ? 'URL' : 'Text to Copy'}
                value={button.action}
                onChange={(e) => handleUpdateButton(index, 'action', e.target.value)}
                margin="dense"
                error={!!errors[`button-${index}`]}
              />
            )}
          </Box>
        ))}

        <Box sx={buttonContainerStyles}>
          <PrimaryButton 
            isSubmitting={isSubmitting}
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            {isEditing ? 'Update Post' : 'Create Post'}
          </PrimaryButton>
          <ClearButton onClick={isEditing ? onDiscardChanges : handleClearForm} />
        </Box>

        <Typography variant="caption" align="center" sx={{ mt: 2, display: 'block' }}>
          By uploading your file or using our service, you agree to our
          <Typography component="a" href="/terms" color="primary"> Terms of Service</Typography> and
          <Typography component="a" href="/privacy" color="primary"> Privacy Policy</Typography>.
        </Typography>
      </Box>
    </ThemeProvider>
  );
};

export default PostForm;
