import React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

const waveAnimation = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
`;

const WaveContainer = styled.div`
  position: absolute;
  bottom: 50px;
  left: 0;
  width: 100%;
  height: 80px;  // Increased height for bigger waves
  overflow: hidden;
  z-index: 1;
`;

const Wave = styled.div`
  position: relative;
  height: 100%;
  width: 200%;
  
  svg {
    position: absolute;
    width: 100%;
    height: 100%;
    animation: ${waveAnimation} 20s linear infinite;  // Increased to 10 seconds
  }

  svg:nth-of-type(2) {
    left: 100%;
    animation: ${waveAnimation} 20s linear infinite;  // Increased to 10 seconds
  }
`;

const AnimatedWave = () => (
  <WaveContainer>
    <Wave>
      <svg viewBox="0 0 1000 80" preserveAspectRatio="none">
        <defs>
          <linearGradient id="waveGradient" x1="0" x2="1" y1="0" y2="0">
            <stop offset="0%" style={{ stopColor: '#4ade80' }}>
              <animate 
                attributeName="stop-color" 
                values="#4ade80; #22c55e; #4ade80"
                dur="3s" 
                repeatCount="indefinite" 
              />
            </stop>
            <stop offset="100%" style={{ stopColor: '#22c55e' }}>
              <animate 
                attributeName="stop-color" 
                values="#22c55e; #4ade80; #22c55e"
                dur="3s" 
                repeatCount="indefinite" 
              />
            </stop>
          </linearGradient>
        </defs>
        <path
          fill="url(#waveGradient)"
          d="M0,25 
             C150,45 200,5 300,25 
             C400,45 450,5 550,25 
             C650,45 700,5 800,25 
             C900,45 950,5 1000,25 
             L1000,55
             C900,75 800,35 700,55 
             C600,75 500,35 400,55 
             C300,75 200,35 100,55 
             C50,75 0,55 0,55 
             L0,25 Z"
        />
      </svg>
      <svg viewBox="0 0 1000 80" preserveAspectRatio="none">
        <path
          fill="url(#waveGradient)"
          d="M0,25 
             C150,45 200,5 300,25 
             C400,45 450,5 550,25 
             C650,45 700,5 800,25 
             C900,45 950,5 1000,25 
             L1000,55
             C900,75 800,35 700,55 
             C600,75 500,35 400,55 
             C300,75 200,35 100,55 
             C50,75 0,55 0,55 
             L0,25 Z"
        />
      </svg>
    </Wave>
  </WaveContainer>
);

export default AnimatedWave;