import React, { useRef, useEffect, useState } from 'react';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Typography, Box, Divider } from '@mui/material';
import { MdCall, MdOutlineContentCopy } from 'react-icons/md';
import { RxExternalLink } from 'react-icons/rx';
import ImagePlaceholder from '../../../components/icons/place_holder.png';
import ImageBackground from '../../../components/images/post_backround.png';
import LogoIcon from '../../../components/images/sendue_logo.png';

const PostPreview = ({ postImage, postText, buttons }) => {
  const [isSticky, setIsSticky] = useState(false);
  const previewRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      if (previewRef.current) {
        const { bottom } = previewRef.current.getBoundingClientRect();
        setIsSticky(bottom <= window.innerHeight);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (postImage instanceof File) {
      const reader = new FileReader();
      reader.onload = (e) => setImageSrc(e.target.result);
      reader.readAsDataURL(postImage);
    } else if (typeof postImage === 'string') {
      setImageSrc(postImage);
    } else {
      setImageSrc(null);
    }
  }, [postImage]);

  const previewStyles = css`
    padding: 20px;
    background-image: url(${ImageBackground});
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: ${isSticky ? 'sticky' : 'relative'};
    top: ${isSticky ? '20px' : 'auto'};
    transition: position 0.3s ease;
  `;

  const headerStyles = css`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: white;
    padding: 10px;
    color: black;
    font-weight: bold;
    border-radius: 10px 10px 0 0;
    z-index: 2;
    text-align: center;
  `;

  const contentStyles = css`
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    margin-top: 40px;
  `;

  const buttonStyles = css`
    margin: 2px 0;
    padding: 8px;
    background-color: white;
    color: #24D5FF;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 14px;
  `;

  const postTextStyles = css`
    color: #333;
    padding: 10px 0;
    text-align: left;
    white-space: pre-wrap;
    font-size: 16px;
    line-height: 1.5;
    max-height: 300px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #888;
      border-radius: 3px;
    }
  `;

  const renderFormattedText = (text) => {
    const formattingRules = [
      { regex: /\*\*(.*?)\*\*/g, replacement: '<strong>$1</strong>' },
      { regex: /\*(.*?)\*/g, replacement: '<em>$1</em>' },
      { regex: /~~(.*?)~~/g, replacement: '<del>$1</del>' },
      { regex: /```(.*?)```/g, replacement: '<code>$1</code>' },
      { regex: /^• (.*)$/gm, replacement: '<li>$1</li>' },
      { regex: /^\d+\. (.*)$/gm, replacement: '<li>$1</li>' },
      { regex: /^> (.*)$/gm, replacement: '<blockquote>$1</blockquote>' },
    ];

    let formattedText = text;
    formattingRules.forEach(rule => {
      formattedText = formattedText.replace(rule.regex, rule.replacement);
    });

    // Wrap lists
    formattedText = formattedText.replace(/(<li>.*<\/li>)/s, '<ul>$1</ul>');
    formattedText = formattedText.replace(/(<li>\d+\..*<\/li>)/s, '<ol>$1</ol>');

    return <div dangerouslySetInnerHTML={{ __html: formattedText }} />;
  };

  return (
    <Box css={previewStyles} ref={previewRef}>
      <Typography variant="subtitle1" css={headerStyles}>POST PREVIEW</Typography>
      <Box css={contentStyles}>
        {imageSrc ? (
          <img
            src={imageSrc}
            alt="Post Media"
            css={css`width: 100%; border-radius: 5px; margin-bottom: 10px;`}
          />
        ) : (
          <Box css={css`
            width: 100%;
            height: 150px;
            background: linear-gradient(to bottom, #F9F9F9, #E6E6E6);
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 10px;
          `}>
            <img src={ImagePlaceholder} alt="Placeholder Icon" css={css`width: 60px; height: 60px;`} />
          </Box>
        )}

        <Box css={postTextStyles}>
          {renderFormattedText(postText)}
        </Box>

        <Typography variant="caption" color="textSecondary" align="left" display="block">
          to Unsubscribe respond Unsubscribe
        </Typography>

        <Box css={css`margin-top: 5px;`}>
        {buttons && buttons.length > 0 ? (
          buttons.map((button, index) => (
            <React.Fragment key={index}>
              <Divider css={css`margin: 5px 0;`} />
              <button css={buttonStyles}>
                <Typography variant="button">
                  {button.text || ' '}
                </Typography>
                {button.type === 'call' && <MdCall css={css`color: #24D5FF; margin-left: 5px;`} />}
                {button.type === 'copy' && <MdOutlineContentCopy css={css`color: #24D5FF; margin-left: 5px;`} />}
                {button.type === 'link' && <RxExternalLink css={css`color: #24D5FF; margin-left: 5px;`} />}
              </button>
            </React.Fragment>
          ))
        ) : (
          <Typography variant="caption" color="textSecondary">No buttons added</Typography>
        )}
      </Box>
      </Box>
    </Box>
  );
};

export default PostPreview;