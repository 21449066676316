/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import CurrentCampaignWindow from '../../../components/CurrentCampaignWindow/CurrentCampaignWindow.js';
import NextStepButton from '../../../components/Single/NextStepButton';
import PostList from './PostList';
import PostForm from './PostForm';
import PostPreview from './PostPreview';
import ChatButton from '../../../components/Single/ContactButton.js';
import ConfirmationWindow from '../../../components/Single/ConfirmationWindow';
import { useStepsPostLogic } from './StepsPostLogic';

const containerStyles = css`
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
  max-width: 1400px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
`;

const columnStyles = css`
  width: 30%;
`;

const rightColumnStyles = css`
  width: 30%;
  position: sticky;
  top: 20px;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  gap: 40px; 
  margin-top: -20px; 
`;

const alertStyles = (type) => css`
  background-color: ${type === 'success' ? '#d4edda' : '#f8d7da'};
  color: ${type === 'success' ? '#155724' : '#721c24'};
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 4px;
  width: 95%;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
`;

const StepsPost = () => {
  const {
    posts,
    selectedPost,
    selectedGroups,
    formData,
    isLoading,
    alert,
    isConfirmationOpen,
    postToDelete,
    handleFormChange,
    handleCreatePost,
    handleDeleteClick,
    handleDeleteConfirm,
    handleNext,
    setIsConfirmationOpen
  } = useStepsPostLogic();

  return (
    <>
      <CurrentCampaignWindow currentStep={3}>
        <p>Total Contacts: {selectedGroups.reduce((sum, group) => sum + (group.contacts || 0), 0)}</p>
      </CurrentCampaignWindow>

      {alert.open && (
        <div css={alertStyles(alert.severity)}>
          {alert.message}
        </div>
      )}

      <div css={containerStyles}>
        <div css={columnStyles}>
          <PostList 
            posts={posts}
            onDeletePost={handleDeleteClick}
          />
        </div>

        <div css={columnStyles}>
          <PostForm 
            formData={formData}
            onFormChange={handleFormChange}
            onSubmit={handleCreatePost}
            isSubmitting={isLoading}
          />
        </div>

        <div css={rightColumnStyles}>
          <NextStepButton
            isEnabled={selectedPost !== null}
            onClick={handleNext}
          />

          <PostPreview 
            postImage={formData.postImage}
            postText={formData.postText}
            buttons={formData.buttons}
          />
        </div>
      </div>

      <ConfirmationWindow 
        isOpen={isConfirmationOpen}
        onClose={() => setIsConfirmationOpen(false)}
        onConfirm={handleDeleteConfirm}
        message="Are you sure you want to delete this post?"
        targetId={postToDelete}
      />

      <ChatButton type="chatNow" location="rightCorner" />
    </>
  );
};

export default StepsPost;