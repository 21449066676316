/** @jsxImportSource @emotion/react */
import React from 'react';
import { Button, CircularProgress } from '@mui/material';
import { css } from '@emotion/react';
import { green, grey } from '@mui/material/colors';

const primaryButtonStyles = css`
  flex: 3;
  height: 56px;
  font-size: 16px;
  background-color: ${green[500]};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: ${green[700]};
  }
  &:disabled {
    background-color: ${grey[400]};
    cursor: not-allowed;
  }
`;

const PrimaryButton = ({ isSubmitting, onClick, disabled, children }) => (
  <Button 
    type="submit" 
    css={primaryButtonStyles} 
    disabled={isSubmitting || disabled} 
    onClick={onClick}
  >
    {isSubmitting ? (
      <>
        <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />
        Submitting...
      </>
    ) : children}
  </Button>
);

export default PrimaryButton;