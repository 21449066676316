// postErrors.js

export const validatePostForm = (formData) => {
    const errors = {};
  
    // 1. Title must not be empty
    if (!formData.title.trim()) {
      errors.title = "Post title is required.";
    }
  
    // 2. Title can't contain spaces
    if (formData.title.includes(' ')) {
      errors.title = "Post title can't contain spaces.";
    }
  
    // 3. Post content can't have more than 10 emojis
    const emojiCount = (formData.postText.match(/\p{Emoji}/gu) || []).length;
    if (emojiCount > 10) {
      errors.postText = "Post can't contain more than 10 emojis.";
    }
  
    // 4. Post content can't have more than 2000 characters
    if (formData.postText.length > 2000) {
      errors.postText = "Post content can't exceed 2000 characters.";
    }
  
    // 5. Selected button fields can't be empty
    formData.buttons.forEach((button, index) => {
      if (!button.text || !button.action) {
        errors[`button-${index}`] = "Both fields are required for each button.";
      }
    });
  
    return errors;
  };
  
  export const getErrorMessage = (errorType, details = '') => {
    const errorMessages = {
      formValidation: "Please correct the errors in the form before submitting.",
      postCreationSuccess: "Post created successfully!",
      postCreationError: `Failed to create post: ${details}`,
      postDeletionSuccess: "Post deleted successfully!",
      postDeletionError: `Failed to delete post: ${details}`,
      postUpdateSuccess: "Post updated successfully!",
      postUpdateError: `Failed to update post: ${details}`,
    };
  
    return errorMessages[errorType] || "An unknown error occurred.";
  };