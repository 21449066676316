// FormattedTextInput.js
import React, { useState, useRef, useEffect } from 'react';
import { Box, TextField, IconButton } from '@mui/material';
import { 
  FormatBold, 
  FormatItalic, 
  FormatStrikethrough, 
  Code,
  FormatListBulleted,
  FormatListNumbered,
  FormatQuote,
} from '@mui/icons-material';
import { HiOutlineEmojiHappy } from 'react-icons/hi';
import EmojiPicker from 'emoji-picker-react';
import InfoIcon from '../../../components/Single/InfoIcon';

const FormattedTextInput = ({ value, onChange, error, helperText, reset }) => {
  const [text, setText] = useState(value || '');
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);
  const textFieldRef = useRef(null);
  const emojiPickerRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
        setIsEmojiPickerOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (reset) {
      setText('');
      onChange('');
    }
  }, [reset]);

  const handleTextChange = (e) => {
    setText(e.target.value);
    onChange(e.target.value);
  };

  const handleEmojiClick = (emojiObject) => {
    const textField = textFieldRef.current;
    const cursorPosition = textField.selectionStart;
    const newText = text.slice(0, cursorPosition) + emojiObject.emoji + text.slice(cursorPosition);
    setText(newText);
    onChange(newText);
    setIsEmojiPickerOpen(false);

    setTimeout(() => {
      const newPosition = cursorPosition + emojiObject.emoji.length;
      textField.setSelectionRange(newPosition, newPosition);
      textField.focus();
    }, 0);
  };

  return (
    <Box sx={{ mt: 2, position: 'relative' }}>
      <Box 
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 0.25,
          bgcolor: '#f5f5f5',
          p: 0.25,
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
        }}
      >
        {[{ title: "Bold", icon: <FormatBold /> }, { title: "Italic", icon: <FormatItalic /> }, { title: "Strikethrough", icon: <FormatStrikethrough /> }, { title: "Monospace", icon: <Code /> }, { title: "Bullet List", icon: <FormatListBulleted /> }, { title: "Numbered List", icon: <FormatListNumbered /> }, { title: "Quote", icon: <FormatQuote /> }, { title: "Emoji", icon: <HiOutlineEmojiHappy /> }].map((item, index) => (
          <IconButton key={index} size="small">
            {item.icon}
          </IconButton>
        ))}
        <InfoIcon tooltipText="This feature is currently under maintenance. We recommend composing your post content in WhatsApp and pasting it here for the best experience." />
      </Box>
      <TextField
        fullWidth
        multiline
        minRows={4}
        maxRows={8}
        value={text}
        onChange={handleTextChange}
        placeholder="Enter post text here..."
        error={error}
        helperText={helperText}
        inputRef={textFieldRef}
      />
      {isEmojiPickerOpen && (
        <Box 
          ref={emojiPickerRef} 
          sx={{ 
            position: 'absolute',
            top: '100%',
            left: 0,
            zIndex: 1000,
            marginTop: '5px',
          }}
        >
          <EmojiPicker onEmojiClick={handleEmojiClick} />
        </Box>
      )}
    </Box>
  );
};

export default FormattedTextInput;
