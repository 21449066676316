import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectAuthToken } from '../../../store/selectors';
import { logout } from '../../../store/authSlice';
import ActionButton from '../../../components/Single/ActionButton';
import CustomTable from '../../../components/Single/CustomTable';
import campaignAPI from '../../../service/campaignAPI';

const Leads = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector(selectAuthToken);
  const [groups, setGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchGroups = async () => {
      if (!token) {
        setError("No authentication token found. Please log in.");
        setIsLoading(false);
        return;
      }

      try {
        setIsLoading(true);
        const response = await campaignAPI.fetchGroups(token);
        
        console.log("API Response:", response);

        if (response && response.groups) {
          const fetchedGroups = Object.entries(response.groups).map(([id, group]) => ({
            id,
            name: group.group_name,
            contacts: group.group_size,
            dateCreated: new Date(group.create_time).toLocaleDateString(),
            lastModified: new Date(group.last_modified).toLocaleDateString(),
            origin: group.origin
          }));
          
          setGroups(fetchedGroups);
        } else {
          throw new Error("Unexpected data format");
        }
      } catch (err) {
        console.error("Failed to fetch groups:", err);
        if (err.response && err.response.status === 401) {
          dispatch(logout());
          navigate('/login');
        } else {
          setError(`Failed to load groups: ${err.message}`);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchGroups();
  }, [token, dispatch, navigate]);

  const styles = {
    leadsContainer: {
      padding: '20px',
      position: 'relative',
      minHeight: '100vh',
    },
    contentContainer: {
      position: 'relative',
      paddingBottom: '100px',
    },
    leadsContent: {
      backgroundColor: 'white',
      borderRadius: '10px',
      padding: '20px',
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    },
    tableContainer: {
      overflowX: 'auto',
    },
    actionButton: {
      position: 'absolute',
      right: '20px',
      bottom: '20px',
      width: '150px',
    },
    emptyMessage: {
      textAlign: 'center',
      padding: '20px',
      fontSize: '16px',
      color: '#666',
    },
  };

  const handleAddGroup = () => {
    navigate('/add-group');
  };

  const headers = ['Groups', 'Number of Contacts', 'Date Created', 'Last Modified', 'Origin'];
  const data = groups.map(group => [
    group.name,
    group.contacts,
    group.dateCreated,
    group.lastModified,
    group.origin
  ]);

  if (isLoading) return <div style={styles.leadsContainer}>Loading...</div>;
  if (error) return <div style={styles.leadsContainer}>{error}</div>;

  return (
    <div style={styles.leadsContainer}>
      <h1>Leads</h1>
      <div style={styles.contentContainer}>
        <div style={styles.leadsContent}>
          <div style={styles.tableContainer}>
            {groups.length > 0 ? (
              <CustomTable 
                headers={headers} 
                data={data} 
              />
            ) : (
              <div style={styles.emptyMessage}>No current leads</div>
            )}
          </div>
        </div>
        <div style={styles.actionButton}>
          <ActionButton 
            text="Add group"
            onClick={handleAddGroup}
          />
        </div>
      </div>
    </div>
  );
};

export default Leads;