import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import config from '../config';
import { setToken, removeToken, getToken } from '../utils/authUtils';

export const initiateLogin = createAsyncThunk(
  'auth/initiateLogin',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch(`${config.apiUrl}/auth/login`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
        },
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        throw new Error(errorData?.message || `HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (data.url) {
        window.location.href = data.url;
        return null;
      } else {
        throw new Error('Login URL not provided by the server');
      }
    } catch (error) {
      console.error('Login error:', error);
      return rejectWithValue(error.message || 'An unexpected error occurred');
    }
  }
);

export const handleLoginCallback = createAsyncThunk(
  'auth/handleLoginCallback',
  async (token, { dispatch }) => {
    setToken(token);
    return token;
  }
);

export const logout = createAsyncThunk(
  'auth/logout',
  async (_, { dispatch }) => {
    try {
      removeToken();
      dispatch({ type: 'campaign/resetState' });
    } catch (error) {
      console.error('Logout error:', error);
      dispatch({ type: 'campaign/resetState' });
    }
  }
);

export const login = createAsyncThunk(
  'auth/login',
  async (token, { dispatch }) => {
    setToken(token);
    return token;
  }
);

export const checkAuth = createAsyncThunk(
  'auth/checkAuth',
  async (_, { dispatch }) => {
    const token = getToken();
    if (token) {
      return token;
    }
    throw new Error('No token found');
  }
);

// 1. AUTH STATE MANAGEMENT & INITIAL CHECK
// authSlice.js - Manages the authentication state and initial checks

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    token: getToken(),
    isAuthenticated: !!getToken(),
    loading: false,
    error: null,
    userId: null,
    name: null,
    email: null,
    picture: null,
  },
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
    setUserInfo: (state, action) => {
      state.userId = action.payload.userId;
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.picture = action.payload.picture;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initiateLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(initiateLogin.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(initiateLogin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(handleLoginCallback.fulfilled, (state, action) => {
        state.token = action.payload;
        state.isAuthenticated = true;
        state.loading = false;
        state.error = null;
      })
      .addCase(logout.fulfilled, (state) => {
        state.token = null;
        state.isAuthenticated = false;
        state.loading = false;
        state.error = null;
        state.userId = null;
        state.name = null;
        state.email = null;
        state.picture = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.token = action.payload;
        state.isAuthenticated = true;
        state.loading = false;
        state.error = null;
      })
      .addCase(checkAuth.fulfilled, (state, action) => {
        state.token = action.payload;
        state.isAuthenticated = true;
        state.loading = false;
        state.error = null;
      })
      .addCase(checkAuth.rejected, (state) => {
        state.token = null;
        state.isAuthenticated = false;
        state.loading = false;
        state.error = null;
      });
  },
});

export const { clearError, setUserInfo } = authSlice.actions;
export default authSlice.reducer;