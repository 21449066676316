import React from 'react';
import styled from '@emotion/styled';
import FluidCanvasStep from './FluidCanvasStep';
import AttentionGrabber from './AttentionGrabber';

const Container = styled.div`
  position: relative;
  width: 60px;
  height: 60px;
`;

const AnimatedFluidStep = ({ fillPercentage, previousStep }) => {
  return (
    <Container>
      <FluidCanvasStep 
        fillPercentage={fillPercentage} 
        previousStep={previousStep || 0}
      />
      <AttentionGrabber />
    </Container>
  );
};

export default AnimatedFluidStep;