/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { css } from '@emotion/react';
import { scrollbarStyles } from '../../../components/Single/Scrollbar';
import { IoEyeOutline } from 'react-icons/io5';
import Popup from '../../../components/Single/Popup';
import CustomTable from '../../../components/Single/CustomTable';

const SelectedGroups = ({ selectedGroups, totalSelectedContacts, individualLeads = [], selectedIndividualLeads = [] }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  
  const allContacts = selectedGroups.flatMap(group => 
    Array(group.contacts).fill().map((_, i) => ({
      name: `Contact ${i + 1}`,
      group: group.name
    }))
  );

  const selectedLeads = individualLeads.filter(lead => 
    selectedIndividualLeads.includes(lead.id)
  );

  const viewIconStyle = css`
    width: 20px;
    height: 20px;
    padding: 0;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: transparent;
    color: #6B7280;
    transition: transform 0.2s ease, color 0.2s ease;
    &:hover {
      color: #4CAF50;
      transform: scale(1.2);
    }
    &:active {
      transform: scale(0.95);
    }
  `;

  return (
    <div css={css`
      width: 100%;
      height: 600px;
      padding: 15px;
      border: 1px solid #ccc; 
      border-radius: 10px;
      background-color: white;
      display: flex;
      flex-direction: column;
    `}>
      <h3 css={css`font-size: 1.2em; font-weight: bold; margin-bottom: 10px;`}>
        Total Contacts: {totalSelectedContacts}
      </h3>

      <h4>Selected Groups</h4>
      {selectedGroups.length > 0 ? (
        <div css={css`margin-bottom: 20px;`}>
          {selectedGroups.map((group, index) => (
            <div key={index} css={css`margin-bottom: 10px;`}>
              {group.name}: {group.contacts} contacts
            </div>
          ))}
        </div>
      ) : (
        <div css={css`margin-bottom: 20px;`}>No groups selected</div>
      )}

      {selectedLeads.length > 0 && (
        <div css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 20px;
        `}>
          <span>Individual Leads Selected: {selectedLeads.length}</span>
          <button
            css={viewIconStyle}
            onClick={() => setIsPopupOpen(true)}
            aria-label="View individual leads"
          >
            <IoEyeOutline size={20} />
          </button>
        </div>
      )}

      <div css={css`flex: 1; overflow: hidden; display: flex; flex-direction: column;`}>
        <h4>Contacts</h4>
        <div css={[css`
          flex: 1;
          overflow-y: auto;
          border: 1px solid #eee;
          padding: 10px;
          margin-top: 10px;
        `, scrollbarStyles]}>
          {allContacts.map((contact, index) => (
            <div key={index}>
              {contact.name} ({contact.group})
            </div>
          ))}
          {selectedLeads.map((lead, index) => (
            <div key={`individual-${index}`}>
              {lead.name} (Individual Lead)
            </div>
          ))}
        </div>
      </div>

      <Popup
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        title="Selected Individual Leads"
        subtitle={`Total Leads: ${selectedLeads.length}`}
      >
        <CustomTable
          headers={['Name', 'Phone']}
          data={selectedLeads.map(lead => [
            lead.name,
            lead.phone
          ])}
          emptyMessage="No individual leads selected"
        />
      </Popup>
    </div>
  );
};

export default SelectedGroups;