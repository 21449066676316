import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectAuthToken } from '../../../store/selectors';
import { logout } from '../../../store/authSlice';
import ImportWindow from '../../../components/Single/ImportWindow';
import ShareIcon from '../../../components/images/Share.png';
import InfoIcon from '../../../components/images/information.png';
import { FiEdit3 } from 'react-icons/fi';
import DeleteButton from '../../../components/Single/DeleteButton';
import ConfirmationWindow from '../../../components/Single/ConfirmationWindow';
import campaignAPI from '../../../service/campaignAPI';

const Business = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector(selectAuthToken);
  const [businesses, setBusinesses] = useState([]);
  const [businessName, setBusinessName] = useState('');
  const [about, setAbout] = useState('');
  const [location, setLocation] = useState('');
  const [email, setEmail] = useState('');
  const [website, setWebsite] = useState('');
  const [link1, setLink1] = useState('');
  const [link2, setLink2] = useState('');
  const [importedImage, setImportedImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [businessToDelete, setBusinessToDelete] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    fetchBusinesses();
  }, [token]);

  const fetchBusinesses = async () => {
    try {
      const response = await campaignAPI.fetchBusinesses(token);
      setBusinesses(response);
    } catch (error) {
      console.error('Error fetching businesses:', error);
      if (error.response && error.response.status === 401) {
        dispatch(logout());
        navigate('/login');
      } else {
        setErrorMessage('Failed to fetch businesses');
      }
    }
  };


  const handleImageImport = (file) => {
    console.log('File selected:', file); // Debug log
    setImportedImage(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setIsLoading(true);
  
    if (!businessName) {
      setErrorMessage('Business Name is required.');
      setIsLoading(false);
      return;
    }
  
    try {
      let imageUrl = '';
      if (importedImage) {
        console.log('Uploading image:', importedImage); // Debug log
        const imageResponse = await campaignAPI.uploadImage(token, importedImage);
        imageUrl = imageResponse.url;
        console.log('Image uploaded, URL:', imageUrl); // Debug log
      }
  
      const businessData = {
        business_name: businessName,
        business_descriptions: about,
        business_location: location,
        business_email: email,
        website: website,
        facebook_link: link1,
        instagram_link: link2,
        profile_picture: imageUrl,
      };
  
      console.log('Sending business data:', businessData); // Debug log
  
      const response = await campaignAPI.createBusiness(token, businessData);
  
      console.log('Business profile created:', response);
      setSuccessMessage('Business profile updated successfully!');
      fetchBusinesses();
      resetForm();
    } catch (error) {
      console.error('Error updating business profile:', error);
      setErrorMessage(`Failed to update business profile: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = (business) => {
    setBusinessName(business.business_name);
    setAbout(business.business_descriptions);
    setLocation(business.business_location);
    setEmail(business.business_email);
    setWebsite(business.website);
    setLink1(business.facebook_link);
    setLink2(business.instagram_link);
    setImportedImage(null);
  };

  const handleDeleteClick = (business) => {
    setBusinessToDelete(business);
    setIsConfirmationOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (businessToDelete) {
      try {
        await campaignAPI.deleteBusiness(token, businessToDelete.business_id);
        setSuccessMessage('Business deleted successfully!');
        fetchBusinesses();
      } catch (error) {
        console.error('Error deleting business:', error);
        setErrorMessage(`Failed to delete business: ${error.message}`);
      }
    }
    setIsConfirmationOpen(false);
    setBusinessToDelete(null);
  };

  const handleDeleteCancel = () => {
    setIsConfirmationOpen(false);
    setBusinessToDelete(null);
  };

  const resetForm = () => {
    setBusinessName('');
    setAbout('');
    setLocation('');
    setEmail('');
    setWebsite('');
    setLink1('');
    setLink2('');
    setImportedImage(null);
  };


    return (
      <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'flex-start',
        maxWidth: '1200px',
        margin: '0 auto',
        padding: '20px'
      }}>
{/* Left part: List of businesses */}
<div style={{ 
  width: '25%', 
  marginRight: '20px', 
  backgroundColor: 'white', 
  padding: '20px', 
  borderRadius: '10px', 
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)'
}}>
  <h2>Your Businesses</h2>
  {successMessage && <p style={{ color: 'green', textAlign: 'center' }}>{successMessage}</p>}
  {businesses.map((business) => (
    <div key={business.business_id} style={{
      display: 'flex',
      alignItems: 'center',
      marginBottom: '10px',
      padding: '10px',
      border: '1px solid #e6e6e6',
      borderRadius: '10px'
    }}>
      <img
        src={business.profile_picture || '/placeholder.png'}
        alt={business.business_name}
        style={{ 
          width: '40px', 
          height: '40px', 
          borderRadius: '50%', 
          marginRight: '10px',
          objectFit: 'cover'
        }}
      />
      <h3 style={{ margin: '0', flexGrow: 1 }}>{business.business_name}</h3>
      <div>
        <FiEdit3
          onClick={() => handleEdit(business)}
          style={{ cursor: 'pointer', marginRight: '10px' }}
        />
        <DeleteButton onClick={() => handleDeleteClick(business)} />
      </div>
    </div>
  ))}
</div>

        <ConfirmationWindow
        isOpen={isConfirmationOpen}
        onClose={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
        message={`Are you sure you want to delete ${businessToDelete?.business_name}?`}
      />
  
          {/* Middle part: Form for input */}
          <div style={{ 
        width: '40%', 
        marginRight: '20px',
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)'
      }}>
        <h1 style={{ textAlign: 'center', marginBottom: '30px' }}>Update Business Profile</h1>
  
        {errorMessage && (
          <p style={{ color: 'red', textAlign: 'center' }}>{errorMessage}</p>
        )}
  
        <div style={{ marginBottom: '20px' }}>
          <label style={{ display: 'block', marginBottom: '5px' }}>Business Name *</label>
          <input
            type="text"
            value={businessName}
            onChange={(e) => setBusinessName(e.target.value)}
            style={{
              width: '100%',
              padding: '10px',
              fontSize: '16px',
              borderRadius: '5px',
              border: '1px solid #ccc',
            }}
            required
          />
        </div>
  
        <ImportWindow
          type_of_import="image"
          onImport={handleImageImport}
          addSubmit={false}
        />
  
  <div style={{ marginBottom: '20px' }}>
  <label style={{ display: 'block', marginBottom: '5px' }}>About</label>
  <textarea
    value={about}
    onChange={(e) => setAbout(e.target.value)}
    style={{
      width: '100%',
      padding: '10px',
      fontSize: '16px',
      borderRadius: '5px',
      border: '1px solid #ccc',
      height: '80px',
    }}
  />
</div>

<div style={{ marginBottom: '20px' }}>
  <label style={{ display: 'block', marginBottom: '5px' }}>Location</label>
  <input
    type="text"
    value={location}
    onChange={(e) => setLocation(e.target.value)}
    style={{
      width: '100%',
      padding: '10px',
      fontSize: '16px',
      borderRadius: '5px',
      border: '1px solid #ccc',
    }}
  />
</div>

<div style={{ marginBottom: '20px' }}>
  <label style={{ display: 'block', marginBottom: '5px' }}>Email</label>
  <input
    type="email"
    value={email}
    onChange={(e) => setEmail(e.target.value)}
    style={{
      width: '100%',
      padding: '10px',
      fontSize: '16px',
      borderRadius: '5px',
      border: '1px solid #ccc',
    }}
  />
</div>

<div style={{ marginBottom: '20px' }}>
  <label style={{ display: 'block', marginBottom: '5px' }}>Website</label>
  <input
    type="url"
    value={website}
    onChange={(e) => setWebsite(e.target.value)}
    style={{
      width: '100%',
      padding: '10px',
      fontSize: '16px',
      borderRadius: '5px',
      border: '1px solid #ccc',
    }}
  />
</div>

<div style={{ marginBottom: '20px' }}>
  <label style={{ display: 'block', marginBottom: '5px' }}>Facebook Link</label>
  <input
    type="url"
    value={link1}
    onChange={(e) => setLink1(e.target.value)}
    style={{
      width: '100%',
      padding: '10px',
      fontSize: '16px',
      borderRadius: '5px',
      border: '1px solid #ccc',
    }}
  />
</div>

<div style={{ marginBottom: '20px' }}>
  <label style={{ display: 'block', marginBottom: '5px' }}>Instagram Link</label>
  <input
    type="url"
    value={link2}
    onChange={(e) => setLink2(e.target.value)}
    style={{
      width: '100%',
      padding: '10px',
      fontSize: '16px',
      borderRadius: '5px',
      border: '1px solid #ccc',
    }}
  />
</div>  
        <button
          onClick={handleSubmit}
          disabled={isLoading}
          style={{
            width: '100%',
            padding: '15px',
            fontSize: '18px',
            backgroundColor: isLoading ? '#cccccc' : '#4CAF50',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: isLoading ? 'not-allowed' : 'pointer',
            transition: 'background-color 0.3s',
          }}
        >
          {isLoading ? 'Updating...' : 'Update Profile'}
        </button>
      </div>
  
      {/* Right part: Real-time preview */}
      <div style={{
        width: '25%',
        padding: '20px',
        backgroundColor: 'white',
        border: '1px solid #e6e6e6',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
      }}>
        <div style={{ textAlign: 'center', marginBottom: '10px' }}>
          {importedImage ? (
            <img
              src={URL.createObjectURL(importedImage)}
              alt="Imported"
              style={{ width: '100px', height: '100px', borderRadius: '50%', marginBottom: '10px' }}
            />
          ) : (
            <div
              style={{
                width: '100px',
                height: '100px',
                borderRadius: '50%',
                backgroundColor: '#ddd',
                display: 'inline-block',
                marginBottom: '10px',
              }}
            ></div>
          )}
        </div>

        <p style={{ color: '#000', fontSize: '16px', margin: '5px 0', fontWeight: 'bold' }}>
          {businessName || 'Business Name'}
        </p>

        <img
          src={ShareIcon}
          alt="Share Icon"
          style={{ width: '24px', height: '24px', marginBottom: '2px', marginTop: '20px' }}
        />

        <p style={{ fontSize: '14px', color: '#008069', marginTop: '0', marginBottom: '20px' }}>
          Share
        </p>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '10px',
            backgroundColor: '#f9f9f9',
            borderTop: '1px solid #e6e6e6',
            borderBottom: '1px solid #e6e6e6',
            marginBottom: '20px',
          }}
        >
          <p style={{ fontSize: '14px', color: '#555', margin: 0 }}>This is a business account.</p>
          <img src={InfoIcon} alt="Info Icon" style={{ width: '16px', height: '16px' }} />
        </div>

        <p style={{ textAlign: 'left', fontSize: '14px', color: '#555', marginBottom: '10px' }}>
          {about || 'About the business...'}
        </p>
        <p style={{ textAlign: 'left', fontSize: '14px', color: '#555', marginBottom: '10px' }}>
          {location || 'Location'}
        </p>
        <p style={{ textAlign: 'left', fontSize: '14px', color: '#555', marginBottom: '10px' }}>
          {email || 'Email'}
        </p>
        <p style={{ textAlign: 'left', fontSize: '14px', color: '#555', marginBottom: '10px' }}>
          {website || 'Website URL'}
        </p>
        <p style={{ textAlign: 'left', fontSize: '14px', color: '#555', marginBottom: '10px' }}>
          {link1 || 'Link 1'}
        </p>
        <p style={{ textAlign: 'left', fontSize: '14px', color: '#555' }}>
          {link2 || 'Link 2'}
        </p>
      </div>
    </div>
  );
};

export default Business;