import React, { useRef } from 'react';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import ImportWindow from '../../../components/Single/ImportWindow';

const containerStyles = css`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`;

const titleStyles = css`
  text-align: center;
  margin-top: 20px;
  margin-bottom: 5px;
  font-size: 1em;
  font-weight: bold;
`;

const buttonStyles = css`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  background-color: #FFFFFF;
  color: #4CAF50;
  border: 2px solid #4CAF50;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  transition: all 0.3s ease;
  &:hover {
    background-color: #4CAF50;
    color: white;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const fileInfoStyles = css`
  margin-top: 10px;
  text-align: center;
  font-size: 0.9em;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
`;

const duplicateTextStyles = css`
  color: #666;
  font-size: 0.8em;
  font-style: italic;
`;

const ImportLeads = ({ onImport, onAddSpreadsheet, importedFile, isImporting }) => {
  const importRef = useRef();

  const handleFileImport = (file) => {
    if (file && (file.type === 'text/csv' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
      onImport(file);
    } else {
      console.error('Invalid file type. Please upload a CSV or Excel file.');
    }
  };

  const handleAdd = async () => {
    await onAddSpreadsheet();
    importRef.current?.reset();
  };

  return (
    <div css={containerStyles}>
      <div css={titleStyles}>Add New Leads</div>
      <ImportWindow
        ref={importRef}
        type_of_import="spreadsheet"
        onImport={handleFileImport}
        addSubmit={false}
        customMessage="Locate or Drag Your Spreadsheet"
        validFormats="CSV, Excel, Database"
      />
      <div css={fileInfoStyles}>
        {importedFile && !isImporting ? (
          <>
            <p css={css`color: #4CAF50;`}>
              File selected: {importedFile.name}
            </p>
            <p css={duplicateTextStyles}>
              Duplicates will be removed
            </p>
          </>
        ) : null}
      </div>
      <button 
        css={buttonStyles} 
        onClick={handleAdd}
        disabled={!importedFile || isImporting}
      >
        {isImporting ? 'Importing...' : 'Add Spreadsheet'}
      </button>
    </div>
  );
};

export default ImportLeads;