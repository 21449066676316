import React from 'react';
import styled from '@emotion/styled';

const TableContainer = styled.div`
  max-height: 530px;
  overflow-y: auto;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  border: 1px solid #e5e7eb;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #f9fafb;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: #cbd5e1;
    border-radius: 4px;
    border: 2px solid #f9fafb;
    &:hover {
      background: #94a3b8;
    }
  }
`;

const StyledTable = styled.table`
  width: 100%;
  table-layout: fixed;
  border-spacing: 0;
  border-collapse: collapse;
`;

const TableHeader = styled.thead`
  background-color: #f9fafb;
`;

const TableHeaderCell = styled.th`
  font-weight: 600;
  color: #111827;
  padding: 12px 16px;
  border-bottom: 2px solid #e5e7eb;
  text-align: left;
`;

const TableBody = styled.tbody``;

const TableCell = styled.td`
  padding: 12px 16px;
  border-bottom: 1px solid #e5e7eb;
`;

const TableRow = styled.tr`
  &:hover {
    background-color: #f9fafb;
  }
`;

const EmptyMessage = styled.div`
  text-align: center;
  color: #6b7280;
  padding: 32px 16px;
  font-size: 0.875rem;
`;

const CustomTable = ({ headers, data, emptyMessage, isLoading, hideEmptyMessage }) => {
  if (isLoading) {
    return null;
  }

  return (
    <TableContainer>
      <StyledTable>
        <TableHeader>
          <tr>
            {headers.map((header, index) => (
              <TableHeaderCell key={index}>{header}</TableHeaderCell>
            ))}
          </tr>
        </TableHeader>
        <TableBody>
          {data.length > 0 ? (
            data.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <TableCell key={cellIndex}>{cell}</TableCell>
                ))}
              </TableRow>
            ))
          ) : !hideEmptyMessage && !isLoading ? (
            <tr>
              <TableCell colSpan={headers.length}>
                <EmptyMessage>{emptyMessage || 'No data available.'}</EmptyMessage>
              </TableCell>
            </tr>
          ) : null}
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
};

export default CustomTable;