/**
 * deployHooks.js
 * This file contains all the business logic that was previously in StepsDeployLogic.js
 * It's moved here to avoid circular dependencies and follows the React hooks pattern
 * Used by: StepsDeploy.js
 */

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { 
    // Selectors to get data from Redux store
    selectSelectedGroups,  // Gets groups selected in the Leads step
    selectSelectedGroupIds,  // Gets IDs of selected groups
    selectTotalSelectedContacts,  // Gets total contacts (groups + individual)
    selectTotalWithoutDuplicates,  // Gets count after duplicate removal
    selectSelectedBusiness,  // Gets business selected in Business step
    selectSelectedPost,  // Gets post selected in Post step
    selectAuthToken,  // Gets authentication token
    selectIndividualLeads,  // Gets all individual leads
    selectSelectedIndividualLeads  // Gets selected individual leads IDs
} from '../../../store/selectors';
import { createCampaign } from '../../../store/campaignActions';
import { logout } from '../../../store/authSlice';
import campaignAPI from '../../../service/campaignAPI';

export const useDeployLogic = () => {
    // Navigation and Redux setup
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = useSelector(selectAuthToken);
    
    // Local state for deployment page
    const [scheduleDate, setScheduleDate] = useState(new Date());
    const [error, setError] = useState(null);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [isSimulatedDeployment, setIsSimulatedDeployment] = useState(false);
    const [isBuyCreditsOpen, setIsBuyCreditsOpen] = useState(false);
    const [postData, setPostData] = useState(null);

    // Get data from Redux store
    const selectedGroups = useSelector(selectSelectedGroups);
    const selectedGroupIds = useSelector(selectSelectedGroupIds);
    const totalSelectedContacts = useSelector(selectTotalSelectedContacts);
    const totalWithoutDuplicates = useSelector(selectTotalWithoutDuplicates);
    const selectedBusiness = useSelector(selectSelectedBusiness);
    const selectedPost = useSelector(selectSelectedPost);
    const individualLeads = useSelector(selectIndividualLeads);
    const selectedIndividualLeads = useSelector(selectSelectedIndividualLeads);

    // Fetch post preview data when a post is selected
    useEffect(() => {
        const fetchPostData = async () => {
            if (selectedPost?.post_id && token) {
                try {
                    const data = await campaignAPI.fetchGetPost(token, selectedPost.post_id);
                    setPostData(data);
                } catch (error) {
                    console.error("Failed to fetch post data:", error);
                    setError("Failed to load post preview");
                }
            }
        };
        fetchPostData();
    }, [selectedPost, token]);

    // Validate selections - Checks if user has selected all required items
    useEffect(() => {
        if (!selectedBusiness || !selectedPost || 
            (selectedGroups.length === 0 && selectedIndividualLeads.length === 0)) {
            setError("Please ensure you've selected a business, post, and at least one lead (group or individual) before deploying.");
        } else {
            setError(null);
        }
    }, [selectedBusiness, selectedPost, selectedGroups, selectedIndividualLeads]);

    // Handler for date picker changes
    const handleDateChange = (newDate) => {
        setScheduleDate(newDate);
    };

    // Main deploy handler - Creates the campaign
    const handleDeploy = async () => {
        if (error) {
            setIsBuyCreditsOpen(true);
            return;
        }

        try {
            const campaignData = {
                group_ids: selectedGroupIds,
                individual_lead_ids: selectedIndividualLeads,
                post_id: selectedPost.post_id,
                business_id: selectedBusiness.business_id,
                scheduled_time: scheduleDate.toISOString(),
            };

            const response = await dispatch(createCampaign(campaignData)).unwrap();
            setShowConfirmation(true);
        } catch (error) {
            if (error.response?.status === 401) {
                dispatch(logout());
                navigate('/login');
            } else {
                setError("Failed to create campaign. Please try again.");
            }
        }
    };

    // Handler for simulation button
    const handleSimulatedDeploy = () => {
        setIsSimulatedDeployment(true);
        setTimeout(() => {
            setShowConfirmation(true);
            setIsSimulatedDeployment(false);
        }, 1500);
    };

    // Handler for closing confirmation dialog
    const handleCloseConfirmation = () => {
        setShowConfirmation(false);
        navigate('/campaign');
    };

    // Return all values and handlers needed by StepsDeploy.js
    return {
        // Data
        selectedGroups,
        totalSelectedContacts,
        totalWithoutDuplicates,
        selectedBusiness,
        selectedPost,
        postData,
        error,
        scheduleDate,
        showConfirmation,
        isSimulatedDeployment,
        isBuyCreditsOpen,
        individualLeads,
        selectedIndividualLeads,
        // Handlers
        handleDateChange,
        handleDeploy,
        handleSimulatedDeploy,
        handleCloseConfirmation,
        setIsBuyCreditsOpen
    };
};