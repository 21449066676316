// analyticsMiddleware.js

/**
 * Analytics Middleware
 * 
 * This middleware intercepts Redux actions and logs them for analytics purposes.
 * It can be extended to send data to an actual analytics service.
 */
const analyticsMiddleware = store => next => action => {
  try {
    // Check if the action type includes 'FETCH' or 'CREATE'
    if (action.type.includes('FETCH') || action.type.includes('CREATE')) {
      // Log the action type
      console.log('Analytics:', action.type);

      // TODO: Integrate with your analytics service
      // Example:
      // analyticsService.trackEvent({
      //   category: 'Redux Action',
      //   action: action.type,
      //   label: JSON.stringify(action.payload)
      // });
    }

    // You can add more conditions here to track other types of actions
    // Example:
    // if (action.type.includes('ERROR')) {
    //   console.error('Error Action:', action.type, action.payload);
    //   // analyticsService.trackError(action.type, action.payload);
    // }

    // Pass the action to the next middleware or reducer
    return next(action);
  } catch (error) {
    // Log any errors that occur in the analytics middleware
    console.error('Error in analytics middleware:', error);

    // Ensure the action still gets passed on even if analytics fails
    return next(action);
  }
};

export default analyticsMiddleware;