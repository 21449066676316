/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import NextStepButton from '../../../components/Single/NextStepButton.js';
import LeadsTable from './LeadsTable';
import TotalContacts from './TotalContacts';
import ImportLeads from './ImportLeads.js';
import ViewLead from './ViewLead.js';
import CurrentCampaignWindow from '../../../components/CurrentCampaignWindow/CurrentCampaignWindow.js';
import ChatButton from '../../../components/Single/ContactButton.js';
import LeadsLogic from './LeadsLogic.js';

const containerStyles = css`
  display: flex;
  justify-content: center;
  gap: 30px;
  max-width: 1000px;
  margin: 0 auto;
`;

const columnStyles = css`
  width: 50%;
  flex-shrink: 0;
`;

const alertStyles = (type) => css`
  background-color: ${type === 'success' ? '#d4edda' : '#f8d7da'};
  color: ${type === 'success' ? '#155724' : '#721c24'};
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 4px;
  text-align: center;
  width: 100%;
`;

const StepsLeads = () => {
  const {
    importedFile,
    successMessage,
    errorMessage,
    isImporting,
    groups,
    selectedGroups,
    isLoading,
    error,
    totalSelectedContacts,
    totalWithoutDuplicates,
    duplicatesRemoved,
    isAnyGroupSelected,
    handleSpreadsheetImport,
    handleAddSpreadsheet,
    handleSubmit,
    handleLeadSelection,
    handleSelectAll,
    handleViewGroup,
    viewModalData,
    handleCloseViewModal
  } = LeadsLogic();

  return (
    <>
      <CurrentCampaignWindow currentStep={1}>
        <p>Total Contacts: {selectedGroups.reduce((sum, group) => sum + (group.contacts || 0), 0)}</p>
      </CurrentCampaignWindow>
      
      {successMessage && <div css={alertStyles('success')}>{successMessage}</div>}
      {errorMessage && <div css={alertStyles('error')}>{errorMessage}</div>}

      <div css={containerStyles}>
        <div css={columnStyles}>
          <h2>Choose Leads</h2>
          {isLoading && <p>Loading...</p>}
          {error && <p>Error: {typeof error === 'object' ? JSON.stringify(error) : error}</p>}
          {!isLoading && !error && groups.length === 0 && <p>No leads found. Please add some leads.</p>}
          {!isLoading && !error && groups.length > 0 && (
            <>
              <TotalContacts
                totalSelectedContacts={totalSelectedContacts}
                onSelectAll={handleSelectAll}
                totalWithoutDuplicates={totalWithoutDuplicates}
                duplicatesRemoved={duplicatesRemoved}
              />
              <LeadsTable
                groups={groups}
                selectedGroups={selectedGroups}
                onLeadSelection={handleLeadSelection}
                onViewGroup={handleViewGroup}
              />
            </>
          )}
        </div>
    
        <div css={columnStyles}>
          <NextStepButton 
            isEnabled={isAnyGroupSelected} 
            onClick={handleSubmit}
          >
            Proceed (Remove Duplicates)
          </NextStepButton>
          <ImportLeads
            importedFile={importedFile}
            onImport={handleSpreadsheetImport}
            onAddSpreadsheet={handleAddSpreadsheet}
            isImporting={isImporting}
          />
        </div>
      </div>

      <ChatButton type="chatNow" location="rightCorner" />

      <ViewLead
        isOpen={viewModalData.isOpen}
        onClose={handleCloseViewModal}
        data={viewModalData.data}
        groupName={viewModalData.groupName}
        totalLeads={viewModalData.totalLeads}
      />
    </>
  );
};

export default StepsLeads;