import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';

const RequestCreditsButton = ({ className, style }) => {
  const handleClick = () => {
    const whatsappNumber = "972585544618";
    const message = encodeURIComponent("Hi, I'm interested in buying credits for Sendue.");
    window.open(`https://web.whatsapp.com/send?phone=${whatsappNumber}&text=${message}`, '_blank');
  };

  return (
    <div className={className} style={style}>
      <a href="#" className="btn btn-white btn-animated" onClick={handleClick}>
        <FaWhatsapp size={32} style={{ marginRight: '8px' }} />
        Request Credits
      </a>
      <style jsx>{`
        .btn:link,
        .btn:visited {
          text-transform: uppercase;
          text-decoration: none;
          padding: 8px 16px;
          display: inline-flex;
          align-items: center;
          border-radius: 100px;
          transition: all .2s;
          position: relative;
          font-size: 13px;
          font-weight: 600;
        }
        
        .btn:hover {
          transform: translateY(-3px);
          box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
        }
        
        .btn:active {
          transform: translateY(-1px);
          box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
        }
        
        .btn-white {
          background-color: #4CAF50;
          color: white;
        }
        
        .btn::after {
          content: "";
          display: inline-block;
          height: 100%;
          width: 100%;
          border-radius: 100px;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
          transition: all .4s;
        }
        
        .btn-white::after {
          background-color: #4CAF50;
        }
        
        .btn:hover::after {
          transform: scaleX(1.4) scaleY(1.6);
          opacity: 0;
        }
        
        .btn-animated {
          animation: moveInBottom 0.5s ease-out;
          animation-fill-mode: backwards;
        }
        
        @keyframes moveInBottom {
          0% {
            opacity: 0;
            transform: translateY(30px);
          }
        
          100% {
            opacity: 1;
            transform: translateY(0px);
          }
        }
      `}</style>
    </div>
  );
};

export default RequestCreditsButton;