import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import styled from '@emotion/styled';

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 66px;
  height: 66px;
  pointer-events: none;
`;

const Flash = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 3px solid #7CFC00; // Brighter green color
  border-radius: 50%;
  box-sizing: border-box;
  box-shadow: 0 0 10px #7CFC00; // Add a glow effect
`;

const AttentionGrabber = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShow(true), 100);
    return () => clearTimeout(timer);
  }, []);

  const flashVariants = {
    hidden: { scale: 1, opacity: 0 },
    visible: {
      scale: [1, 1.3, 1],
      opacity: [0, 0.8, 0],
      transition: { 
        duration: 2, // Increased duration for slower animation
        times: [0, 0.6, 1], // Adjusted timing for more gradual expansion
        ease: "easeInOut" // Smooth easing function
      }
    }
  };

  if (!show) return null;

  return (
    <Container>
      <Flash
        variants={flashVariants}
        initial="hidden"
        animate="visible"
      />
    </Container>
  );
};

export default AttentionGrabber;