/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import CurrentCampaignWindow from '../../../components/CurrentCampaignWindow/CurrentCampaignWindow.js';
import NextStepButton from '../../../components/Single/NextStepButton';
import BusinessList from './BusinessList';
import BusinessForm from './BusinessForm';
import BusinessPreview from './BusinessPreview';
import ConfirmationWindow from '../../../components/Single/ConfirmationWindow';
import ChatButton from '../../../components/Single/ContactButton.js';
import { useStepsBusinessLogic } from './StepsBusinessLogic';

const containerStyles = css`
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
  max-width: 1400px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
`;

const columnStyles = css`
  width: 28%;
`;

const formColumnStyles = css`
  width: 42%;
`;

const rightColumnStyles = css`
  width: 28%;
  position: sticky;
  top: 20px;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  gap: 40px; 
  margin-top: -20px; 
`;

const alertStyles = (type) => css`
  background-color: ${type === 'success' ? '#d4edda' : '#f8d7da'};
  color: ${type === 'success' ? '#155724' : '#721c24'};
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 4px;
`;

const StepsBusiness = () => {
  const {
    businesses,
    selectedBusiness,
    selectedGroups,
    successMessage,
    errorMessage,
    formData,
    importedImage,
    isConfirmationOpen,
    confirmationTarget,
    isSubmitting,
    isEditing,
    handleInputChange,
    handleImageImport,
    handleSubmit,
    handleClearForm,
    handleBusinessSelect,
    handleEdit,
    handleDeleteClick,
    handleDeleteConfirm,
    handleNext,
    setIsConfirmationOpen,
    setConfirmationTarget,
  } = useStepsBusinessLogic();


  return (
    <>
      <CurrentCampaignWindow currentStep={2}>
        <p>Total Contacts: {selectedGroups.reduce((sum, group) => sum + (group.contacts || 0), 0)}</p>
      </CurrentCampaignWindow>
      
      {successMessage && <div css={alertStyles('success')}>{successMessage}</div>}
      {errorMessage && <div css={alertStyles('error')}>{errorMessage}</div>}

      <div css={containerStyles}>
        <div css={columnStyles}>
          <BusinessList 
            businesses={businesses}
            selectedBusiness={selectedBusiness}
            onSelect={handleBusinessSelect}
            onEdit={handleEdit}
            onDelete={handleDeleteClick}
          />
          <ConfirmationWindow 
            isOpen={isConfirmationOpen} 
            onClose={() => {
              setIsConfirmationOpen(false);
              setConfirmationTarget(null);
            }} 
            onConfirm={handleDeleteConfirm} 
            message="Are you sure you want to delete this business?"
            targetId={confirmationTarget}
          />
        </div>

        <div css={formColumnStyles}>
          <BusinessForm 
            formData={formData || {}}
            onInputChange={handleInputChange}
            onImageImport={handleImageImport}
            onSubmit={handleSubmit}
            onClear={handleClearForm}
            errorMessage={errorMessage}
            isSubmitting={isSubmitting}
            isEditing={isEditing}
          />
        </div>

        <div css={rightColumnStyles}>
          <NextStepButton isEnabled={!!selectedBusiness} onClick={handleNext} />
          <BusinessPreview formData={formData} importedImage={importedImage} />
        </div>
      </div>

      <ChatButton type="chatNow" location="rightCorner" />

    </>
  );
};

export default StepsBusiness;
