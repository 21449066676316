/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { FiEdit3 } from 'react-icons/fi';
import DeleteButton from '../../../components/Single/DeleteButton';
import SelectCircle from '../../../components/Single/SelectCircle';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedPost } from '../../../store/campaignSlice';

const postItemStyles = css`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  position: relative;
`;

const postImageStyles = css`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
`;

const PostList = ({ posts, onDeletePost }) => {
  const dispatch = useDispatch();
  const selectedPost = useSelector(state => state.campaign.selectedPost);

  const handlePostSelect = (post) => {
    dispatch(setSelectedPost(post));
  };

  return (
    <div>
      <h2>Your Posts</h2>
      {posts && posts.length > 0 ? (
        posts.map((post) => post && (
          <div key={post.post_id} css={postItemStyles}>
            <SelectCircle 
              isSelected={selectedPost?.post_id === post.post_id}
              onClick={() => handlePostSelect(post)}
            />
            <img 
              css={postImageStyles}
              src={post.header || '/placeholder.png'} 
              alt={post.template_name} 
            />
            <h3 css={css`margin: 0; flex-grow: 1;`}>{post.template_name}</h3>
            <FiEdit3
              onClick={() => console.log('Edit post:', post.post_id)}
              css={css`cursor: pointer; margin-right: 10px;`}
            />
            <DeleteButton onClick={() => onDeletePost(post.post_id)} />
          </div>
        ))
      ) : (
        <p>No posts available.</p>
      )}
    </div>
  );
};

export default PostList;