import React, { useRef, useEffect, useState } from 'react';
import { Stage, Layer, Shape, Circle } from 'react-konva';

const FluidCanvasStep = ({ fillPercentage, previousStep = 0 }) => {
  const [, setTick] = useState(0);
  const [currentFill, setCurrentFill] = useState(previousStep * 25);
  const bubblesRef = useRef([]);
  const animationStartTimeRef = useRef(null);

  const canvasSize = 66;
  const centerX = canvasSize / 2;
  const centerY = canvasSize / 2;
  const radius = 28;
  const borderWidth = 3;
  const ANIMATION_DURATION = 2000; // 2 seconds

  useEffect(() => {
    bubblesRef.current = Array(5).fill().map(() => createBubble());
    animationStartTimeRef.current = Date.now();

    let animationFrameId;
    const animate = () => {
      const elapsed = Date.now() - animationStartTimeRef.current;
      const progress = Math.min(elapsed / ANIMATION_DURATION, 1);
      
      const startFill = previousStep * 25;
      const targetFill = fillPercentage;
      setCurrentFill(startFill + (targetFill - startFill) * progress);
      
      setTick(prev => prev + 1);
      animationFrameId = requestAnimationFrame(animate);
    };
    
    animate();
    return () => cancelAnimationFrame(animationFrameId);
  }, [fillPercentage, previousStep]);

  const createBubble = () => ({
    x: Math.random() * canvasSize,
    y: canvasSize + Math.random() * 20,
    radius: 2 + Math.random() * 4,
    speed: 0.2 + Math.random() * 0.3,
  });

  const updateBubbles = () => {
    bubblesRef.current = bubblesRef.current.map(bubble => {
      bubble.y -= bubble.speed;
      if (bubble.y + bubble.radius < 0) {
        return createBubble();
      }
      return bubble;
    });
  };

  const drawFluid = (context, shape) => {
    const time = Date.now() / 1000;
    context.clearRect(0, 0, canvasSize, canvasSize);

    const fluidHeight = radius * 2 * (currentFill / 100);

    // Draw main fluid body with wave effect
    context.beginPath();
    context.moveTo(0, canvasSize);

    for (let x = 0; x <= canvasSize; x++) {
      const waveHeight = 
        Math.sin(x * 0.2 + time * 2) * 2 +
        Math.sin(x * 0.3 - time * 1.5) * 1.5;
      
      const y = centerY + radius - fluidHeight + waveHeight;
      context.lineTo(x, Math.max(y, centerY - radius));
    }

    context.lineTo(canvasSize, canvasSize);
    context.closePath();

    // Create and apply gradient
    const gradient = context.createLinearGradient(0, 0, 0, canvasSize);
    gradient.addColorStop(0, '#4CAF50');
    gradient.addColorStop(1, '#45a049');
    context.fillStyle = gradient;
    context.fill();

    // Update and draw bubbles
    updateBubbles();
    bubblesRef.current.forEach(bubble => {
      context.beginPath();
      context.arc(bubble.x, bubble.y, bubble.radius, 0, Math.PI * 2);
      context.strokeStyle = bubble.y > centerY + radius - fluidHeight ? 
        'rgba(255, 255, 255, 0.7)' : 
        'rgba(46, 125, 50, 0.7)';
      context.lineWidth = 1;
      context.stroke();
    });

    // Clip to circle
    context.globalCompositeOperation = 'destination-in';
    context.beginPath();
    context.arc(centerX, centerY, radius, 0, Math.PI * 2);
    context.fill();
    context.globalCompositeOperation = 'source-over';
  };

  return (
    <Stage width={canvasSize} height={canvasSize}>
      <Layer>
        <Shape 
          sceneFunc={(context, shape) => {
            drawFluid(context, shape);
            context.fillStrokeShape(shape);
          }} 
        />
        <Circle 
          x={centerX} 
          y={centerY} 
          radius={radius}
          stroke="#2E7D32"
          strokeWidth={borderWidth}
        />
      </Layer>
    </Stage>
  );
};

export default FluidCanvasStep;