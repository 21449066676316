// DeleteButton.js
import React from 'react';
import { MdClose } from 'react-icons/md';

const DeleteButton = ({ onClick }) => (
  <div
    onClick={onClick}
    style={{
      width: '20px',
      height: '20px',
      borderRadius: '50%',
      backgroundColor: '#FF6262E5',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      transition: 'all 0.3s ease',
    }}
    onMouseEnter={(e) => {
      e.currentTarget.style.backgroundColor = '#ff6666';
      e.currentTarget.style.transform = 'scale(1.1)';
    }}
    onMouseLeave={(e) => {
      e.currentTarget.style.backgroundColor = '#FF6262E5';
      e.currentTarget.style.transform = 'scale(1)';
    }}
  >
    <MdClose color="white" size={14} />
  </div>
);

export default DeleteButton;