import React, { useState } from 'react';

const CustomDatePicker = ({ onChange, initialDate = new Date() }) => {
  const [date, setDate] = useState(initialDate.toISOString().split('T')[0]);
  const [time, setTime] = useState(initialDate.toTimeString().slice(0, 5));

  const handleDateChange = (e) => {
    setDate(e.target.value);
    updateDateTime(e.target.value, time);
  };

  const handleTimeChange = (e) => {
    setTime(e.target.value);
    updateDateTime(date, e.target.value);
  };

  const updateDateTime = (newDate, newTime) => {
    const dateTime = new Date(`${newDate}T${newTime}`);
    onChange(dateTime);
  };

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      width: '100%',
    },
    input: {
      padding: '10px',
      fontSize: '16px',
      borderRadius: '5px',
      border: '1px solid #ccc',
      outline: 'none',
    },
  };

  return (
    <div style={styles.container}>
      <input
        type="date"
        value={date}
        onChange={handleDateChange}
        style={styles.input}
      />
      <input
        type="time"
        value={time}
        onChange={handleTimeChange}
        style={styles.input}
      />
    </div>
  );
};

export default CustomDatePicker;
