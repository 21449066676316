import { store } from '../store/store';
import { logout } from '../store/authSlice';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { handleLoginCallback, setUserInfo } from '../store/authSlice';

/**
 * Retrieves the current authentication token from localStorage.
 * @returns {string|null} The authentication token or null if none exists.
 */
export const getToken = () => {
  return localStorage.getItem('access_token');
};

/**
 * Sets the authentication token in localStorage.
 * @param {string} token - The token to store.
 */
export const setToken = (token) => {
  if (token) {
    localStorage.setItem('access_token', token);
  } else {
    console.error('Attempted to set null or undefined token');
  }
};

/**
 * Removes the authentication token from localStorage.
 */
export const removeToken = () => {
  localStorage.removeItem('access_token');
};

/**
 * Retrieves user information from the Redux store.
 * @returns {object} User details including ID, name, email, and profile picture.
 */
export const getUserInfo = () => {
  const state = store.getState();
  return {
    userId: state.auth.userId,
    name: state.auth.name,
    email: state.auth.email,
    picture: state.auth.picture,
  };
};

/**
 * Logs the user out and redirects to the login page.
 * Used for handling unauthorized actions.
 */
export const handleUnauthorized = () => {
  store.dispatch(logout());
  window.location.href = '/login';
};

/**
 * `AuthHandler` processes the login callback, extracts the token and user details,
 * and updates the Redux store.
 */
export const AuthHandler = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('access_token');
    if (token) {
      dispatch(handleLoginCallback(token));
      dispatch(setUserInfo({
        userId: params.get('user_id'),
        name: params.get('name'),
        email: params.get('email'),
        picture: params.get('picture')
      }));
      navigate('/campaign');
    }
  }, [location, navigate, dispatch]);

  return null;
};
