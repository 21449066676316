/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { FiEdit3 } from 'react-icons/fi';
import DeleteButton from '../../../components/Single/DeleteButton';
import SelectCircle from '../../../components/Single/SelectCircle';

const businessItemStyles = css`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  position: relative;
`;

const businessImageStyles = css`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
`;

const BusinessList = ({ businesses, selectedBusiness, onSelect, onEdit, onDelete }) => (
  <div>
    <h2>Your Businesses</h2>
    {businesses.map((business) => (
      <div key={business.business_id} css={businessItemStyles} id={`business-${business.business_id}`}>
        <SelectCircle
          isSelected={selectedBusiness && selectedBusiness.business_id === business.business_id}
          onClick={() => onSelect(business.business_id)}
        />
        <img 
          css={businessImageStyles}
          src={business.profile_picture || '/placeholder.png'} 
          alt={business.business_name} 
        />
        <h3 css={css`margin: 0; flex-grow: 1;`}>{business.business_name}</h3>
        <FiEdit3
          onClick={() => onEdit(business)}
          css={css`cursor: pointer; margin-right: 10px;`}
        />
        <DeleteButton onClick={() => onDelete(business.business_id)} />
      </div>
    ))}
  </div>
);

export default BusinessList;