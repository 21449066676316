import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectAuthToken } from '../../../store/selectors';
import { logout } from '../../../store/authSlice';
import ImportWindow from '../../../components/Single/ImportWindow';
import { MdCall, MdOutlineContentCopy } from 'react-icons/md';
import { RxExternalLink } from 'react-icons/rx';
import { FiEdit3 } from 'react-icons/fi';
import { HiOutlineEmojiHappy } from 'react-icons/hi';
import ImagePlaceholder from '../../../components/icons/place_holder.png';
import ImageBackground from '../../../components/images/post_backround.png';
import LogoIcon from '../../../components/images/sendue_logo.png';
import EmojiPicker from 'emoji-picker-react';
import DeleteButton from '../../../components/Single/DeleteButton';
import ConfirmationWindow from '../../../components/Single/ConfirmationWindow';
import campaignAPI from '../../../service/campaignAPI';

const Post = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector(selectAuthToken);
  const [title, setTitle] = useState('');
  const [postImage, setPostImage] = useState(null);
  const [postText, setPostText] = useState('');
  const [buttons, setButtons] = useState([]);
  const [isEmojiPickerOpen, setEmojiPickerOpen] = useState(false);
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const emojiPickerRef = useRef(null);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [postToDelete, setPostToDelete] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  
  // New state for imported image
  const [importedImage, setImportedImage] = useState(null);
  
  const buttonTypes = ['link', 'copy', 'call'];

  useEffect(() => {
    fetchPosts();
  }, [token]);

  const fetchPosts = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await campaignAPI.fetchPosts(token);
      setPosts(response);
    } catch (error) {
      console.error('Error fetching posts:', error);
      if (error.response && error.response.status === 401) {
        dispatch(logout());
        navigate('/login');
      } else {
        setError('Failed to fetch posts. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteClick = (post) => {
    setPostToDelete(post);
    setIsConfirmationOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (postToDelete) {
      try {
        await campaignAPI.deletePost(token, postToDelete.post_id);
        setSuccessMessage('Post deleted successfully!');
        fetchPosts();
      } catch (error) {
        console.error('Error deleting post:', error);
        setError('Failed to delete post. Please try again.');
      }
    }
    setIsConfirmationOpen(false);
    setPostToDelete(null);
  };

  const handleDeleteCancel = () => {
    setIsConfirmationOpen(false);
    setPostToDelete(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);
  
    if (!title) {
      setError('Post Title is required.');
      setIsLoading(false);
      return;
    }
  
    try {
      const postData = {
        template_name: title,
        template_type: 'default',
        template_language: 'en',
        body: postText,
        footer: 'Sent via Sendue',
        buttons: buttons.map((button, index) => ({
          action_type: button.type,
          label: button.text,
          button_action: button.action,
          order_index: index
        }))
      };

      if (postImage) {
        postData.header = postImage;
        postData.header_type = 'image';
      }
  
      const response = await campaignAPI.createPost(token, postData);
      console.log('Post created:', response);
      setSuccessMessage('Post created successfully!');
      fetchPosts();
      resetForm();
    } catch (error) {
      console.error('Error creating post:', error);
      setError('Failed to create post. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleMediaImport = async (file) => {
    setImportedImage(file);
    try {
      const response = await campaignAPI.uploadImage(token, file);
      if (response && response.url) {
        setPostImage(response.url);
      } else {
        console.error('Image upload response does not contain a URL');
        setError('Failed to upload image. Please try again.');
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      setError('Failed to upload image. Please try again.');
    }
  };
  const handleEmojiClick = (emojiObject) => {
    setPostText((prevText) => prevText + emojiObject.emoji);
  };

  const addButton = (type) => {
    if (buttons.length < 3) {
      setButtons([...buttons, { type, text: '', action: '' }]);
    }
  };

  const removeButton = (index) => {
    setButtons(buttons.filter((_, i) => i !== index));
  };

  const updateButton = (index, field, value) => {
    const newButtons = [...buttons];
    newButtons[index][field] = value;
    setButtons(newButtons);
  };

  // New resetForm function
  const resetForm = () => {
    setTitle('');
    setPostText('');
    setPostImage(null);
    setImportedImage(null);
    setButtons([]);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
        setEmojiPickerOpen(false);
      }
    };

    if (isEmojiPickerOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isEmojiPickerOpen]);

  const renderButtonInputs = () => {
    return buttons.map((button, index) => (
      <div key={index} style={{
        backgroundColor: 'white',
        padding: '15px',
        borderRadius: '5px',
        marginTop: '10px',
        boxShadow: '0 2px 5px rgba(0,0,0,0.1)'
      }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
          <h3 style={{ margin: 0 }}>{button.type.charAt(0).toUpperCase() + button.type.slice(1)} Button</h3>
          <DeleteButton onClick={() => removeButton(index)} />
        </div>
        <input
          type="text"
          value={button.text}
          onChange={(e) => updateButton(index, 'text', e.target.value)}
          placeholder="Button Text"
          style={{
            width: '100%',
            padding: '8px',
            marginBottom: '10px',
            borderRadius: '4px',
            border: '1px solid #ccc'
          }}
        />
        <input
          type="text"
          value={button.action}
          onChange={(e) => updateButton(index, 'action', e.target.value)}
          placeholder={button.type === 'link' ? 'URL' : button.type === 'copy' ? 'Text to Copy' : 'Phone Number'}
          style={{
            width: '100%',
            padding: '8px',
            borderRadius: '4px',
            border: '1px solid #ccc'
          }}
        />
      </div>
    ));
  };

  return (
    <div style={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'flex-start',
      maxWidth: '1200px',
      margin: '0 auto',
      gap: '20px'
    }}>
         {/* User Posts Column */}
    <div style={{ 
      width: '30%', 
      padding: '20px', 
      backgroundColor: 'white', 
      borderRadius: '10px', 
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
    }}>
          <h2 style={{ textAlign: 'center' }}>Your Posts</h2>
          {successMessage && <p style={{ color: 'green', textAlign: 'center' }}>{successMessage}</p>}
          {isLoading && <p>Loading posts...</p>}
          {error && <p style={{ color: 'red' }}>{error}</p>}
          {posts.map((post) => (
            <div key={post.post_id} style={{ marginBottom: '10px', padding: '10px', border: '1px solid #ddd', borderRadius: '5px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span>{post.template_name}</span>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <FiEdit3
                    style={{
                      color: 'black',
                      cursor: 'pointer',
                      transition: 'color 0.3s',
                      marginRight: '10px',
                    }}
                    onMouseEnter={(e) => (e.currentTarget.style.color = '#555')}
                    onMouseLeave={(e) => (e.currentTarget.style.color = 'black')}
                  />
                  <DeleteButton onClick={() => handleDeleteClick(post)} />
                </div>
              </div>
              {post.header && post.header_type === 'image' && (
                <img 
                  src={post.header} 
                  alt={post.template_name} 
                  style={{ width: '100%', height: 'auto', borderRadius: '5px', marginTop: '10px' }}
                />
              )}
            </div>
          ))}
        </div>
   {/* Editor Section */}
   <form onSubmit={handleSubmit} style={{ 
      width: '40%', 
      padding: '20px', 
      backgroundColor: 'white',
      borderRadius: '10px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
    }}>
        <h1 style={{ textAlign: 'center', marginBottom: '30px' }}>Create Post</h1>

        <div style={{ marginBottom: '20px' }}>
          <label htmlFor="title">Post Title:</label>
          <input
            id="title"
            type="text"
            value={title}
            onChange={handleTitleChange}
            style={{ width: '100%', padding: '8px', marginTop: '5px' }}
            placeholder="Enter post title"
            required
          />
        </div>

        <ImportWindow
          type_of_import="image_vid"
          onImport={handleMediaImport}
          addSubmit={false}
        />

        <div style={{ marginBottom: '20px', position: 'relative' }}>
          <label style={{ display: 'block', marginBottom: '5px' }}>Post Text</label>
          <textarea
            value={postText}
            onChange={(e) => setPostText(e.target.value)}
            placeholder="Enter post text here..."
            style={{
              width: '100%',
              padding: '10px',
              fontSize: '16px',
              borderRadius: '5px',
              border: '1px solid #ccc',
              height: '80px',
              color: postText ? '#000' : '#aaa',
            }}
          />
          <button
            type="button"
            onClick={() => setEmojiPickerOpen((prev) => !prev)}
            style={{
              position: 'absolute',
              right: '10px',
              top: '30px',
              backgroundColor: 'transparent',
              border: 'none',
              cursor: 'pointer',
            }}
          >
            <HiOutlineEmojiHappy style={{ color: '#000', fontSize: '20px' }} />
          </button>
          {isEmojiPickerOpen && (
            <div ref={emojiPickerRef} style={{ position: 'absolute', top: '60px', left: '0', zIndex: '1000' }}>
              <EmojiPicker onEmojiClick={handleEmojiClick} />
            </div>
          )}
        </div>

        <div style={{ marginBottom: '20px' }}>
          <h3>Add Button</h3>
          <select
            value=""
            onChange={(e) => addButton(e.target.value)}
            style={{
              width: '100%',
              padding: '10px',
              borderRadius: '5px',
              border: '1px solid #ccc',
              fontSize: '16px',
            }}
          >
            <option value="">Select Button</option>
            {buttonTypes.filter(type => !buttons.some(button => button.type === type)).map(type => (
              <option key={type} value={type}>{type.charAt(0).toUpperCase() + type.slice(1)} Button</option>
            ))}
          </select>
          
          {renderButtonInputs()}
        </div>

        <button
          type="submit"
          style={{
            width: '100%',
            padding: '15px',
            fontSize: '18px',
            backgroundColor: '#4CAF50',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            marginTop: '20px',
            transition: 'background-color 0.3s',
          }}
          onMouseEnter={(e) => (e.target.style.backgroundColor = '#45a049')}
          onMouseLeave={(e) => (e.target.style.backgroundColor = '#4CAF50')}
        >
          Create Post
        </button>

        <p style={{ fontSize: '12px', textAlign: 'center', marginTop: '20px', color: '#666' }}>
          By uploading your file or using our service, you agree to our
          <a href="/terms" style={{ color: '#4CAF50', textDecoration: 'none' }}> Terms of Service</a> and
          <a href="/privacy" style={{ color: '#4CAF50', textDecoration: 'none' }}> Privacy Policy</a>.
        </p>
      </form>

{/* Preview Section */}
<div style={{
    width: '30%',
    padding: '20px',
    backgroundImage: `url(${ImageBackground})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    position: 'relative',
  }}>
  {/* POST PREVIEW header */}
  <div
    style={{
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      background: 'white',
      padding: '10px',
      color: 'black',
      fontWeight: 'bold',
      borderRadius: '10px 10px 0 0',
      zIndex: 2,
      textAlign: 'center', // Center the text
    }}
  >
    POST PREVIEW
  </div>

  {/* White Post Box */}
  <div
    style={{
      backgroundColor: 'white',
      borderRadius: '10px',
      padding: '20px',
      textAlign: 'center',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.15)',
      marginTop: '40px',
    }}
  >
  {postImage ? (
    postImage.type && postImage.type.startsWith('video/') ? (
      <video
        controls
        src={URL.createObjectURL(postImage)}
        style={{ width: '100%', borderRadius: '5px', marginBottom: '10px' }}
      />
    ) : (
      <img
        src={typeof postImage === 'string' ? postImage : URL.createObjectURL(postImage)}
        alt="Post Media"
        style={{ width: '100%', borderRadius: '5px', marginBottom: '10px' }}
      />
    )
  ) : (
    <div
      style={{
        width: '100%',
        height: '150px',
        background: 'linear-gradient(to bottom, #F9F9F9, #E6E6E6)',
        borderRadius: '5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '10px',
      }}
    >
      <img src={ImagePlaceholder} alt="Placeholder Icon" style={{ width: '60px', height: '60px' }} />
    </div>
  )}

  {/* Main Text */}
  <p style={{ color: 'black', padding: '10px 0', textAlign: 'left', whiteSpace: 'pre-wrap' }}>
    {postText}
  </p>
    {/* Watermark */}
    <p style={{ color: '#000', fontSize: '12px', display: 'flex', alignItems: 'center', margin: '10px 0' }}>
      This message was sent using{' '}
      <img src={LogoIcon} alt="Logo" style={{ height: '2em', marginLeft: '5px' }} />
    </p>

    {/* Unsubscribe Text */}
    <p style={{ color: 'grey', fontSize: '12px', textAlign: 'left' }}>
      to Unsubscribe respond Unsubscribe
    </p>

    {/* Button Previews */}
    {buttons.map((button, index) => (
      <div key={index}>
        <hr style={{ border: 'none', borderTop: '1px solid #e6e6e6', margin: '10px 0' }} />
        <button
          style={{
            margin: '5px 0',
            padding: '10px',
            backgroundColor: 'white',
            color: '#24D5FF',
            border: 'none',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          {button.text || ' '}
          {button.type === 'call' && <MdCall style={{ color: '#24D5FF', marginLeft: '5px' }} />}
          {button.type === 'copy' && <MdOutlineContentCopy style={{ color: '#24D5FF', marginLeft: '5px' }} />}
          {button.type === 'link' && <RxExternalLink style={{ color: '#24D5FF', marginLeft: '5px' }} />}
        </button>
      </div>
    ))}
  </div>
</div>
<ConfirmationWindow
      isOpen={isConfirmationOpen}
      onClose={handleDeleteCancel}
      onConfirm={handleDeleteConfirm}
      message={`Are you sure you want to delete the post "${postToDelete?.template_name}"?`}
    />
  </div>
  );
};

export default Post;