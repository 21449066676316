// ===================== IMPORTS START =====================
import { createSlice } from '@reduxjs/toolkit';
import {
  // Leads Related
  fetchGroups,
  importContactsSpreadsheet,
  removeDuplicates,
  getLeadsByGroupId,
  // Business Related
  fetchBusinesses,
  createBusiness,
  updateBusiness,
  deleteBusiness,
  // Post Related
  fetchPosts,
  createPost,
  updatePost,
  deletePost,
  getPostById,
  // Campaign Related
  createCampaign,
  fetchCampaigns
} from './campaignActions';
// ===================== IMPORTS END =====================

// ===================== INITIAL STATE START =====================
const initialState = {
  // Leads Related
  groups: [],
  selectedGroups: [],
  selectedGroupIds: [],
  individualLeads: [],
  selectedIndividualLeads: [],
  totalSelectedContacts: 0,
  totalWithoutDuplicates: null,
  duplicatesRemoved: 0,
  currentGroupLeads: null,

  // Business Related
  businesses: [],
  selectedBusiness: null,

  // Posts Related
  posts: [],
  selectedPost: null,
  currentEditingPost: null,
  currentPostForm: {
    title: '',
    postText: '',
    postImage: null,
    buttons: []
  },

  // Campaign Related
  campaigns: [],

  // Common States
  loading: false,
  error: null
};
// ===================== INITIAL STATE END =====================

const campaignSlice = createSlice({
  name: 'campaign',
  initialState,

  // ===================== REDUCERS START =====================
  reducers: {
    // Leads Reducers
    setSelectedGroups: (state, action) => {
      state.selectedGroups = action.payload || [];
      state.selectedGroupIds = (action.payload || []).map(group => group.id);
      const groupContacts = (action.payload || []).reduce((sum, group) => sum + (group.contacts || 0), 0);
      const individualContacts = (state.selectedIndividualLeads || []).length;
      state.totalSelectedContacts = groupContacts + individualContacts;
      state.totalWithoutDuplicates = null;
    },
    addIndividualLead: (state, action) => {
      state.individualLeads = [...(state.individualLeads || []), action.payload];
      state.selectedIndividualLeads = [...(state.selectedIndividualLeads || []), action.payload.id];
      state.totalSelectedContacts = (state.totalSelectedContacts || 0) + 1;
    },
    setSelectedIndividualLeads: (state, action) => {
      state.selectedIndividualLeads = action.payload || [];
      const groupContacts = (state.selectedGroups || []).reduce((sum, group) => sum + (group.contacts || 0), 0);
      state.totalSelectedContacts = groupContacts + (action.payload || []).length;
    },
    deleteIndividualLead: (state, action) => {
      state.individualLeads = (state.individualLeads || []).filter(lead => lead.id !== action.payload);
      state.selectedIndividualLeads = (state.selectedIndividualLeads || []).filter(id => id !== action.payload);
      state.totalSelectedContacts = Math.max(0, (state.totalSelectedContacts || 1) - 1);
    },
    resetTotalWithoutDuplicates: (state) => {
      state.totalWithoutDuplicates = null;
      state.duplicatesRemoved = 0;
    },

    // Business Reducers
    setSelectedBusiness: (state, action) => {
      state.selectedBusiness = action.payload;
    },

    // Post Reducers
    setSelectedPost: (state, action) => {
      state.selectedPost = action.payload;
    },
    updatePostForm: (state, action) => {
      state.currentPostForm = { ...state.currentPostForm, ...action.payload };
    },
    clearPostForm: (state) => {
      state.currentPostForm = initialState.currentPostForm;
    },

    // Common Reducers
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    resetState: () => initialState,
  },
  // ===================== REDUCERS END =====================

  // ===================== EXTRA REDUCERS START =====================
  extraReducers: (builder) => {
    builder
      // ===================== LEADS ACTIONS START =====================
      // Fetch Groups
      .addCase(fetchGroups.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchGroups.fulfilled, (state, action) => {
        state.loading = false;
        state.groups = action.payload;
        state.error = null;
      })
      .addCase(fetchGroups.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Import Contacts Spreadsheet
      .addCase(importContactsSpreadsheet.pending, (state) => {
        state.loading = true;
      })
      .addCase(importContactsSpreadsheet.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) {
          const newGroup = {
            id: action.payload.lead_source_id,
            name: action.payload.group_name,
            contacts: action.payload.contacts_imported,
            lastModified: action.payload.last_modified ? new Date(action.payload.last_modified) : new Date()
          };
          state.groups = [newGroup, ...state.groups];
        }
      })
      .addCase(importContactsSpreadsheet.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Remove Duplicates
      .addCase(removeDuplicates.pending, (state) => {
        state.loading = true;
      })
      .addCase(removeDuplicates.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.totalWithoutDuplicates = action.payload.deduplicated_count;
          state.duplicatesRemoved = action.payload.original_count - action.payload.deduplicated_count;
        }
      })
      .addCase(removeDuplicates.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Get Leads By Group ID
      .addCase(getLeadsByGroupId.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getLeadsByGroupId.fulfilled, (state, action) => {
        state.loading = false;
        state.currentGroupLeads = action.payload.leads;
        state.error = null;
      })
      .addCase(getLeadsByGroupId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // ===================== LEADS ACTIONS END =====================

      // ===================== BUSINESS ACTIONS START =====================
      // Fetch Businesses
      .addCase(fetchBusinesses.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchBusinesses.fulfilled, (state, action) => {
        state.loading = false;
        state.businesses = action.payload;
      })
      .addCase(fetchBusinesses.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Create Business
      .addCase(createBusiness.pending, (state) => {
        state.loading = true;
      })
      .addCase(createBusiness.fulfilled, (state, action) => {
        state.loading = false;
        state.businesses.push(action.payload);
      })
      .addCase(createBusiness.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Update Business
      .addCase(updateBusiness.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateBusiness.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.businesses.findIndex(business => business.business_id === action.payload.business_id);
        if (index !== -1) {
          state.businesses[index] = action.payload;
        }
      })
      .addCase(updateBusiness.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Delete Business
      .addCase(deleteBusiness.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteBusiness.fulfilled, (state, action) => {
        state.loading = false;
        state.businesses = state.businesses.filter(business => business.business_id !== action.payload.id);
      })
      .addCase(deleteBusiness.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // ===================== BUSINESS ACTIONS END =====================

      // ===================== POST ACTIONS START =====================
      // Fetch Posts
      .addCase(fetchPosts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPosts.fulfilled, (state, action) => {
        state.loading = false;
        state.posts = action.payload;
        state.error = null;
      })
      .addCase(fetchPosts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Create Post
      .addCase(createPost.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createPost.fulfilled, (state, action) => {
        state.loading = false;
        state.posts.unshift(action.payload);
        state.error = null;
      })
      .addCase(createPost.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Update Post
      .addCase(updatePost.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updatePost.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.posts.findIndex(post => post.post_id === action.payload.post_id);
        if (index !== -1) {
          state.posts[index] = action.payload;
        }
        state.currentEditingPost = null;
        state.error = null;
      })
      .addCase(updatePost.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Delete Post
      .addCase(deletePost.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deletePost.fulfilled, (state, action) => {
        state.loading = false;
        state.posts = state.posts.filter(post => post.post_id !== action.payload);
        if (state.selectedPost && state.selectedPost.post_id === action.payload) {
          state.selectedPost = null;
        }
        state.error = null;
      })
      .addCase(deletePost.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Get Post By ID
      .addCase(getPostById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPostById.fulfilled, (state, action) => {
        state.loading = false;
        state.currentEditingPost = action.payload;
        state.error = null;
      })
      .addCase(getPostById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // ===================== POST ACTIONS END =====================

      // ===================== CAMPAIGN ACTIONS START =====================
      // Create Campaign
      .addCase(createCampaign.pending, (state) => {
        state.loading = true;
      })
      .addCase(createCampaign.fulfilled, (state, action) => {
        state.loading = false;
        state.campaigns.push(action.payload);
      })
      .addCase(createCampaign.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Fetch Campaigns
      .addCase(fetchCampaigns.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCampaigns.fulfilled, (state, action) => {
        state.loading = false;
        state.campaigns = action.payload;
      })
      .addCase(fetchCampaigns.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
      // ===================== CAMPAIGN ACTIONS END =====================
  }
  // ===================== EXTRA REDUCERS END =====================
});

// ===================== EXPORTS START =====================
export const {
  setSelectedGroups,
  addIndividualLead,
  setSelectedIndividualLeads, 
  deleteIndividualLead,
  resetTotalWithoutDuplicates,
  setSelectedBusiness,
  setSelectedPost,
  updatePostForm,
  clearPostForm,
  setLoading,
  resetState
} = campaignSlice.actions;

export default campaignSlice.reducer;
// ===================== EXPORTS END =====================