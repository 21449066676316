/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Paper, Typography, Button } from '@mui/material';
import { css, keyframes } from '@emotion/react';
import campaignAPI from '../../../service/campaignAPI';
import { useSelector } from 'react-redux';
import { selectAuthToken } from '../../../store/selectors';

const flashAnimation = keyframes`
  0%, 100% { background-color: transparent; }
  50% { background-color: rgba(255, 0, 0, 0.2); }
`;

const expandAnimation = keyframes`
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.05); }
`;

const CurrentBalance = forwardRef(({ totalContacts, onInsufficientFunds, onBuyCredits, ...props }, ref) => {
  const [balance, setBalance] = useState(null);
  const [credits, setCredits] = useState(0);
  const [currency, setCurrency] = useState('');
  const [isSufficient, setIsSufficient] = useState(true);
  const [isFlashing, setIsFlashing] = useState(false);
  const [error, setError] = useState(null);
  const token = useSelector(selectAuthToken);

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const response = await campaignAPI.fetchUserBalance(token);
        setBalance(response.balance);
        setCurrency(response.currency);
        const availableCredits = response.credit || 0;
        setCredits(availableCredits);
        setIsSufficient(availableCredits >= totalContacts);
        setError(null);
      } catch (error) {
        console.error('Error fetching balance:', error);
        setError("We're sorry for the inconvenience, please contact support.");
        setBalance(null);
        setCredits(0);
        setIsSufficient(false);
      }
    };

    if (token) {
      fetchBalance();
    }
  }, [token, totalContacts]);

  useImperativeHandle(ref, () => ({
    checkSufficiency: () => {
      if (!isSufficient || error) {
        setIsFlashing(true);
        setTimeout(() => setIsFlashing(false), 1000);
        onInsufficientFunds?.();
      }
      return isSufficient && !error;
    }
  }));

  const containerStyles = css`
    padding: 16px;
    text-align: left;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 250px;
    ${isFlashing ? `animation: ${flashAnimation} 1s, ${expandAnimation} 1s;` : ''}
  `;

  const messageStyles = css`
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 8px;
    color: ${error ? '#9B2C2C' : (isSufficient ? '#155724' : '#9B2C2C')};
    background-color: ${!isSufficient || error ? '#FEE7E7' : 'transparent'};
    padding: 8px;
    border-radius: 4px;
    line-height: 1.5;
  `;

  const infoStyles = css`
    font-size: 14px;
    margin-bottom: 4px;
  `;

  const buttonStyles = css`
    background-color: #4CAF50;
    color: white;
    width: 100%;
    margin-top: 12px;
    &:hover {
      background-color: #45a049;
    }
  `;

  if (error) {
    return (
      <Paper elevation={1} css={containerStyles} {...props}>
        <Typography css={messageStyles}>{error}</Typography>
        <Button 
          variant="contained" 
          css={buttonStyles}
          onClick={onBuyCredits}
        >
          BUY CREDITS
        </Button>
      </Paper>
    );
  }

  return (
    <Paper elevation={1} css={containerStyles} {...props}>
      <Typography css={messageStyles}>
        {isSufficient ? 'Sufficient Credits' : 'Insufficient Credits'}
      </Typography>
      {/* <Typography css={infoStyles}>Current Balance: {balance} {currency}</Typography> */}
      <Typography css={infoStyles}>Available Credits: {credits}</Typography>
      <Typography css={infoStyles}>Required Credits: {totalContacts}</Typography>
      <Button 
        variant="contained" 
        css={buttonStyles}
        onClick={onBuyCredits}
      >
        BUY CREDITS
      </Button>
    </Paper>
  );
});

export default CurrentBalance;