import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { icons } from '../../utils/iconImports';

const Sidebar = ({ isExpanded }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const styles = {
        sidebar: {
            width: isExpanded ? '200px' : '60px',
            height: 'calc(100vh - 150px)', // Adjust height to fit within viewport
            backgroundColor: '#ffffff',
            transition: 'width 0.3s ease',
            overflow: 'hidden',
            boxShadow: '2px 0 5px rgba(0, 0, 0, 0.1)',
            borderRadius: '10px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            marginLeft: '40px',
            marginTop: '120px',
            marginBottom: '30px',
            position: 'sticky', // Changed from 'fixed' to 'sticky'
            top: '120px', // Keeps the sidebar at the top with a margin when scrolling

        },
        sidebarMenu: {
            listStyleType: 'none',
            padding: '20px 0',
        },
        bottomMenu: {
            marginTop: 'auto',
        },
        menuItem: {
            padding: '10px 10px',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            transition: 'background-color 0.3s ease',
            ':hover': {
                backgroundColor: '#f0f0f0',
            },
        },
        icon: {
            width: '45px',
            height: '45px',
            marginRight: '5px',
            transition: 'transform 0.4s ease',
        },
        menuText: {
            whiteSpace: 'nowrap',
            opacity: isExpanded ? 1 : 0,
            transition: 'opacity 0.3s ease',
        },
    };

    const menuItems = [
        { name: 'user', path: '/dashboard', label: 'Profile' },
        { name: 'campaign', path: '/campaign', label: 'Campaigns' },
        { name: 'leads', path: '/leads', label: 'Leads' },
        { name: 'post', path: '/posts', label: 'Posts' },
        { name: 'business', path: '/business', label: 'business' },
    ];

    const bottomMenuItems = [
        { name: 'settings', path: '/settings', label: 'Settings' },
        { name: 'support', path: '/support', label: 'Support' },
    ];

    const isActive = (path) => {
        if (path === '/leads') {
            return location.pathname === '/leads' || location.pathname === '/add-group';
        }
        return location.pathname === path;
    };

    const renderMenuItem = (item) => {
        const active = isActive(item.path);

        return (
            <li 
                key={item.name}
                className="menu-item"
                onClick={() => navigate(item.path)}
            >
                <img 
                    src={active ? icons[item.name].selected : icons[item.name].default} 
                    alt={item.label} 
                    className={`icon ${active ? 'active' : ''}`}
                />
                <span style={styles.menuText}>{item.label}</span>
            </li>
        );
    };

    return (
        <nav style={styles.sidebar}>
            <ul style={styles.sidebarMenu}>
                {menuItems.map(renderMenuItem)}
            </ul>
            <ul style={{...styles.sidebarMenu, ...styles.bottomMenu}}>
                {bottomMenuItems.map(renderMenuItem)}
            </ul>
            <style jsx>{`
                .menu-item {
                    padding: 10px 10px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    transition: background-color 0.3s ease;
                }
                .menu-item:hover {
                    background-color: #f0f0f0;
                }
                .icon {
                    width: 45px;
                    height: 45px;
                    margin-right: 5px;
                    transition: transform 0.4s ease;
                }
                .menu-item:hover .icon {
                    transform: scale(1.1);
                }
                .icon.active {
                    transform: scale(1.2);
                }
            `}</style>
        </nav>
    );
};

export default Sidebar;