const environment = process.env.REACT_APP_ENVIRONMENT || 'production';

const config = {
  development: {
    apiUrl: 'http://127.0.0.1:8000/api/v1',
    env: 'development'
  },
  production: {
    apiUrl: 'https://ho8e4sil9c.execute-api.us-east-1.amazonaws.com/api/v1',
    env: 'production'
  },
};

if (!config[environment]) {
  console.warn(`Invalid environment: ${environment}. Falling back to production.`);
}

export default config[environment] || config.production;