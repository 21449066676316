import { createSelector } from '@reduxjs/toolkit';

// Base selectors
const selectCampaign = state => state.campaign;
const selectAuth = state => state.auth;

// Auth selectors
export const selectIsAuthenticated = createSelector(
  [selectAuth],
  auth => auth.isAuthenticated
);

export const selectAuthToken = createSelector(
  [selectAuth],
  auth => auth.token
);

export const selectAuthLoading = createSelector(
  [selectAuth],
  auth => auth.loading
);

export const selectAuthError = createSelector(
  [selectAuth],
  auth => auth.error
);

//-------CAMPAIGN SELECTORS--------
export const selectCampaigns = createSelector(
  [selectCampaign],
  campaign => campaign.campaigns
);

//-------LEADS SELECTORS--------
export const selectGroups = createSelector(
  [selectCampaign],
  campaign => campaign.groups || []
);

export const selectLeads = createSelector(
  [selectCampaign],
  campaign => campaign.leads || []
);

export const selectSelectedGroups = createSelector(
  [selectCampaign],
  campaign => campaign.selectedGroups || []
);

export const selectSelectedGroupIds = createSelector(
  [selectCampaign],
  campaign => campaign.selectedGroupIds || []
);

export const selectIndividualLeads = createSelector(
  [selectCampaign],
  campaign => campaign.individualLeads || []
);

export const selectSelectedIndividualLeads = createSelector(
  [selectCampaign],
  campaign => campaign.selectedIndividualLeads || []
);

export const selectIsAnyGroupSelected = createSelector(
  [selectSelectedGroups, selectSelectedIndividualLeads],
  (selectedGroups, selectedIndividualLeads) => {
    return (selectedGroups?.length > 0) || (selectedIndividualLeads?.length > 0);
  }
);

export const selectTotalLeads = createSelector(
  [selectSelectedGroups],
  selectedGroups => {
    if (!Array.isArray(selectedGroups)) return 0;
    return selectedGroups.reduce((sum, group) => sum + (group.contacts || 0), 0);
  }
);

export const selectTotalSelectedContacts = createSelector(
  [selectCampaign],
  campaign => campaign.totalSelectedContacts || 0
);

export const selectTotalWithoutDuplicates = createSelector(
  [selectCampaign],
  campaign => campaign.totalWithoutDuplicates
);

//-------BUSINESS SELECTORS--------
export const selectBusinesses = createSelector(
  [selectCampaign],
  campaign => campaign.businesses || []
);

export const selectSelectedBusiness = createSelector(
  [selectCampaign],
  campaign => campaign.selectedBusiness
);

//-------POST SELECTORS--------
export const selectPosts = createSelector(
  [selectCampaign],
  campaign => (campaign.posts || []).filter(post => post !== null)
);

export const selectSelectedPost = createSelector(
  [selectCampaign],
  campaign => campaign.selectedPost
);

// Common selectors
export const selectIsLoading = createSelector(
  [selectCampaign, selectAuth],
  (campaign, auth) => campaign.loading || auth.loading
);

export const selectError = createSelector(
  [selectCampaign, selectAuth],
  (campaign, auth) => campaign.error || auth.error
);

export default {
  selectIsAuthenticated,
  selectAuthToken,
  selectAuthLoading,
  selectAuthError,
  selectCampaigns,
  selectGroups,
  selectLeads,
  selectSelectedGroups,
  selectSelectedGroupIds,
  selectIndividualLeads,
  selectSelectedIndividualLeads,
  selectIsAnyGroupSelected,
  selectTotalLeads,
  selectTotalSelectedContacts,
  selectTotalWithoutDuplicates,
  selectBusinesses,
  selectSelectedBusiness,
  selectPosts,
  selectSelectedPost,
  selectIsLoading,
  selectError
};