import React, { useState, useEffect } from 'react';
import { RiQuestionMark } from 'react-icons/ri';
import { useLocation } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

// Keyframe Animations
const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const pulseAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const shineAnimation = keyframes`
  0% { transform: translateX(-100%) rotate(-45deg); opacity: 0; }
  20% { opacity: 0.8; }
  35% { opacity: 0.9; }
  50% { opacity: 1; }
  65% { opacity: 0.9; }
  80% { opacity: 0.8; }
  100% { transform: translateX(100%) rotate(-45deg); opacity: 0; }
`;

// Styled Components
const HelpContainer = styled.div`
  position: relative;
  width: 35px;
  height: 35px;
  cursor: pointer;
  
  &:hover {
    .help-tooltip {
      opacity: 1;
      transform: translateY(0);
      visibility: visible;
    }
  }
`;

const HelpCircle = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  background: linear-gradient(45deg, #4CAF50, #66BB6A, #81C784);
  background-size: 200% 200%;
  animation: ${gradientAnimation} 4s ease infinite;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.1);
    animation: ${pulseAnimation} 2s ease-in-out infinite;
    box-shadow: 0 0 15px rgba(76, 175, 80, 0.3);
  }

  /* Shine effect overlay */
  &::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: linear-gradient(
      45deg,
      transparent,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.1),
      transparent
    );
    transform: translateX(-100%) rotate(-45deg);
  }

  &.shining::before {
    animation: ${shineAnimation} 1.5s ease-in-out;
  }
`;

const QuestionMark = styled(RiQuestionMark)`
  color: white;
  font-size: 20px;
  z-index: 2;
  filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.2));
`;

const Tooltip = styled.div`
  position: absolute;
  bottom: 120%;
  left: 50%;
  transform: translateX(-50%) translateY(10px);
  padding: 8px 12px;
  background: #4a4a4a;
  color: white;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: 1000;

  &::after {
    display: none;
  }
`;

const HelpIndicator = () => {
  const [isShining, setIsShining] = useState(false);
  const location = useLocation();
  
  // Map routes to tutorial sections and tooltips
  const routeMap = {
    '/campaign/steps/leads': {
      section: '#leads',
      tooltip: 'Learn about adding leads'
    },
    '/campaign/steps/business': {
      section: '#business',
      tooltip: 'Learn about business setup'
    },
    '/campaign/steps/post': {
      section: '#post',
      tooltip: 'Learn about creating posts'
    },
    '/campaign/steps/deploy': {
      section: '#deploy',
      tooltip: 'Learn about deployment'
    }
  };

  // Get current section info
  const getCurrentSection = () => {
    const path = Object.keys(routeMap).find(route => 
      location.pathname.includes(route)
    );
    return routeMap[path] || { section: '', tooltip: 'Help Guide' };
  };

  // Automatic shine effect
  useEffect(() => {
    const shineInterval = setInterval(() => {
      setIsShining(true);
      setTimeout(() => setIsShining(false), 1500);
    }, 10000);

    return () => clearInterval(shineInterval);
  }, []);

  const handleClick = () => {
    const { section } = getCurrentSection();
    // Using your domain
    window.open(`https://www.sen-due.com/how${section}`, '_blank');
  };

  const handleMouseEnter = () => {
    setIsShining(true);
  };

  const handleMouseLeave = () => {
    setIsShining(false);
  };

  return (
    <HelpContainer>
      <HelpCircle
        className={isShining ? 'shining' : ''}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <QuestionMark />
      </HelpCircle>
      <Tooltip className="help-tooltip">
        {getCurrentSection().tooltip}
      </Tooltip>
    </HelpContainer>
  );
};

export default HelpIndicator;