import React, { useRef, useEffect } from 'react';

const ConfirmationWindow = ({ isOpen, onClose, onConfirm, message, targetId }) => {
  const windowRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (windowRef.current && !windowRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  useEffect(() => {
    if (isOpen && targetId) {
      const targetElement = document.getElementById(`business-${targetId}`);
      if (targetElement && windowRef.current) {
        const rect = targetElement.getBoundingClientRect();
        windowRef.current.style.top = `${rect.top}px`;
        windowRef.current.style.left = `${rect.right + 10}px`; // 10px offset from the right edge
      }
    }
  }, [isOpen, targetId]);

  if (!isOpen) return null;

  return (
    <div ref={windowRef} style={{
      position: 'absolute',
      backgroundColor: 'white',
      padding: '10px',
      borderRadius: '8px',
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
      zIndex: 1000,
      width: '250px',
      border: '1px solid #e0e0e0',
    }}>
      <p style={{ marginBottom: '10px', textAlign: 'center', fontSize: '14px' }}>{message}</p>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <button
          onClick={onClose}
          style={{
            padding: '6px 12px',
            border: '1px solid #4CAF50',
            borderRadius: '4px',
            backgroundColor: 'white',
            color: '#4CAF50',
            cursor: 'pointer',
            transition: 'background-color 0.3s',
            fontSize: '12px',
          }}
          onMouseEnter={(e) => e.target.style.backgroundColor = '#f0f0f0'}
          onMouseLeave={(e) => e.target.style.backgroundColor = 'white'}
        >
          Cancel
        </button>
        <button
          onClick={onConfirm}
          style={{
            padding: '6px 12px',
            border: 'none',
            borderRadius: '4px',
            backgroundColor: '#4CAF50',
            color: 'white',
            cursor: 'pointer',
            transition: 'background-color 0.3s',
            fontSize: '12px',
          }}
          onMouseEnter={(e) => e.target.style.backgroundColor = '#45a049'}
          onMouseLeave={(e) => e.target.style.backgroundColor = '#4CAF50'}
        >
          Confirm
        </button>
      </div>
    </div>
  );
};

export default ConfirmationWindow;