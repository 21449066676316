/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { css } from '@emotion/react';
import CustomTable from '../../../components/Single/CustomTable';
import SelectCircle from '../../../components/Single/SelectCircle';
import DeleteButton from '../../../components/Single/DeleteButton';
import { green, grey } from '@mui/material/colors';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedIndividualLeads, addIndividualLead, deleteIndividualLead } from '../../../store/campaignSlice';
import 'react-phone-input-2/lib/style.css';

const containerStyles = css`
  margin-top: 20px;
`;

const titleStyles = css`
  text-align: left;
  margin-top: 20px;
  margin-bottom: 5px;
  font-size: 1em;
  font-weight: bold;
`;

const inputBoxStyles = css`
  background: white;
  border: 1px solid #E5E7EB;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 12px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
`;

const nameInputStyles = css`
  width: 100%;
  height: 40px;
  padding: 8px 12px;
  border: 1px solid #E5E7EB;
  border-radius: 4px;
  margin-bottom: 12px;
  font-size: 14px;
  &:focus {
    outline: none;
    border-color: #4CAF50;
    box-shadow: 0 0 0 1px #4CAF50;
  }
`;

const buttonContainerStyles = css`
  display: flex;
  gap: 12px;
  margin-top: 12px;
`;

const addButtonStyles = (isValid) => css`
  flex: 3;
  padding: 10px;
  height: 40px;
  font-size: 14px;
  background-color: ${isValid ? green[500] : grey[300]};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: ${isValid ? 'pointer' : 'not-allowed'};
  transition: all 0.3s ease;
  
  &:hover {
    background-color: ${isValid ? green[700] : grey[300]};
  }
`;

const clearButtonStyles = css`
  flex: 1;
  height: 40px;
  font-size: 14px;
  background-color: white;
  color: ${grey[500]};
  border: 1px solid ${grey[300]};
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;
  
  &:hover {
    background-color: ${green[50]};
    color: ${green[700]};
    border-color: ${green[700]};
  }
`;

const tableStyles = css`
  margin-top: 20px;
  border: 1px solid #E5E7EB;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
`;

const IndividualLeadsInput = () => {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneInputRef, setPhoneInputRef] = useState(null);
  
  const individualLeads = useSelector(state => state.campaign.individualLeads) || [];
  const selectedLeads = useSelector(state => state.campaign.selectedIndividualLeads) || [];

  const isValid = () => name.trim() && phone.length >= 10;

  const handleAddLead = async () => {
    if (isValid()) {
      const newLead = {
        id: Date.now(), // Temporary ID until server response
        name: name.trim(),
        phone,
      };
      dispatch(addIndividualLead(newLead));
      clearForm();
    }
  };

  const clearForm = () => {
    setName('');
    setPhone('');
    if (phoneInputRef) {
      phoneInputRef.setState({ 
        country: 'us',
        formattedNumber: '',
        value: ''
      });
      const dropdownButton = phoneInputRef.numberInputRef.parentElement.querySelector('.selected-flag');
      if (dropdownButton) {
        dropdownButton.querySelector('.flag').className = 'flag us';
      }
    }
  };

  const handleDeleteLead = (id) => {
    dispatch(deleteIndividualLead(id));
  };

  const toggleLeadSelection = (id) => {
    const newSelectedLeads = selectedLeads.includes(id)
      ? selectedLeads.filter(leadId => leadId !== id)
      : [...selectedLeads, id];
    dispatch(setSelectedIndividualLeads(newSelectedLeads));
  };

  const renderLeadsTable = () => {
    if (!individualLeads || individualLeads.length === 0) return null;
  
    const headers = ['Select', 'Name', 'Phone', ''];
  
    const data = individualLeads.map(lead => [
      <SelectCircle
        key={`select-${lead.id}`}
        isSelected={selectedLeads.includes(lead.id)}
        onClick={() => toggleLeadSelection(lead.id)}
      />,
      <span key={`name-${lead.id}`} css={css`color: #111827; font-size: 14px;`}>{lead.name}</span>,
      <span key={`phone-${lead.id}`} css={css`color: #6B7280; font-size: 14px;`}>{lead.phone}</span>,
      <div key={`delete-${lead.id}`} css={css`display: flex; justify-content: flex-end;`}>
        <DeleteButton onClick={() => handleDeleteLead(lead.id)} />
      </div>
    ]);
  
    return (
      <div css={tableStyles}>
        <CustomTable
          headers={headers}
          data={data}
          emptyMessage="No leads added yet"
        />
      </div>
    );
  };
  
  return (
    <div css={containerStyles}>
      <div css={titleStyles}>Add Individual Leads</div>
      <div css={inputBoxStyles}>
        <input
          css={nameInputStyles}
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <PhoneInput
          ref={(ref) => setPhoneInputRef(ref)}
          country={'us'}
          value={phone}
          onChange={(value) => setPhone(value)}
          inputStyle={{
            width: '100%',
            height: '40px',
            fontSize: '14px',
            borderColor: '#E5E7EB'
          }}
          containerStyle={{
            marginBottom: 0
          }}
        />
        <div css={buttonContainerStyles}>
          <button
            css={addButtonStyles(isValid())}
            onClick={handleAddLead}
            disabled={!isValid()}
          >
            Add Lead
          </button>
          <button
            css={clearButtonStyles}
            onClick={clearForm}
            type="button"
          >
            Clear
          </button>
        </div>
      </div>
      {renderLeadsTable()}
    </div>
  );
};

export default IndividualLeadsInput;