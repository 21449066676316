/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import AnimatedFluidStep from './AnimatedFluidStep';
import PopupContent from './PopupContent';

const IconContainer = styled.div`
  position: fixed;
  bottom: 20px;
  left: 20px;
  cursor: pointer;
  z-index: 1000;
`;

const PopupWindow = styled.div`
  position: fixed;
  left: 20px;
  bottom: 90px;
  z-index: 1000;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  width: 200px;
  max-height: 300px;
  overflow-y: auto;
  transition: all 0.3s ease-in-out;
  display: ${props => props.isVisible ? 'block' : 'none'};
`;

const CurrentCampaignWindow = ({ currentStep }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [previousStep, setPreviousStep] = useState(currentStep - 1);

  useEffect(() => {
    setPreviousStep(Math.max(0, currentStep - 1));
  }, [currentStep]);

  const fillPercentage = (currentStep / 4) * 100;

  return (
    <>
      <IconContainer
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <AnimatedFluidStep 
          fillPercentage={fillPercentage}
          previousStep={previousStep}
        />
      </IconContainer>
      <PopupWindow
        isVisible={isHovered}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <PopupContent currentStep={currentStep} />
      </PopupWindow>
    </>
  );
};

export default CurrentCampaignWindow;