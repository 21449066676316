import React, { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Sidebar from '../Sidebar/Sidebar';
import Footer from '../Footer/Footer';
import { logo } from '../../utils/iconImports';

// Import the baseStyle and globalStyles
import { baseStyle, globalStyles } from './sharedStyles';

const Layout = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    const navigate = useNavigate();

    const styles = {      
        layout: {
            ...baseStyle, // Spread the baseStyle here
            display: 'flex',
            minHeight: '100vh',
            width: '100vw',
            backgroundImage: `url("data:image/svg+xml,%3Csvg id='patternId' width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3Cpattern id='a' patternUnits='userSpaceOnUse' width='70' height='70' patternTransform='scale(2) rotate(0)'%3E%3Crect x='0' y='0' width='100%25' height='100%25' fill='hsla(86, 0%25, 100%25, 1)'/%3E%3Cpath d='M-4.8 4.44L4 16.59 16.14 7.8M32 30.54l-13.23 7.07 7.06 13.23M-9 38.04l-3.81 14.5 14.5 3.81M65.22 4.44L74 16.59 86.15 7.8M61 38.04l-3.81 14.5 14.5 3.81' stroke-linecap='square' stroke-width='1' stroke='hsla(86, 96%25, 90%25, 1)' fill='none'/%3E%3Cpath d='M59.71 62.88v3h3M4.84 25.54L2.87 27.8l2.26 1.97m7.65 16.4l-2.21-2.03-2.03 2.21m29.26 7.13l.56 2.95 2.95-.55' stroke-linecap='square' stroke-width='1' stroke='hsla(86, 26%25, 87%25, 1)' fill='none'/%3E%3Cpath d='M58.98 27.57l-2.35-10.74-10.75 2.36M31.98-4.87l2.74 10.65 10.65-2.73M31.98 65.13l2.74 10.66 10.65-2.74' stroke-linecap='square' stroke-width='1' stroke='hsla(86, 100%25, 84%25, 1)' fill='none'/%3E%3Cpath d='M8.42 62.57l6.4 2.82 2.82-6.41m33.13-15.24l-4.86-5.03-5.03 4.86m-14-19.64l4.84-5.06-5.06-4.84' stroke-linecap='square' stroke-width='1' stroke='hsla(86, 0%25, 95%25, 1)' fill='none'/%3E%3C/pattern%3E%3C/defs%3E%3Crect width='800%25' height='800%25' transform='translate(0,0)' fill='url(%23a)'/%3E%3C/svg%3E")`,
            backgroundRepeat: 'repeat',
            backgroundSize: 'auto',
            position: 'relative',
            zIndex: 0,
        },
        logo: {
            position: 'absolute',
            top: '20px',
            left: '20px',
            width: '150px',
            zIndex: 10,
            cursor: 'pointer', // Add cursor pointer to indicate clickable
        },
        contentWrapper: {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            marginLeft: '70px',
            transition: 'margin-left 0.3s ease',
            paddingTop: '80px',
        },
        mainContent: {
            flexGrow: 1,
            padding: '20px',
        },
        footer: {
            marginTop: 'auto',
            width: '100%',
        },
    };

    return (
        <>
            <style>{globalStyles}</style>
            <div style={styles.layout}>
                {/* Wrap the logo image with an anchor tag */}
                <a href="https://www.sen-due.com/" target="_blank" rel="noopener noreferrer">
                    <img src={logo} alt="Sendue Logo" style={styles.logo} />
                </a>
                <div 
                    onMouseEnter={() => setIsExpanded(true)} 
                    onMouseLeave={() => setIsExpanded(false)}
                >
                    <Sidebar isExpanded={isExpanded} />
                </div>
                <div style={styles.contentWrapper}>
                    <main style={styles.mainContent}>
                        <Outlet />
                    </main>
                    <Footer />
                </div>
            </div>
        </>
    );
};

export default Layout;
