// loggerMiddleware.js

import config from '../../config';

/**
 * Logger Middleware
 * 
 * This middleware logs Redux actions and state changes in development mode.
 * It's automatically disabled in production for better performance.
 */
const loggerMiddleware = store => next => action => {
  // Only log in development mode
  if (config.env === 'development') {
    try {
      // Group console logs for this action
      console.group(action.type);

      // Log the action details
      console.info('Dispatching:', action);

      // Call the next middleware or reducer
      const result = next(action);

      // Log the new state after the action has been processed
      console.log('Next State:', store.getState());

      // End the console group
      console.groupEnd();

      return result;
    } catch (error) {
      // Log any errors that occur during logging
      console.error('Error in logger middleware:', error);

      // Ensure the console group is closed even if an error occurs
      console.groupEnd();

      // Pass the action through even if logging fails
      return next(action);
    }
  } else {
    // In production, simply pass the action through without logging
    return next(action);
  }
};

export default loggerMiddleware;