/** @jsxImportSource @emotion/react */
import React from 'react';
import { Button } from '@mui/material';
import { css } from '@emotion/react';
import { grey, green } from '@mui/material/colors';

const clearButtonStyles = css`
  flex: 1;
  height: 56px;
  font-size: 16px;
  background-color: white;
  color: ${grey[500]};
  border: 1px solid ${grey[300]};
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    background-color: ${green[50]};
    color: ${green[700]};
    border-color: ${green[700]};
  }
`;

const ClearButton = ({ onClick }) => (
  <Button onClick={onClick} css={clearButtonStyles}>
    Clear
  </Button>
);

export default ClearButton;
