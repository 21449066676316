/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, keyframes } from '@emotion/react';
import { MdRocketLaunch } from 'react-icons/md';

const shake = keyframes`
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
`;

const fly = keyframes`
  0% { transform: translate(0, 0) rotate(0deg); }
  100% { transform: translate(200px, -200px) rotate(45deg); }
`;

const rocketStyles = css`
  margin-left: 10px;
  position: relative;
`;

const AnimatedRocket = ({ isAnimating }) => (
  <span css={[
    rocketStyles,
    isAnimating && css`
      animation: ${shake} 0.5s ease-in-out, ${fly} 1s ease-in-out 0.5s;
    `
  ]}>
    <MdRocketLaunch />
  </span>
);

export default AnimatedRocket;