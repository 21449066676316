import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthToken } from '../../../store/selectors';
import { logout } from '../../../store/authSlice';
import { 
  fetchBusinesses, 
  createBusiness, 
  deleteBusiness,
  updateBusiness
} from '../../../store/campaignActions';
import { setSelectedBusiness } from '../../../store/campaignSlice';
import { 
  selectBusinesses, 
  selectSelectedBusiness,
  selectIsLoading,
  selectError,
  selectSelectedGroups
} from '../../../store/selectors';
import campaignAPI from '../../../service/campaignAPI';
import imageCompression from 'browser-image-compression';

export const useStepsBusinessLogic = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector(selectAuthToken);
  
  const businesses = useSelector(selectBusinesses);
  const selectedBusiness = useSelector(selectSelectedBusiness);
  const selectedGroups = useSelector(selectSelectedGroups);
  const isLoading = useSelector(selectIsLoading);
  const error = useSelector(selectError);
  
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [formData, setFormData] = useState({
    businessName: '',
    about: '',
    location: '',
    email: '',
    website: '',
    facebookLink: '',
    instagramLink: '',
    profilePicture: null,
  });
  const [importedImage, setImportedImage] = useState(null);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [businessToDelete, setBusinessToDelete] = useState(null);
  const [confirmationTarget, setConfirmationTarget] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (token) {
      dispatch(fetchBusinesses())
        .unwrap()
        .catch(err => {
          if (err.status === 401) {
            dispatch(logout());
            navigate('/login');
          }
        });
    }
  }, [dispatch, token, navigate]);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  }, []);

  const handleImageImport = useCallback(async (file) => {
    setImportedImage(file);
  }, []);

  const resetForm = useCallback(() => {
    setFormData({
      businessName: '',
      about: '',
      location: '',
      email: '',
      website: '',
      facebookLink: '',
      instagramLink: '',
      profilePicture: null,
    });
    setImportedImage(null);
    setIsEditing(false);
  }, []);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setIsSubmitting(true);

    if (!formData.businessName.trim()) {
      setErrorMessage('Business Name is required.');
      setIsSubmitting(false);
      return;
    }

    try {
      let imageUrl = '';
      if (importedImage) {
        try {
          const compressedImage = await imageCompression(importedImage, {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920
          });
          
          const imageFormData = new FormData();
          imageFormData.append('file', compressedImage);
          const imageResponse = await campaignAPI.fetchUploadImage(token, imageFormData);
          imageUrl = imageResponse.url;
          console.log('Image uploaded successfully:', imageUrl);
        } catch (imageError) {
          console.error('Error uploading image:', imageError);
          setErrorMessage('Failed to upload image. Please try again.');
          setIsSubmitting(false);
          return;
        }
      }

      const businessData = {
        business_name: formData.businessName,
        profile_picture: imageUrl || formData.profilePicture,
        business_descriptions: formData.about,
        business_location: formData.location,
        website: formData.website,
        facebook_link: formData.facebookLink,
        instagram_link: formData.instagramLink,
        business_email: formData.email,
      };

      console.log('Submitting business data:', businessData);

      if (isEditing) {
        console.log('Updating business...');
        const result = await dispatch(updateBusiness({ token, id: selectedBusiness.business_id, businessData })).unwrap();
        console.log('Update business result:', result);
        setSuccessMessage('Business profile updated successfully!');
      } else {
        console.log('Creating new business...');
        const result = await dispatch(createBusiness({ token, businessData })).unwrap();
        console.log('Create business result:', result);
        setSuccessMessage('Business profile created successfully!');
      }

      await dispatch(fetchBusinesses(token));
      resetForm();
    } catch (error) {
      console.error('Error in handleSubmit:', error);
      if (error.status === 401) {
        dispatch(logout());
        navigate('/login');
      } else {
        setErrorMessage(`Failed to ${isEditing ? 'update' : 'create'} business profile: ${error.message || 'Unknown error'}`);
      }
    } finally {
      setIsSubmitting(false);
      setIsEditing(false);
    }
  }, [dispatch, formData, importedImage, isEditing, navigate, selectedBusiness, token]);

  const handleBusinessSelect = useCallback((businessId) => {
    const selected = businesses.find(b => b.business_id === businessId);
    if (selected) {
      dispatch(setSelectedBusiness(selected));
    }
  }, [businesses, dispatch]);

  const handleEdit = useCallback((business) => {
    setFormData({
      businessName: business.business_name,
      about: business.business_descriptions,
      location: business.business_location,
      email: business.business_email,
      website: business.website,
      facebookLink: business.facebook_link,
      instagramLink: business.instagram_link,
      profilePicture: business.profile_picture,
    });
    setIsEditing(true);
    dispatch(setSelectedBusiness(business));
  }, [dispatch]);

  const handleDeleteClick = useCallback((id) => {
    setBusinessToDelete(id);
    setConfirmationTarget(`business-${id}`);
    setIsConfirmationOpen(true);
  }, []);

  const handleDeleteConfirm = useCallback(async () => {
    console.log('Deleting business with ID:', businessToDelete);
    try {
      const result = await dispatch(deleteBusiness({ token, id: businessToDelete })).unwrap();
      console.log('Delete business result:', result);
      await dispatch(fetchBusinesses(token));
      setSuccessMessage('Business deleted successfully');
    } catch (err) {
      console.error('Error deleting business:', err);
      if (err.status === 401) {
        dispatch(logout());
        navigate('/login');
      } else {
        setErrorMessage(err.message || 'Failed to delete business');
      }
    } finally {
      setIsConfirmationOpen(false);
      setConfirmationTarget(null);
    }
  }, [businessToDelete, dispatch, navigate, token]);
  
  const handleNext = useCallback(() => {
    if (!selectedBusiness) {
      setErrorMessage('Please select a business before proceeding.');
      return;
    }
    navigate('/campaign/steps/post');
  }, [navigate, selectedBusiness]);

  const handleClearForm = useCallback(() => {
    resetForm();
  }, [resetForm]);

  return {
    businesses,
    selectedBusiness,
    selectedGroups,
    successMessage,
    errorMessage,
    formData,
    importedImage,
    isConfirmationOpen,
    confirmationTarget,
    isSubmitting,
    isEditing,
    isLoading,
    error,
    handleInputChange,
    handleImageImport,
    handleSubmit,
    handleBusinessSelect,
    handleEdit,
    handleDeleteClick,
    handleDeleteConfirm,
    handleNext,
    handleClearForm,
    setIsConfirmationOpen,
    setConfirmationTarget,
  };
};