import React, { useState, useEffect } from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 0.3;
  }
`;

const slideIn = keyframes`
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: ${fadeIn} 0.2s ease-out;
  padding: 20px;
  backdrop-filter: blur(2px);
`;

const ModalContent = styled.div`
  background: white;
  border-radius: 12px;
  width: 90%;
  max-width: ${props => props.maxWidth || '800px'};
  max-height: 90vh;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  animation: ${slideIn} 0.3s ease-out;
`;

const ModalHeader = styled.div`
  padding: 20px 24px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background: linear-gradient(to bottom, #ffffff, #fafafa);
`;

const HeaderContent = styled.div`
  flex: 1;
  min-width: 0; // Prevents text overflow issues
`;

const Title = styled.h2`
  margin: 0;
  color: #111827;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.2;
`;

const Subtitle = styled.p`
  margin: 8px 0 0;
  color: #6b7280;
  font-size: 0.875rem;
  line-height: 1.25rem;
`;

const CloseButton = styled.button`
  background: transparent;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  margin: -8px -8px 0 0;
  transition: all 0.2s ease;
  flex-shrink: 0;

  &:hover {
    background-color: #f3f4f6;
  }

  &:active {
    background-color: #e5e7eb;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px #e5e7eb;
  }
`;

const CloseIcon = styled(IoCloseOutline)`
  transition: transform 0.2s ease;
  transform: ${props => props.isHovered ? 'rotate(90deg)' : 'rotate(0deg)'};
  pointer-events: none;
  color: #6b7280;
`;

const ModalBody = styled.div`
  padding: ${props => props.noPadding ? '0' : '24px'};
  overflow-y: auto;
  min-height: ${props => props.minHeight || 'auto'};
  max-height: calc(90vh - 150px); // Adjust based on header height
  scrollbar-width: thin;
  scrollbar-color: #CBD5E1 #F1F5F9;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #F1F5F9;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #CBD5E1;
    border-radius: 4px;
    border: 2px solid #F1F5F9;

    &:hover {
      background-color: #94A3B8;
    }
  }
`;

const ModalFooter = styled.div`
  padding: 16px 24px;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: ${props => props.justify || 'flex-end'};
  gap: 12px;
  background: linear-gradient(to top, #ffffff, #fafafa);
`;

const Popup = ({ 
  isOpen, 
  onClose, 
  title, 
  subtitle, 
  children,
  maxWidth,
  minHeight,
  noPadding = false,
  footer,
  footerJustify,
  closeOnOverlayClick = true,
  onOpen,
  preventCloseKeys = []
}) => {
  const [isCloseHovered, setIsCloseHovered] = useState(false);

  useEffect(() => {
    if (isOpen && onOpen) {
      onOpen();
    }

    const handleEscape = (e) => {
      if (
        isOpen && 
        e.key === 'Escape' && 
        !preventCloseKeys.includes('Escape')
      ) {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEscape);
    
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
      document.body.style.overflow = 'unset';
    };
  }, [isOpen, onOpen, onClose, preventCloseKeys]);

  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    if (closeOnOverlayClick && e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <ModalOverlay onClick={handleOverlayClick}>
      <ModalContent 
        onClick={e => e.stopPropagation()} 
        maxWidth={maxWidth}
        role="dialog"
        aria-modal="true"
        aria-labelledby="popup-title"
      >
        <ModalHeader>
          <HeaderContent>
            <Title id="popup-title">{title}</Title>
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
          </HeaderContent>
          <CloseButton
            onClick={onClose}
            onMouseEnter={() => setIsCloseHovered(true)}
            onMouseLeave={() => setIsCloseHovered(false)}
            aria-label="Close"
          >
            <CloseIcon 
              size={24}
              isHovered={isCloseHovered}
            />
          </CloseButton>
        </ModalHeader>

        <ModalBody noPadding={noPadding} minHeight={minHeight}>
          {children}
        </ModalBody>

        {footer && (
          <ModalFooter justify={footerJustify}>
            {footer}
          </ModalFooter>
        )}
      </ModalContent>
    </ModalOverlay>
  );
};

export default Popup;