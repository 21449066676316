import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectAuthToken } from '../../../store/selectors';
import { logout } from '../../../store/authSlice';
import ImportWindow from '../../../components//Single/ImportWindow';
import TrashIcon from '../../../components/icons/trash.png';
import TrashIconHover from '../../../components/icons/trash_select.png';
import campaignAPI from '../../../service/campaignAPI';

const AddGroup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector(selectAuthToken);
  const [spreadsheetFiles, setSpreadsheetFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSpreadsheetImport = (file) => {
    setSpreadsheetFiles((prevFiles) => [...prevFiles, file]);
    console.log('Imported Spreadsheet:', file);
  };

  const handleRemoveSpreadsheet = (index) => {
    setSpreadsheetFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
  
    try {
      for (const file of spreadsheetFiles) {
        const formData = new FormData();
        formData.append('file', file);
        
        console.log('Uploading file:', file.name);
        
        const response = await campaignAPI.importContactsSpreadsheet(token, formData);
        console.log('Upload response:', response);
      }
  
      alert('All spreadsheets uploaded successfully!');
      setSpreadsheetFiles([]);
      navigate('/leads');
    } catch (err) {
      console.error('Error uploading spreadsheets:', err);
      if (err.response && err.response.status === 401) {
        dispatch(logout());
        navigate('/login');
      } else {
        setError(`Failed to upload: ${err.response?.data?.detail || err.message}`);
      }
    } finally {
      setIsLoading(false);
    }
  };
  
  const styles = {
    container: {
      padding: '20px',
      maxWidth: '600px',
      margin: '0 auto',
    },
    title: {
      textAlign: 'center',
      marginBottom: '30px',
    },
    fileList: {
      marginTop: '20px',
    },
    fileListHeader: {
      marginBottom: '10px',
    },
    fileListContainer: {
      border: '1px solid #ccc',
      borderRadius: '5px',
      padding: '10px',
      backgroundColor: '#f9f9f9',
    },
    fileItem: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px',
      borderBottom: '1px solid #ddd',
    },
    removeButton: {
      backgroundColor: 'black',
      border: 'none',
      borderRadius: '5px',
      padding: '5px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: 'background-color 0.3s, transform 0.3s',
      width: '30px',
      height: '30px',
      backgroundSize: '16px 18px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
    submitButton: {
      width: '100%',
      padding: '15px',
      fontSize: '18px',
      backgroundColor: '#4CAF50',
      color: 'white',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      marginTop: '20px',
    },
    termsText: {
      fontSize: '12px',
      textAlign: 'center',
      marginTop: '20px',
      color: '#666',
    },
    termsLink: {
      color: '#4CAF50',
      textDecoration: 'none',
    },
    errorMessage: {
      color: 'red',
      textAlign: 'center',
      marginTop: '10px',
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Add Spreadsheets</h1>

      <ImportWindow
        type_of_import="spreadsheet"
        onImport={handleSpreadsheetImport}
        addSubmit={false}
      />

      <div style={styles.fileList}>
        <h3 style={styles.fileListHeader}>Added Spreadsheets:</h3>
        {spreadsheetFiles.length === 0 ? (
          <p>No spreadsheets added yet.</p>
        ) : (
          <div style={styles.fileListContainer}>
            {spreadsheetFiles.map((file, index) => (
              <div key={index} style={styles.fileItem}>
                <span>{file.name}</span>
                <button
                  onClick={() => handleRemoveSpreadsheet(index)}
                  style={{
                    ...styles.removeButton,
                    backgroundImage: `url(${TrashIcon})`,
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.backgroundColor = '#333';
                    e.currentTarget.style.transform = 'scale(1.1)';
                    e.currentTarget.style.backgroundImage = `url(${TrashIconHover})`;
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.backgroundColor = 'black';
                    e.currentTarget.style.transform = 'scale(1)';
                    e.currentTarget.style.backgroundImage = `url(${TrashIcon})`;
                  }}
                />
              </div>
            ))}
          </div>
        )}
      </div>

      {error && <p style={styles.errorMessage}>{error}</p>}

      <button
        onClick={handleSubmit}
        style={styles.submitButton}
        disabled={isLoading}
      >
        {isLoading ? 'Uploading...' : 'Submit All Spreadsheets'}
      </button>

      <p style={styles.termsText}>
        By uploading your file or using our service, you agree to our
        <a href="/terms" style={styles.termsLink}> Terms of Service</a> and
        <a href="/privacy" style={styles.termsLink}> Privacy Policy</a>.
      </p>
    </div>
  );
};

export default AddGroup;