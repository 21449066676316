import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import CustomTable from '../../../components/Single/CustomTable';
import LoadingCursor from '../../../components/Single/LoadingCursor';
import { IoCloseOutline } from 'react-icons/io5';

const PopupOverlay = styled.div`
  position: fixed;
  inset: 0;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const PopupContainer = styled.div`
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: ${props => props.isExpanded ? '800px' : '400px'};
  display: flex;
  flex-direction: column;
  max-height: ${props => props.isExpanded ? '90vh' : '200px'};
  overflow: hidden;
  transition: all 0.3s ease-in-out;
`;

const PopupHeader = styled.div`
  padding: 24px;
  border-bottom: 1px solid #E5E7EB;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const HeaderContent = styled.div`
  flex: 1;
  min-width: 0;
`;

const Title = styled.h2`
  margin: 0;
  font-size: 24px;
  color: #111827;
`;

const Subtitle = styled.p`
  margin: 4px 0 0;
  font-size: 14px;
  color: #6B7280;
`;

const CloseButton = styled.button`
  background: transparent;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  margin: -8px -8px 0 0;
  transition: all 0.2s ease;
  flex-shrink: 0;

  &:hover {
    background-color: #f3f4f6;
  }

  &:active {
    background-color: #e5e7eb;
  }

  &:focus {
    outline: none;
  }
`;

const CloseIcon = styled(IoCloseOutline)`
  transition: transform 0.2s ease;
  transform: ${props => props.isHovered ? 'rotate(90deg)' : 'rotate(0deg)'};
  pointer-events: none;
  color: #6b7280;
`;

const LoadingContainer = styled.div`
  display: ${props => props.isLoading ? 'flex' : 'none'};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  gap: 16px;
`;

const LoadingText = styled.p`
  color: #4CAF50;
  font-size: 14px;
  margin: 0;
`;

const TableWrapper = styled.div`
  padding: 0;
  flex: 1;
  overflow: hidden;
  max-height: calc(90vh - 100px);
  opacity: ${props => props.isVisible ? 1 : 0};
  transition: opacity 0.3s ease-in-out;
`;

const TableScroller = styled.div`
  max-height: 100%;
  overflow-y: auto;
  
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #D1D5DB;
    border-radius: 4px;
    border: 2px solid transparent;
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #9CA3AF;
    border: 2px solid transparent;
    background-clip: padding-box;
  }
`;

const ViewLead = ({ 
  isOpen, 
  onClose, 
  data = [], 
  groupName, 
  totalLeads,
  isLoading: dataLoading,
  error
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [isCloseHovered, setIsCloseHovered] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => setIsExpanded(true), 100);
      setTimeout(() => setShowContent(true), 400);
    } else {
      setShowContent(false);
      setIsExpanded(false);
    }
  }, [isOpen]);

  if (!isOpen) return null;

  const headers = ['Number', 'Name', 'Group'];
  const tableData = data.map(lead => [
    lead.number,
    `${lead.first_name} ${lead.last_name}`.trim(),
    lead.group_name || groupName
  ]);

  return (
    <PopupOverlay onClick={(e) => e.target === e.currentTarget && onClose()}>
      <PopupContainer isExpanded={isExpanded}>
        <PopupHeader>
          <HeaderContent>
            <Title>Lead Group Details</Title>
            <Subtitle>Total Leads: {totalLeads}</Subtitle>
          </HeaderContent>
          <CloseButton
            onClick={onClose}
            onMouseEnter={() => setIsCloseHovered(true)}
            onMouseLeave={() => setIsCloseHovered(false)}
            aria-label="Close"
          >
            <CloseIcon 
              size={24}
              isHovered={isCloseHovered}
            />
          </CloseButton>
        </PopupHeader>

        <LoadingContainer isLoading={!showContent}>
          <LoadingCursor />
          <LoadingText>Loading lead details...</LoadingText>
        </LoadingContainer>

        <TableWrapper isVisible={showContent}>
          <TableScroller>
            <CustomTable
              headers={headers}
              data={tableData}
              hideEmptyMessage={true}
            />
          </TableScroller>
        </TableWrapper>
      </PopupContainer>
    </PopupOverlay>
  );
};

export default ViewLead;