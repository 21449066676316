import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { checkAuth } from './store/authSlice';
import ProtectedRoute from './utils/ProtectedRoute';
import { AuthHandler } from './utils/authUtils';import Layout from './components/Layout/Layout';
import StepsLayout from './components/Layout/Steps_layout';

// Import SINGLE pages
import Dashboard from './pages/SINGLE/Dashboard/Dashboard';
import Campaign from './pages/SINGLE/Campaign/Campaign';
import Leads from './pages/SINGLE/Leads/Leads';
import AddGroup from './pages/SINGLE/Leads/AddGroup';
import Posts from './pages/SINGLE/Posts/Posts';
import Business from './pages/SINGLE/Business/Business';
import Settings from './pages/SINGLE/Settings/Settings';
import Support from './pages/SINGLE/Support/Support';
import Login from './pages/SINGLE/Login/login';

// Import STEPS pages
import StepsLeads from './pages/STEPS/1_LEADS/steps_leads';
import StepsBusiness from './pages/STEPS/2_BUSINESS/steps_business';
import StepsPost from './pages/STEPS/3_POST/steps_post';
import StepsDeploy from './pages/STEPS/4_DEPLOY/steps_deploy';

// Import other components
import PrivacyPolicy from './components/Footer/Footer_pages/PrivacyPolicyPage';
import TermsOfService from './components/Footer/Footer_pages/TermsofService';
import ContactPage from './components/Footer/Footer_pages/Contact';

function AppContent() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkAuth());
  }, [dispatch]);

  return (
    <>
      <AuthHandler />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/auth/callback" element={<AuthHandler />} />
        <Route element={<Layout />}>
          <Route path="/" element={<Navigate to="/campaign" replace />} />
          <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
          <Route path="/campaign" element={<ProtectedRoute><Campaign /></ProtectedRoute>} />
          <Route path="/leads" element={<ProtectedRoute><Leads /></ProtectedRoute>} />
          <Route path="/add-group" element={<ProtectedRoute><AddGroup /></ProtectedRoute>} />
          <Route path="/posts" element={<ProtectedRoute><Posts /></ProtectedRoute>} />
          <Route path="/business" element={<ProtectedRoute><Business /></ProtectedRoute>} />
          <Route path="/settings" element={<ProtectedRoute><Settings /></ProtectedRoute>} />
          <Route path="/support" element={<ProtectedRoute><Support /></ProtectedRoute>} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<TermsOfService />} />
          <Route path="/contact" element={<ContactPage />} />
        </Route>
        <Route path="/campaign/steps" element={<ProtectedRoute><StepsLayout /></ProtectedRoute>}>
          <Route path="leads" element={<StepsLeads />} />
          <Route path="business" element={<StepsBusiness />} />
          <Route path="post" element={<StepsPost />} />
          <Route path="deploy" element={<StepsDeploy />} />
        </Route>
      </Routes>
    </>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;