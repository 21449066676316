import { useState } from 'react';
import { validatePostForm } from './postErrors';

export const usePostFormLogic = (formData, onFormChange, onSubmit, isEditing) => {
  const [errors, setErrors] = useState({});
  const [topErrors, setTopErrors] = useState([]);

  const handleInputChange = (field, value) => {
    onFormChange({ ...formData, [field]: value });
  };

  const handleImageImport = async (file) => {
    onFormChange({ ...formData, postImage: file });
  };

  const handleAddButton = (type) => {
    if (formData.buttons.length < 4) {
      onFormChange({
        ...formData,
        buttons: [...formData.buttons, { type, text: '', action: '' }]
      });
    }
  };

  const handleRemoveButton = (index) => {
    onFormChange({
      ...formData,
      buttons: formData.buttons.filter((_, i) => i !== index)
    });
  };

  const handleUpdateButton = (index, field, value) => {
    const newButtons = [...formData.buttons];
    newButtons[index][field] = value;
    onFormChange({ ...formData, buttons: newButtons });
  };

  const handleSubmit = () => {
    const validationErrors = validatePostForm(formData);
    setErrors(validationErrors);
  
    if (Object.keys(validationErrors).length === 0) {
      setTopErrors([]);
      const formattedData = {
        title: formData.title,
        postText: formData.postText,
        postImage: formData.postImage,
        buttons: formData.buttons.map((button, index) => ({
          type: button.type,
          text: button.text,
          action: button.action
        }))
      };
  
      onSubmit(formattedData);
    } else {
      setTopErrors(Object.values(validationErrors));
    }
  };

  return {
    errors,
    topErrors,
    handleInputChange,
    handleImageImport,
    handleAddButton,
    handleRemoveButton,
    handleUpdateButton,
    handleSubmit
  };
};