/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { TextField, Typography, Box, Grid } from '@mui/material';
import { green } from '@mui/material/colors';
import ImportWindow from '../../../components/Single/ImportWindow';
import PrimaryButton from '../../../components/Single/PrimaryButton';
import ClearButton from '../../../components/Single/ClearButton';

// Styles for the form container
const formStyles = css`
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
`;

const inputStyles = css`
  margin-bottom: 16px;
`;

const textFieldSx = {
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: green[500],
    },
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: green[500],
  },
};

const buttonContainerStyles = css`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
`;

const BusinessForm = ({
  formData = {},
  onInputChange,
  onImageImport,
  onSubmit,
  onClear,
  errorMessage,
  isSubmitting,
  isEditing,
}) => {
  const handleChange = (e) => {
    if (onInputChange) {
      onInputChange(e);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (onSubmit) {
      onSubmit(e);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate css={formStyles}>
      <Typography variant="h4" align="center" gutterBottom>
        {isEditing ? 'Edit' : 'Create'} Business Profile
      </Typography>
  
      {errorMessage && (
        <Typography color="error" align="center" gutterBottom>
          {errorMessage}
        </Typography>
      )}
  
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            name="businessName"
            label="Business Name"
            value={formData.businessName || ''}
            onChange={handleChange}
            css={inputStyles}
            sx={textFieldSx}
          />
        </Grid>
  
        <Grid item xs={12}>
          <ImportWindow
            type_of_import="image"
            onImport={onImageImport}
            addSubmit={false}
          />
        </Grid>
  
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            rows={4}
            name="about"
            label="About"
            value={formData.about || ''}
            onChange={handleChange}
            css={inputStyles}
            sx={textFieldSx}
          />
        </Grid>
  
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="location"
            label="Location"
            value={formData.location || ''}
            onChange={handleChange}
            css={inputStyles}
            sx={textFieldSx}
          />
        </Grid>
  
        <Grid item xs={12}>
          <TextField
            fullWidth
            type="email"
            name="email"
            label="Email"
            value={formData.email || ''}
            onChange={handleChange}
            css={inputStyles}
            sx={textFieldSx}
          />
        </Grid>
  
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="website"
            label="Website"
            value={formData.website || ''}
            onChange={handleChange}
            css={inputStyles}
            sx={textFieldSx}
          />
        </Grid>
  
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="facebookLink"
            label="Facebook Link"
            value={formData.facebookLink || ''}
            onChange={handleChange}
            css={inputStyles}
            sx={textFieldSx}
          />
        </Grid>
  
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="instagramLink"
            label="Instagram Link"
            value={formData.instagramLink || ''}
            onChange={handleChange}
            css={inputStyles}
            sx={textFieldSx}
          />
        </Grid>
  
        <Grid item xs={12}>
          <Box css={buttonContainerStyles}>
            <PrimaryButton 
              isSubmitting={isSubmitting} 
              disabled={isSubmitting}
            >
              {isEditing ? 'Update Business' : 'Create Business'}
            </PrimaryButton>
            <ClearButton onClick={onClear} />
          </Box>
        </Grid>
      </Grid>
  
      <Typography variant="caption" align="center" display="block" sx={{ mt: 2 }}>
        By uploading your file or using our service, you agree to our
        <a href="/terms" css={css`color: #4CAF50; text-decoration: none;`}> Terms of Service</a> and
        <a href="/privacy" css={css`color: #4CAF50; text-decoration: none;`}> Privacy Policy</a>.
      </Typography>
    </Box>
  );
};

export default BusinessForm;
