import React from 'react';
import { privacyPolicyHtml } from './privacyPolicyContent';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <div dangerouslySetInnerHTML={{ __html: privacyPolicyHtml }} />
    </div>
  );
};

export default PrivacyPolicy;