/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { css, keyframes } from '@emotion/react';

const enableScale = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const activationMove = keyframes`
  0% { left: -100%; }
  100% { left: 100%; }
`;

const NextStepButton = ({ isEnabled, onClick }) => {
  const [wasEnabled, setWasEnabled] = useState(false);

  useEffect(() => {
    if (isEnabled && !wasEnabled) {
      setWasEnabled(true);
    }
  }, [isEnabled]);

  const buttonStyles = css`
    width: 100%;
    padding: 15px 20px;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    cursor: ${isEnabled ? 'pointer' : 'not-allowed'};
    transition: all 0.3s ease;
    overflow: hidden;
    position: relative;
    background-color: ${isEnabled ? '#000000' : '#cccccc'};
    color: ${isEnabled ? 'white' : '#666666'};
    animation: ${isEnabled && !wasEnabled ? `${enableScale} 0.3s ease` : 'none'};

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -100%;
      width: 200%;
      height: 100%;
      background: linear-gradient(
        to right, 
        #000000 0%,
        #000000 25%,
        rgba(0, 255, 0, 0.5) 50%,
        #000000 75%,
        #000000 100%
      );
      transition: left 0.7s ease, opacity 0.3s ease;
      z-index: 1;
      opacity: 0;
    }

    ${isEnabled && `
      &:hover::before {
        left: 0;
        opacity: 1;
      }

      &:not(:hover)::before {
        left: -100%;
        opacity: 0;
        transition: left 0.7s ease, opacity 0.7s ease 0.2s;
      }
    `}
  `;

  const spanStyles = css`
    position: relative;
    z-index: 2;
  `;

  const gradientStyles = css`
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, transparent, rgba(0, 255, 0, 0.5), transparent);
    animation: ${activationMove} 1s ease-in-out;
  `;

  return (
    <button
      css={buttonStyles}
      onClick={isEnabled ? onClick : undefined}
      disabled={!isEnabled}
    >
      <span css={spanStyles}>Proceed to Next Step</span>
      {isEnabled && <div css={gradientStyles}></div>}
    </button>
  );
};

export default NextStepButton;