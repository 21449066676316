import { createAsyncThunk } from '@reduxjs/toolkit';
import campaignAPI from '../service/campaignAPI';
import { logout } from './authSlice';

// ===================== UTILITY FUNCTIONS START =====================

const handleUnauthorized = (dispatch, rejectWithValue) => (error) => {
  if (error.response && error.response.status === 401) {
    dispatch(logout());
    return rejectWithValue('Session expired. Please log in again.');
  }
  return rejectWithValue(error.response?.data || 'An error occurred');
};

// ===================== UTILITY FUNCTIONS END =====================

// ===================== LEADS ACTIONS START =====================

export const fetchGroups = createAsyncThunk(
  'campaign/fetchGroups',
  async (_, { getState, rejectWithValue }) => {
    try {
      const { auth: { token } } = getState();
      const response = await campaignAPI.fetchGroups(token);

      if (response && response.groups) {
        return response.groups.map(group => ({
          id: group.id,
          name: group.name,
          contacts: group.contacts,
          create_time: group.create_time
        }));
      } else {
        return rejectWithValue('No groups found in the API response');
      }
    } catch (error) {
      return rejectWithValue(error.message || 'Failed to fetch groups');
    }
  }
);

export const removeDuplicates = createAsyncThunk(
  'campaign/removeDuplicates',
  async ({ groupIds }, { dispatch, rejectWithValue, getState }) => {
    try {
      const { auth: { token } } = getState();
      const response = await campaignAPI.fetchRemoveDuplicates(token, { group_ids: groupIds });

      if (response && response.deduplicated_count !== undefined) {
        return {
          originalCount: response.original_count,
          deduplicatedCount: response.deduplicated_count,
          duplicatesRemoved: response.original_count - response.deduplicated_count
        };
      }
      return rejectWithValue('Invalid response from remove duplicates');
    } catch (error) {
      return handleUnauthorized(dispatch, rejectWithValue)(error);
    }
  }
);

export const importContactsSpreadsheet = createAsyncThunk(
  'campaign/importContactsSpreadsheet',
  async (formData, { dispatch, rejectWithValue, getState }) => {
    try {
      const { auth: { token } } = getState();
      
      if (!(formData instanceof FormData)) {
        return rejectWithValue('Invalid form data provided');
      }

      const response = await campaignAPI.fetchImportContactsSpreadsheet(token, formData);
      
      if (!response) {
        return rejectWithValue('No response received from server');
      }

      if (response.contacts_imported !== undefined && response.lead_source_id !== undefined) {
        return {
          contacts_imported: parseInt(response.contacts_imported, 10),
          lead_source_id: parseInt(response.lead_source_id, 10),
          group_name: response.group_name || 'Imported Group',
          create_time: new Date().toISOString()
        };
      }
      return rejectWithValue('Invalid response structure from server');
    } catch (error) {
      return handleUnauthorized(dispatch, rejectWithValue)(error);
    }
  }
);

export const importContactsManual = createAsyncThunk(
  'campaign/importContactsManual',
  async (leadData, { dispatch, rejectWithValue, getState }) => {
    try {
      const { auth: { token } } = getState();
      const contacts = [[
        leadData.name.split(' ')[0] || '', // First name
        leadData.name.split(' ').slice(1).join(' ') || '', // Last name
        leadData.phone
      ]];

      const response = await campaignAPI.fetchImportContactsManual(token, {
        group_name: "Individual Leads",
        contacts: contacts,
        origin: "manual"
      });

      if (response?.lead_source_id) {
        return {
          id: response.lead_source_id,
          name: response.group_name,
          contacts: 1,
          create_time: new Date().toISOString()
        };
      }
      return rejectWithValue('Failed to add manual contact');
    } catch (error) {
      return handleUnauthorized(dispatch, rejectWithValue)(error);
    }
  }
);

export const getLeadsByGroupId = createAsyncThunk(
  'campaign/getLeadsByGroupId',
  async (groupId, { dispatch, rejectWithValue, getState }) => {
    try {
      const { auth: { token } } = getState();
      const response = await campaignAPI.fetchLeadsByGroupId(token, groupId);
      
      if (response?.leads) {
        return {
          leads: response.leads.map(lead => ({
            number: lead.lead_phone_number,
            first_name: lead.lead_first_name,
            last_name: lead.lead_last_name
          }))
        };
      }
      return rejectWithValue('No leads found in the API response');
    } catch (error) {
      return handleUnauthorized(dispatch, rejectWithValue)(error);
    }
  }
);

export const deleteLeadGroup = createAsyncThunk(
  'campaign/deleteLeadGroup',
  async (groupId, { dispatch, rejectWithValue, getState }) => {
    try {
      const { auth: { token } } = getState();
      await campaignAPI.fetchDeleteGroup(token, groupId);
      return groupId;
    } catch (error) {
      return handleUnauthorized(dispatch, rejectWithValue)(error);
    }
  }
);

// ===================== LEADS ACTIONS END =====================

// ===================== BUSINESS ACTIONS START =====================

export const fetchBusinesses = createAsyncThunk(
  'campaign/fetchBusinesses',
  async (_, { dispatch, rejectWithValue, getState }) => {
    try {
      const { auth: { token } } = getState();
      const response = await campaignAPI.fetchBusinesses(token);
      return response;
    } catch (error) {
      return handleUnauthorized(dispatch, rejectWithValue)(error);
    }
  }
);

export const createBusiness = createAsyncThunk(
  'campaign/createBusiness',
  async ({ token, businessData }, { rejectWithValue }) => {
    try {
      console.log('Creating business with data:', businessData);
      const response = await campaignAPI.fetchCreateBusiness(token, businessData);
      console.log('Create business API response:', response);
      return response;
    } catch (error) {
      console.error('Error in createBusiness action:', error);
      return rejectWithValue(error.response?.data || error.message || 'An error occurred');
    }
  }
);

export const deleteBusiness = createAsyncThunk(
  'business/deleteBusiness',
  async ({ token, id }, { rejectWithValue }) => {
    try {
      console.log('Deleting business with ID:', id);
      const response = await campaignAPI.fetchDeleteBusiness(token, id);
      console.log('Delete business API response:', response);
      return response;
    } catch (error) {
      console.error('Error in deleteBusiness action:', error);
      return rejectWithValue(error.response?.data || error.message || 'An error occurred while deleting the business');
    }
  }
);

export const updateBusiness = createAsyncThunk(
  'campaign/updateBusiness',
  async ({ id, businessData }, { dispatch, rejectWithValue, getState }) => {
    try {
      const { auth: { token } } = getState();
      const response = await campaignAPI.fetchUpdateBusiness(token, id, businessData);
      return response;
    } catch (error) {
      return handleUnauthorized(dispatch, rejectWithValue)(error);
    }
  }
);

// ===================== BUSINESS ACTIONS END =====================

// ===================== POST ACTIONS START =====================
export const createPost = createAsyncThunk(
  'campaign/createPost',
  async (formData, { getState, rejectWithValue }) => {
    console.log('[CreatePost Action] Starting with formData:', {
      title: formData?.title,
      postText: formData?.postText, 
      postImage: formData?.postImage ? 'present' : 'none',
      buttons: formData?.buttons
    });
 
    try {
      const { auth: { token } } = getState();
      let imageUrl = '';
 
      if (formData.postImage) {
        const imageFormData = new FormData();
        imageFormData.append('file', formData.postImage);
        const imageResponse = await campaignAPI.fetchUploadImage(token, imageFormData);
        imageUrl = imageResponse.url;
      }
 
      const postData = {
        template_name: formData.title.trim(),
        template_type: 'template',
        template_language: 'en',
        header: imageUrl,
        header_type: imageUrl ? 'image' : '',
        body: formData.postText.trim(),
        footer: '',
        buttons: formData.buttons?.map((button, index) => ({
          action_type: button.type,
          label: button.text,
          button_action: button.action,
          order_index: index
        })) || []
      };
 
      const response = await campaignAPI.fetchCreatePost(token, postData);
      return response;
    } catch (error) {
      console.error('[CreatePost Action] Error:', error);
      return rejectWithValue(error.message || 'Failed to create post');
    }
  }
 );
 
 export const fetchPosts = createAsyncThunk(
  'campaign/fetchPosts',
  async (_, { getState, rejectWithValue }) => {
    try {
      const { auth: { token } } = getState();
      const response = await campaignAPI.fetchPosts(token);
      return response;
    } catch (error) {
      return rejectWithValue(error.message || 'Failed to fetch posts');
    }
  }
 );
 
 export const deletePost = createAsyncThunk(
  'campaign/deletePost', 
  async (postId, { getState, rejectWithValue }) => {
    try {
      const { auth: { token } } = getState();
      await campaignAPI.fetchDeletePost(token, postId);
      return postId;
    } catch (error) {
      return rejectWithValue(error.message || 'Failed to delete post');
    }
  }
 );
 
 export const getPostById = createAsyncThunk(
  'campaign/getPostById',
  async (postId, { getState, rejectWithValue }) => {
    try {
      const { auth: { token } } = getState();
      const response = await campaignAPI.fetchGetPost(token, postId);
      return response;
    } catch (error) {
      return rejectWithValue(error.message || 'Failed to get post');
    }
  }
 );
 
 export const updatePost = createAsyncThunk(
  'campaign/updatePost',
  async ({ postId, postData }, { getState, rejectWithValue }) => {
    try {
      const { auth: { token } } = getState();
      const response = await campaignAPI.fetchUpdatePost(token, postId, postData);
      return response;
    } catch (error) {
      return rejectWithValue(error.message || 'Failed to update post');
    }
  }
 );
 // ===================== POST ACTIONS END =====================

// ===================== CAMPAIGN ACTIONS START =====================

export const createCampaign = createAsyncThunk(
  'campaign/createCampaign',
  async (campaignData, { dispatch, rejectWithValue, getState }) => {
    try {
      const { auth: { token } } = getState();
      console.log('Creating campaign with data:', campaignData);
      const response = await campaignAPI.fetchCreateCampaign(token, campaignData);
      console.log('Create campaign response:', response);
      return response;
    } catch (error) {
      console.error('Error in createCampaign:', error);
      return handleUnauthorized(dispatch, rejectWithValue)(error);
    }
  }
);

export const fetchCampaigns = createAsyncThunk(
  'campaign/fetchCampaigns',
  async (_, { dispatch, rejectWithValue, getState }) => {
    try {
      const { auth: { token } } = getState();
      const response = await campaignAPI.getCampaigns(token);
      return response;
    } catch (error) {
      return handleUnauthorized(dispatch, rejectWithValue)(error);
    }
  }
);

// ===================== CAMPAIGN ACTIONS END =====================