import React, { useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { useNavigate } from 'react-router-dom';

const ImportWindow = forwardRef(({ type_of_import, onImport, addSubmit = true, externalSubmit }, ref) => {
  const [file, setFile] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  useImperativeHandle(ref, () => ({
    reset() {
      setFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  }));

  const importConfig = {
    image: {
      title: 'Locate or Drag Your Image',
      validFormats: 'PNG, JPEG, etc.',
      accept: 'image/*',
      endpoint: '/api/upload-image',
    },
    image_vid: {
      title: 'Locate or Drag Your Image or Video',
      validFormats: 'PNG, JPEG, MP4, etc.',
      accept: 'image/*,video/*',
      endpoint: '/api/upload-media',
    },
    spreadsheet: {
      title: 'Locate or Drag Your Spreadsheet',
      validFormats: 'CSV, Excel, Database',
      accept: '.csv,.xlsx,.xls,.db',
      endpoint: '/api/upload-group',
    },
  };

  const { title, validFormats, accept, endpoint } = importConfig[type_of_import];

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setIsDragging(true);
    } else if (e.type === 'dragleave') {
      setIsDragging(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const droppedFile = e.dataTransfer.files[0];
      setFile(droppedFile);
      onImport && onImport(droppedFile);
    }
  };

  const handleFileSelect = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];
      setFile(selectedFile);
      onImport && onImport(selectedFile);
    }
  };

  return (
    <div className="import-window-container">
      <style>
        {`
          .import-window-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 40px;
            background-color: white;
            border-radius: 20px;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            max-width: 400px;
            margin: 40px auto;
          }

          .import-window-title {
            font-size: 24px;
            margin-bottom: 10px;
            text-align: center;
          }

          .import-window-subtitle {
            font-size: 14px;
            color: #666;
            margin-bottom: 20px;
            text-align: center;
          }

          .upload-area {
            width: 100%;
            height: 130px;
            border: 2px dashed #ccc;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;
            transition: background-color 0.3s, border-color 0.3s;
            cursor: pointer;
            text-align: center;
          }

          .upload-area-active {
            background-color: #e8f5e9;
            border-color: #4caf50;
          }
        `}
      </style>
      <h1 className="import-window-title">{title}</h1>
      <p className="import-window-subtitle">Valid formats: {validFormats}</p>
      <div
        className={`upload-area ${isDragging ? 'upload-area-active' : ''}`}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
        onClick={handleFileSelect}
      >
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          style={{ display: 'none' }}
          accept={accept}
        />
        {file ? (
          <span>{file.name}</span>
        ) : (
          <span>Import or Drag File Here</span>
        )}
      </div>
    </div>
  );
});

export default ImportWindow;