/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { css, keyframes } from '@emotion/react';
import { MdRocketLaunch } from 'react-icons/md';
import CustomDatePicker from '../../../components/Single/DatePicker';
import AnimatedRocket from './AnimatedRocketDeploy';

const containerStyles = css`
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;
`;

const buttonStyles = css`
  width: 100%;
  max-width: 400px;
  padding: 15px 30px;
  font-size: 18px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
  position: relative;
  overflow: hidden;

  &:hover {
    background-color: #45a049;
  }
`;

const DeploymentScheduler = ({ scheduleDate, onDateChange, onDeploy }) => {
  const [isAnimating, setIsAnimating] = useState(false);

  const handleDeploy = () => {
    setIsAnimating(true);
    setTimeout(() => {
      setIsAnimating(false);
      onDeploy();
    }, 1500); // Adjust this value to match your animation duration
  };

  return (
    <>
      <div css={containerStyles}>
        <label htmlFor="scheduleDate" css={css`display: block; margin-bottom: 5px;`}>
          Schedule Deployment:
        </label>
        <CustomDatePicker onChange={onDateChange} initialDate={scheduleDate} />
      </div>

      <button onClick={handleDeploy} css={buttonStyles} disabled={isAnimating}>
        Deploy
        <AnimatedRocket isAnimating={isAnimating} />
      </button>
    </>
  );
};

export default DeploymentScheduler;