import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { icons } from '../../../utils/iconImports'; // Adjust the path as necessary
import supportImage from '../../../components/images/settings_page.png'; // Adjust the path if needed

const Settings = () => {
  const navigate = useNavigate();
  const [isLogoutHovered, setIsLogoutHovered] = useState(false);

  const handleLogout = () => {
      navigate('/login'); // Add your logout logic here
  };

  const styles = {
      settingsContainer: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          padding: '20px',
          position: 'relative', // Position relative for absolute positioning of the logout button
          backgroundColor: 'transparent', // Adjust as needed
      },
      logoutBtn: {
          position: 'absolute', // Position absolute for top right corner placement
          top: '20px',
          right: '20px',
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#000000',
          color: '#FFF3F3',
          border: 'none',
          borderRadius: '15px',
          padding: '8px 12px',
          cursor: 'pointer',
          transition: 'all 0.3s ease',
          zIndex: 1000, // Ensure button is above other content
      },
      logoutBtnHovered: {
          backgroundColor: '#1D1D1D',
          transform: 'scale(1.03)',
      },
      logoutIcon: {
          width: '24px',
          height: '24px',
          marginRight: '8px',
          transition: 'transform 0.3s ease',
      },
      logoutIconHovered: {
          transform: 'scale(1.1)',
      },
      logoutText: {
          fontSize: '14px',
          opacity: 1,
          transition: 'opacity 0.3s ease',
      },
      imageContainer: {
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '20px',
      },
      supportImage: {
          maxWidth: '100%',
          height: 'auto',
      },
  };

  return (
      <div style={styles.settingsContainer}>
          {/* Logout Button */}
          <button 
              style={{
                  ...styles.logoutBtn,
                  ...(isLogoutHovered ? styles.logoutBtnHovered : {})
              }}
              onMouseEnter={() => setIsLogoutHovered(true)}
              onMouseLeave={() => setIsLogoutHovered(false)}
              onClick={handleLogout}
          >
              <img 
                  src={isLogoutHovered ? icons.logout.selected : icons.logout.default} 
                  alt="Logout" 
                  style={{
                      ...styles.logoutIcon,
                      ...(isLogoutHovered ? styles.logoutIconHovered : {})
                  }}
              />
              <span style={styles.logoutText}>Logout</span>
          </button>

          {/* Your centered content here */}
          <div>
              <h2>Settings Page</h2>
              {/* Add other settings elements here */}

              {/* Image Display */}
              <div style={styles.imageContainer}>
                  <img src={supportImage} alt="Support" style={styles.supportImage} />
              </div>
          </div>
      </div>
  );
};

export default Settings;
