import React, { useState, useCallback } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import StepIndicator from '../../components/Single/StepIndicator';
import { logo } from '../../utils/iconImports';
import { baseStyle, globalStyles } from './sharedStyles';
import Footer from '../Footer/Footer';
import CurrentBalance from '../../pages/STEPS/4_DEPLOY/CurrentBalance';
import BuyCredits from '../../pages/STEPS/4_DEPLOY/BuyCredits';

const StepsLayout = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isBuyCreditsOpen, setIsBuyCreditsOpen] = useState(false);

    const getCurrentStep = () => {
        if (location.pathname.includes('/leads')) return 0;
        if (location.pathname.includes('/business')) return 1;
        if (location.pathname.includes('/post')) return 2;
        if (location.pathname.includes('/deploy')) return 3;
        return 0;
    };

    const isDeployStep = getCurrentStep() === 3;

    const openBuyCreditsModal = useCallback(() => {
        setIsBuyCreditsOpen(true);
    }, []);

    const closeBuyCreditsModal = useCallback(() => {
        setIsBuyCreditsOpen(false);
    }, []);

    const styles = {
        layout: {
            ...baseStyle,
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            width: '100vw',
            backgroundImage: `url("data:image/svg+xml,%3Csvg id='patternId' width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3Cpattern id='a' patternUnits='userSpaceOnUse' width='70' height='70' patternTransform='scale(2) rotate(0)'%3E%3Crect x='0' y='0' width='100%25' height='100%25' fill='hsla(86, 0%25, 100%25, 1)'/%3E%3Cpath d='M-4.8 4.44L4 16.59 16.14 7.8M32 30.54l-13.23 7.07 7.06 13.23M-9 38.04l-3.81 14.5 14.5 3.81M65.22 4.44L74 16.59 86.15 7.8M61 38.04l-3.81 14.5 14.5 3.81' stroke-linecap='square' stroke-width='1' stroke='hsla(86, 96%25, 90%25, 1)' fill='none'/%3E%3Cpath d='M59.71 62.88v3h3M4.84 25.54L2.87 27.8l2.26 1.97m7.65 16.4l-2.21-2.03-2.03 2.21m29.26 7.13l.56 2.95 2.95-.55' stroke-linecap='square' stroke-width='1' stroke='hsla(86, 26%25, 87%25, 1)' fill='none'/%3E%3Cpath d='M58.98 27.57l-2.35-10.74-10.75 2.36M31.98-4.87l2.74 10.65 10.65-2.73M31.98 65.13l2.74 10.66 10.65-2.74' stroke-linecap='square' stroke-width='1' stroke='hsla(86, 100%25, 84%25, 1)' fill='none'/%3E%3Cpath d='M8.42 62.57l6.4 2.82 2.82-6.41m33.13-15.24l-4.86-5.03-5.03 4.86m-14-19.64l4.84-5.06-5.06-4.84' stroke-linecap='square' stroke-width='1' stroke='hsla(86, 0%25, 95%25, 1)' fill='none'/%3E%3C/pattern%3E%3C/defs%3E%3Crect width='800%25' height='800%25' transform='translate(0,0)' fill='url(%23a)'/%3E%3C/svg%3E")`,
            backgroundRepeat: 'repeat',
            backgroundSize: 'auto',
            position: 'relative',
            zIndex: 0,
        },
        logoLink: {
            position: 'absolute',
            top: '20px',
            left: '20px',
            zIndex: 10,
            cursor: 'pointer',
            outline: 'none',
        },
        logo: {
            width: '150px',
        },
        contentWrapper: {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '40px 20px',
        },
        transparentContainer: {
            display: 'flex',
            width: '100%',
            maxWidth: '1800px',
            marginTop: '60px',
        },
        balanceContainer: {
            width: '300px',
            marginRight: '20px',
            position: 'sticky',
            top: '50%',
            transform: 'translateY(-50%)',
            alignSelf: 'flex-start',
        },
        whiteContainer: {
            backgroundColor: 'white',
            borderRadius: '8px',
            boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
            padding: '30px 30px',
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
        },
        backButton: {
            position: 'absolute',
            top: '20px',
            right: '40px',
            padding: '6px 12px',
            backgroundColor: '#A5D6A7',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            transition: 'background-color 0.3s ease, transform 0.3s ease',
            fontSize: '14px',
            zIndex: 10,
        },
        backdropStyles: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            backdropFilter: 'blur(5px)',
            zIndex: 1000,
        },
        modalContainerStyles: {
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1001,
            backgroundColor: 'white',
            borderRadius: '10px',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
            padding: '20px',
        },
    };

    return (
        <>
            <style>{globalStyles}</style>
            <div style={styles.layout}>
                <a href="https://sen-due.com" target="_blank" rel="noopener noreferrer" style={styles.logoLink}>
                    <img src={logo} alt="Sendue Logo" style={styles.logo} />
                </a>
                <button 
                    style={styles.backButton}
                    onClick={() => navigate('/dashboard')}
                    onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor = '#66BB6A';
                        e.currentTarget.style.transform = 'scale(1.05)';
                    }}
                    onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = '#A5D6A7';
                        e.currentTarget.style.transform = 'scale(1)';
                    }}
                >
                    Back to Dashboard
                </button>
                <div style={styles.contentWrapper}>
                    <div style={styles.transparentContainer}>
                        {isDeployStep && (
                            <div style={styles.balanceContainer}>
                                <CurrentBalance totalContacts={0} onBuyCredits={openBuyCreditsModal} />
                            </div>
                        )}
                        <div style={styles.whiteContainer}>
                            <StepIndicator currentStep={getCurrentStep()} />
                            <Outlet context={{ openBuyCreditsModal }} />
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
            {isBuyCreditsOpen && (
                <>
                    <div style={styles.backdropStyles} onClick={closeBuyCreditsModal} />
                    <div style={styles.modalContainerStyles}>
                        <BuyCredits onClose={closeBuyCreditsModal} />
                    </div>
                </>
            )}
        </>
    );
};

export default StepsLayout;