/** @jsxImportSource @emotion/react */
import React from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { 
  selectTotalSelectedContacts, 
  selectTotalWithoutDuplicates, 
  selectSelectedBusiness,
  selectSelectedPost
} from '../../store/selectors';

const Title = styled.h3`
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin: 0 0 15px 0;
  border-bottom: 2px solid #4CAF50;
  padding-bottom: 10px;
`;

const InfoItem = styled.p`
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
`;

const BusinessContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const BusinessImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
`;

const PopupContent = ({ currentStep, children }) => {
  const totalSelectedContacts = useSelector(selectTotalSelectedContacts);
  const totalWithoutDuplicates = useSelector(selectTotalWithoutDuplicates);
  const selectedBusiness = useSelector(selectSelectedBusiness);
  const selectedPost = useSelector(selectSelectedPost);

  return (
    <>
      <Title>Campaign</Title>
      <InfoItem>
        <strong>LEADS:</strong> {totalWithoutDuplicates !== null 
          ? `${totalWithoutDuplicates} contacts`
          : `${totalSelectedContacts} contacts`}
      </InfoItem>
      {selectedBusiness && (
        <BusinessContainer>
          <BusinessImage 
            src={selectedBusiness.profile_picture || '/placeholder.png'} 
            alt={selectedBusiness.business_name}
          />
          <span>{selectedBusiness.business_name}</span>
        </BusinessContainer>
      )}
      {selectedPost && (
        <InfoItem>
          <strong>Post:</strong> {selectedPost.template_name}
        </InfoItem>
      )}
      {children}
    </>
  );
};

export default PopupContent;