import React from 'react';
import supportImage from '../../../components/images/support_page.png'; 

const Support = () => {
    const styles = {
      supportContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0px 20px', // Reduce top padding to move up
        minHeight: '100vh',
        backgroundColor: 'transparent', // Make the main background transparent
      },
      card: {
        backgroundColor: 'rgba(255, 255, 255, 0.8)', // White with some transparency
        borderRadius: '10px',
        padding: '30px',
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
        maxWidth: '600px',
        width: '100%',
        marginBottom: '20px',
        textAlign: 'center',
        marginTop: '-50px', // Move the card up with negative margin
      },
      title: {
        fontSize: '24px',
        marginBottom: '20px',
        color: '#333',
      },
      emailList: {
        listStyleType: 'none',
        padding: 0,
        marginBottom: '30px',
      },
      emailItem: {
        fontSize: '18px',
        marginBottom: '10px',
        color: '#555',
      },
      emailLink: {
        textDecoration: 'none',
        color: '#4CAF50', // Match the button style from the dashboard
        fontWeight: 'bold',
      },
      imageContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '20px',
      },
      supportImage: {
        maxWidth: '100%',
        height: 'auto',
      },
    };
  
    return (
      <div style={styles.supportContainer}>
        <div style={styles.card}>
          <h2 style={styles.title}>Contact Support</h2>
  
          <ul style={styles.emailList}>
            <li style={styles.emailItem}>
              For general inquiries or technical support, please contact: 
              <br />
              <a href="mailto:aharon@sen-due.com" style={styles.emailLink}>aharon@sen-due.com</a> or <a href="mailto:yosef@sen-due.com" style={styles.emailLink}>yosef@sen-due.com</a>
            </li>
          </ul>
  
          <div style={styles.imageContainer}>
            <img src={supportImage} alt="Support" style={styles.supportImage} />
          </div>
  
          <p>If you have any questions, feel free to reach out to us. We're here to help!</p>
        </div>
      </div>
    );
  };
  
  export default Support;