import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createPost, fetchPosts, deletePost } from '../../../store/campaignActions';
import { 
  selectPosts, 
  selectSelectedGroups, 
  selectIsLoading, 
  selectSelectedPost 
} from '../../../store/selectors';

export const useStepsPostLogic = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const posts = useSelector(selectPosts);
  const selectedGroups = useSelector(selectSelectedGroups);
  const isLoading = useSelector(selectIsLoading);
  const selectedPost = useSelector(selectSelectedPost);

  const [formData, setFormData] = useState({
    title: '',
    postText: '',
    postImage: null,
    buttons: []
  });

  const [alert, setAlert] = useState({ open: false, message: '', severity: 'success' });
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [postToDelete, setPostToDelete] = useState(null);

  useEffect(() => {
    dispatch(fetchPosts());
  }, [dispatch]);

  const handleFormChange = (newFormData) => {
    setFormData(newFormData);
  };

  const handleCreatePost = async (postData) => {
    try {
      await dispatch(createPost(postData)).unwrap();
      setAlert({ open: true, message: 'Post created successfully!', severity: 'success' });
      setFormData({
        title: '',
        postText: '',
        postImage: null,
        buttons: []
      });
      dispatch(fetchPosts());
    } catch (err) {
      setAlert({ open: true, message: 'Failed to create post', severity: 'error' });
    }
  };

  const handleDeleteClick = (postId) => {
    setPostToDelete(postId);
    setIsConfirmationOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await dispatch(deletePost(postToDelete)).unwrap();
      setAlert({ open: true, message: 'Post deleted successfully!', severity: 'success' });
      dispatch(fetchPosts());
      setIsConfirmationOpen(false);
      setPostToDelete(null);
    } catch (err) {
      setAlert({ open: true, message: 'Failed to delete post', severity: 'error' });
    }
  };

  const handleNext = () => {
    if (selectedPost) {
      navigate('/campaign/steps/deploy');
    }
  };

  return {
    posts,
    selectedPost,
    selectedGroups,
    formData,
    isLoading,
    alert,
    isConfirmationOpen,
    postToDelete,
    handleFormChange,
    handleCreatePost,
    handleDeleteClick,
    handleDeleteConfirm,
    handleNext,
    setIsConfirmationOpen
  };
};