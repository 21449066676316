// InfoIcon.js
import React from 'react';
import { Tooltip, IconButton } from '@mui/material';
import { Info } from '@mui/icons-material';

const InfoIcon = ({ tooltipText }) => {
  return (
    <Tooltip title={tooltipText} arrow>
      <IconButton
        size="small"
        sx={{
          color: '#6CBB6FBF',
        }}
      >
        <Info />
      </IconButton>
    </Tooltip>
  );
};

export default InfoIcon;
